import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CONFIG } from "@rentiohq/shared-frontend/dist/config/app.config";
import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import { ERegistrationContractStatus, ERegistrationFailedReason, } from "@rentiohq/shared-frontend/dist/types/registration.types";
import { formatAddress } from "@rentiohq/shared-frontend/dist/utils/address.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { getMembersWithOneOfRoles } from "@rentiohq/shared-frontend/dist/utils/roles.utils";
import { join, stringToSnakeCase, } from "@rentiohq/shared-frontend/dist/utils/string.utils";
import { mergeDeepLeft } from "ramda";
import { ContractFetchListItem, Lozenge, PropertyFetchListItem, TextStyle, TruncateMiddle, TruncateTooltip, } from "../components";
import * as utils from ".";
export var getTextStyleVariationTypeForStatus = function (rentContractStatus) {
    switch (rentContractStatus) {
        case ERegistrationContractStatus.None:
        case ERegistrationContractStatus.Rejected:
        case ERegistrationContractStatus.Failed:
            return "negative";
        case ERegistrationContractStatus.Registering:
            return "warn";
        case ERegistrationContractStatus.Registered:
        case ERegistrationContractStatus.RegisteredManually:
        case ERegistrationContractStatus.NotApplicable:
            return "positive";
        default:
            return "default";
    }
};
export var getLozengeAppearanceForStatus = function (rentContractStatus) {
    switch (rentContractStatus) {
        case ERegistrationContractStatus.None:
        case ERegistrationContractStatus.Rejected:
        case ERegistrationContractStatus.Failed:
            return "error";
        case ERegistrationContractStatus.Registering:
            return "warning";
        case ERegistrationContractStatus.Registered:
        case ERegistrationContractStatus.RegisteredManually:
        case ERegistrationContractStatus.NotApplicable:
            return "success";
        default:
            return "default";
    }
};
export var DEFAULT_FILTER = {
    filter: {
        where: {},
        include: ["property"],
        order: "createdAt ASC",
        limit: CONFIG.DEFAULT_FETCH_LIMIT,
    },
};
export var getFilterQuery = function (values, defaultFilter) {
    var newFilter = defaultFilter;
    var stageStatuses = [];
    var signatureStatuses = [];
    var paymentStatuses = [];
    var statuses = utils.array.intersectionIfNotEmpty(stageStatuses, signatureStatuses, paymentStatuses);
    if (statuses.length > 0) {
        newFilter = mergeDeepLeft({ filter: { where: { status: { inq: statuses } } } }, newFilter);
    }
    if (values.order) {
        newFilter = mergeDeepLeft({ filter: { order: values.order } }, newFilter);
    }
    if (values.query) {
        newFilter = mergeDeepLeft({ filter: { search: values.query } }, newFilter);
    }
    if (values.page) {
        newFilter = mergeDeepLeft({ filter: { page: values.page || 1 } }, newFilter);
    }
    return newFilter;
};
export var renderCellStatus = function (status, document) {
    if (!status) {
        return;
    }
    var lokaliseKeyPrefix = "registration.status";
    var key = stringToSnakeCase(status);
    if (status === ERegistrationContractStatus.Failed) {
        lokaliseKeyPrefix = "registration.status.failed";
        key = "contact_rentio";
        if (document === null || document === void 0 ? void 0 : document.failedReason) {
            var failedReason = document.failedReason;
            // TODO: remove this if statement again once we know all the possible statuses
            // (and they have been added to lokalise )
            if (failedReason === ERegistrationFailedReason.AddressNotFound) {
                key = failedReason;
            }
        }
    }
    return (_jsx(Lozenge, { appearance: getLozengeAppearanceForStatus(status), children: getLocalizedText("".concat(lokaliseKeyPrefix, ".").concat(key).toLowerCase()) }));
};
var renderCellPropertyBase = function (property) {
    var WIDTH = 160;
    return (_jsxs("div", { style: { width: WIDTH }, children: [_jsx(TruncateMiddle, { input: formatAddress(property, false, false), skipInitialChars: 14 }), _jsx(TextStyle, { variation: "subdued", children: _jsx(TruncateMiddle, { input: "".concat(property.zip, " ").concat(property.city), skipInitialChars: 14 }) })] }));
};
export var renderCellProperty = function (propertyId, property) {
    if (property) {
        return renderCellPropertyBase(property);
    }
    return (_jsx(PropertyFetchListItem, { shouldRefetch: false, property: property, propertyId: propertyId, renderLoading: function () { return "..."; }, renderProperty: renderCellPropertyBase }));
};
export var renderCellContractRoles = function (registration, roles) {
    var WIDTH = 140;
    return (_jsx(ContractFetchListItem, { contract: registration.contract, contractId: registration.contractId, renderLoading: function () { return "..."; }, renderContract: function (contract) { return (_jsx(TruncateTooltip, { width: WIDTH, children: join(getMembersWithOneOfRoles(contract.members, roles).map(function (member) {
                return getName(member.account);
            })) })); } }));
};
