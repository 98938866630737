var _a;
import api from "../../utils/api/api.utils";
import { append } from "../../utils/api.utils";
import { generateApi } from "../generators/generator.api";
import { GENERATOR_PARAMS } from "./property.constants";
export var getPaged = (_a = generateApi(GENERATOR_PARAMS), _a.getPaged), getDetail = _a.getDetail, getCount = _a.getCount, getInfiniteLoad = _a.getInfiniteLoad, update = _a.update, create = _a.create, remove = _a.remove, removeMembers = _a.removeMembers, addMembers = _a.addMembers;
export var searchProperties = function (query, extraFilterData) {
    if (extraFilterData === void 0) { extraFilterData = {}; }
    return api.get(append("/".concat(GENERATOR_PARAMS.basePathEntity), {
        limit: 30,
        search: query,
        filter: extraFilterData,
        sort: GENERATOR_PARAMS.defaultSort,
    }), {
        isV2: true,
    });
};
export var sendGroupAction = function (data) {
    return api.post("/".concat(GENERATOR_PARAMS.basePathEntity, "/group-communication"), {
        isV2: true,
        data: data,
    });
};
export var sendApplicationInvitation = function (propertyId, data) {
    return api.post("/".concat(GENERATOR_PARAMS.basePathEntity, "/").concat(propertyId, "/invite-applicant"), {
        data: data,
        isV2: true,
    });
};
export var getAccountContactInfo = function (params) {
    var propertyId = params.propertyId, accountId = params.accountId;
    return api.get("/".concat(GENERATOR_PARAMS.basePathEntity, "/").concat(propertyId, "/members/").concat(accountId, "/light-contact-info"), { isV2: true });
};
export var getPropertyMissingKYCs = function (params) {
    var propertyId = params.propertyId;
    return api.get("/".concat(GENERATOR_PARAMS.basePathEntity, "/").concat(propertyId, "/missing-kycs"), { isV2: true });
};
