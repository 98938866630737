import { ThemeProvider as ThemeProviderNative } from "@rentiohq/shared-frontend/dist/components/theme/styled-components.native";
import rentioInsuranceTheme, {
  rentioInsuranceThemeNative,
} from "@rentiohq/shared-frontend/dist/components/theme/theme.rentio_insurance";
import { CONFIG } from "@rentiohq/shared-frontend/dist/config/app.config";
import { EProduct } from "@rentiohq/shared-frontend/dist/config/app.config.types";
import logger from "@rentiohq/shared-frontend/dist/logger";
import consoleLogger from "@rentiohq/shared-frontend/dist/logger/loggers/logger.console";
import sentryLogger from "@rentiohq/shared-frontend/dist/logger/loggers/logger.sentry";
import {
  IAlert,
  registerAlertTrigger,
} from "@rentiohq/shared-frontend/dist/utils/alert/alert.utils";
import { getMessageForError } from "@rentiohq/shared-frontend/dist/utils/error.utils";
import { setLocale } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { setStore } from "@rentiohq/shared-frontend/dist/utils/redux/redux.utils";
import {
  ErrorBoundary,
  Loading,
  ModalProvider,
} from "@rentiohq/web-shared/dist/components";
import {
  ToastContainer,
  toast,
} from "@rentiohq/web-shared/dist/managers/Toast";
import {
  GlobalStyle,
  ThemeProvider,
  insuranceTheme,
} from "@rentiohq/web-theme";
import * as Sentry from "@sentry/browser";
import merge from "lodash/merge";
import * as ReactDOM from "react-dom";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.minimal.css";
import store from "redux/store";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { AppRouter } from "router";
import "./index.css";
import * as serviceWorker from "./serviceWorker";

setLocale("NL");
setStore(store);

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: CONFIG.SENTRY[EProduct.InsuranceBackoffice],
    environment: CONFIG.ENVIRONMENT,
    release: CONFIG.SENTRY_RELEASE,
  });
  logger.registerLogger(sentryLogger);
}

if (process.env.NODE_ENV === "development") {
  logger.registerLogger(consoleLogger);
}

const alertTrigger = ({ message = "", error, type, content }: IAlert) => {
  toast({
    variation: type,
    heading: error ? getMessageForError(error) : message,
    content,
  });
};
registerAlertTrigger(alertTrigger);

ReactDOM.render(
  <BrowserRouter>
    <ReduxProvider store={store}>
      <PersistGate
        // @ts-ignore
        persistor={persistStore(store)}
        loading={<Loading />}
      >
        <ThemeProvider theme={merge({}, rentioInsuranceTheme, insuranceTheme)}>
          <ThemeProviderNative theme={rentioInsuranceThemeNative}>
            <GlobalStyle />
            <ErrorBoundary>
              <ModalProvider>
                <ToastContainer />
                <AppRouter />
              </ModalProvider>
            </ErrorBoundary>
          </ThemeProviderNative>
        </ThemeProvider>
      </PersistGate>
    </ReduxProvider>
  </BrowserRouter>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
