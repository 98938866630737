var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { flatten, uniq } from "lodash";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { CONFIG } from "../../config/app.config";
import logger from "../../logger";
import { throttleWithUniquePayload } from "../../redux/utils/saga.utils";
import { showAlert } from "../../utils/alert/alert.utils";
import { getGeneralArchiveFailureText, getGeneralArchiveSuccessText, getGeneralCreateFailureText, getGeneralCreateSuccessText, getGeneralRemoveFailureText, getGeneralRemoveSuccessText, getGeneralUnarchiveFailureText, getGeneralUnarchiveSuccessText, getGeneralUpdateFailureText, getGeneralUpdateSuccessText, getLocalizedText, } from "../../utils/i18n/i18n.utils";
import { join } from "../../utils/string.utils";
import { generateActions } from "./generator.actions";
import { generateApi } from "./generator.api";
import { generateSagaBase } from "./generator.sagaBase";
import { generateSelectors } from "./generator.selectors";
var generateGetDetailStartFlow = function (params) {
    var _a = generateActions(params), getDetailStart = _a.getDetailStart, getDetailSucceeded = _a.getDetailSucceeded, getDetailFailed = _a.getDetailFailed;
    var getDetailBase = generateSagaBase(params).getDetailBase;
    function getDetailStartFlow(action) {
        var _a, id, customPath, onSuccess, onFailure, result, unknownError_1, error;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _a = action.payload, id = _a.id, customPath = _a.customPath, onSuccess = _a.onSuccess, onFailure = _a.onFailure;
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 4, , 6]);
                    if (!id && !customPath) {
                        throw new Error("No id or custom path to fetch from");
                    }
                    return [4 /*yield*/, call(getDetailBase, action.payload)];
                case 2:
                    result = _b.sent();
                    return [4 /*yield*/, put(getDetailSucceeded.getAction({
                            id: id,
                            customPath: customPath,
                            result: result,
                        }))];
                case 3:
                    _b.sent();
                    onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess(result);
                    return [3 /*break*/, 6];
                case 4:
                    unknownError_1 = _b.sent();
                    error = unknownError_1;
                    logger.logError({ error: error });
                    return [4 /*yield*/, put(getDetailFailed.getAction({
                            id: id,
                            customPath: customPath,
                            error: error,
                        }))];
                case 5:
                    _b.sent();
                    onFailure === null || onFailure === void 0 ? void 0 : onFailure(error);
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/];
            }
        });
    }
    return getDetailStartFlow;
};
var generateGetPagedStartFlow = function (params) {
    var legacyGetPaged = params.legacyGetPaged;
    var _a = generateActions(params), getPagedStart = _a.getPagedStart, getPagedSucceeded = _a.getPagedSucceeded, getPagedFailed = _a.getPagedFailed;
    var _b = generateApi(params), getPaged = _b.getPaged, getPagedLegacy = _b.getPagedLegacy, getCountLegacy = _b.getCountLegacy;
    function getPagedStartFlow(action) {
        var _a, query, customPath, skipLegacyCount, skipLegacy, onSuccess, onFailure, response_1, countResponse, responseMapped, response, unknownError_2, error;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _a = action.payload, query = _a.query, customPath = _a.customPath, skipLegacyCount = _a.skipLegacyCount, skipLegacy = _a.skipLegacy, onSuccess = _a.onSuccess, onFailure = _a.onFailure;
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 9, , 11]);
                    if (!(legacyGetPaged && !skipLegacy)) return [3 /*break*/, 6];
                    return [4 /*yield*/, call(getPagedLegacy, { query: query, customPath: customPath })];
                case 2:
                    response_1 = _b.sent();
                    countResponse = void 0;
                    if (!!skipLegacyCount) return [3 /*break*/, 4];
                    return [4 /*yield*/, call(getCountLegacy, {
                            query: query,
                            customPath: customPath,
                        })];
                case 3:
                    countResponse = _b.sent();
                    _b.label = 4;
                case 4:
                    responseMapped = {
                        data: {
                            meta: {
                                totalItems: (countResponse === null || countResponse === void 0 ? void 0 : countResponse.data.count) || -1,
                                // Items in current response
                                itemCount: response_1.data.length,
                                itemsPerPage: query.limit,
                                totalPages: Math.ceil(((countResponse === null || countResponse === void 0 ? void 0 : countResponse.data.count) || -1) / query.limit),
                                currentPage: query.page,
                            },
                            links: {
                                first: "",
                                previous: "",
                                next: "",
                                last: "",
                            },
                            items: response_1.data,
                        },
                        debugInfo: {
                            legacySagaInterop: true,
                        },
                    };
                    return [4 /*yield*/, put(getPagedSucceeded.getAction({
                            query: query,
                            customPath: customPath,
                            result: responseMapped,
                        }))];
                case 5:
                    _b.sent();
                    onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess(responseMapped);
                    return [2 /*return*/];
                case 6: return [4 /*yield*/, call(getPaged, { query: query, customPath: customPath })];
                case 7:
                    response = _b.sent();
                    return [4 /*yield*/, put(getPagedSucceeded.getAction({
                            query: query,
                            customPath: customPath,
                            result: response.data,
                        }))];
                case 8:
                    _b.sent();
                    onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess(response.data);
                    return [3 /*break*/, 11];
                case 9:
                    unknownError_2 = _b.sent();
                    error = unknownError_2;
                    logger.logError({ error: error });
                    return [4 /*yield*/, put(getPagedFailed.getAction({
                            query: query,
                            customPath: customPath,
                            error: error,
                        }))];
                case 10:
                    _b.sent();
                    onFailure === null || onFailure === void 0 ? void 0 : onFailure(error);
                    return [3 /*break*/, 11];
                case 11: return [2 /*return*/];
            }
        });
    }
    return getPagedStartFlow;
};
var generateGetInfiniteLoadStartFlow = function (params) {
    var _a = generateActions(params), getInfiniteLoadStart = _a.getInfiniteLoadStart, getInfiniteLoadForPageStart = _a.getInfiniteLoadForPageStart;
    var getInfiniteLoadRawSelector = generateSelectors(params).getInfiniteLoadRaw;
    function getInfiniteLoadStartFlow(action) {
        var _a, query, customPath, refresh, onSuccess, onFailure, infiniteLoad, _b, page;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _a = action.payload, query = _a.query, customPath = _a.customPath, refresh = _a.refresh, onSuccess = _a.onSuccess, onFailure = _a.onFailure;
                    _b = getInfiniteLoadRawSelector;
                    return [4 /*yield*/, select()];
                case 1:
                    infiniteLoad = _b.apply(void 0, [_c.sent(), query,
                        customPath]);
                    page = (function () {
                        if (refresh) {
                            return 1;
                        }
                        var totalPages = infiniteLoad === null || infiniteLoad === void 0 ? void 0 : infiniteLoad.totalPages;
                        // Nothing fetched yet
                        if (totalPages === undefined) {
                            return 1;
                        }
                        for (var page_1 = 1; page_1 <= totalPages; page_1++) {
                            var pageState = infiniteLoad === null || infiniteLoad === void 0 ? void 0 : infiniteLoad.pages[page_1];
                            // No ID's and not pending
                            if (!(pageState === null || pageState === void 0 ? void 0 : pageState.ids) && !(pageState === null || pageState === void 0 ? void 0 : pageState.pending)) {
                                return page_1;
                            }
                        }
                    })();
                    if (!page) {
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, put(getInfiniteLoadForPageStart.getAction({
                            query: query,
                            customPath: customPath,
                            page: page,
                            onSuccess: onSuccess,
                            onFailure: onFailure,
                        }))];
                case 2:
                    _c.sent();
                    return [2 /*return*/];
            }
        });
    }
    return getInfiniteLoadStartFlow;
};
var generateGetInfiniteLoadForPageStartFlow = function (params) {
    var _a = generateActions(params), getInfiniteLoadForPageStart = _a.getInfiniteLoadForPageStart, getInfiniteLoadSucceeded = _a.getInfiniteLoadSucceeded, getInfiniteLoadFailed = _a.getInfiniteLoadFailed;
    var getInfiniteLoad = generateApi(params).getInfiniteLoad;
    function getInfiniteLoadForPageStartFlow(action) {
        var _a, query, customPath, page, onSuccess, onFailure, response, unknownError_3, error;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _a = action.payload, query = _a.query, customPath = _a.customPath, page = _a.page, onSuccess = _a.onSuccess, onFailure = _a.onFailure;
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 4, , 6]);
                    return [4 /*yield*/, call(getInfiniteLoad, {
                            query: __assign(__assign({ limit: CONFIG.DEFAULT_FETCH_LIMIT }, query), { page: page }),
                            customPath: customPath,
                        })];
                case 2:
                    response = _b.sent();
                    return [4 /*yield*/, put(getInfiniteLoadSucceeded.getAction({
                            query: query,
                            customPath: customPath,
                            page: page,
                            result: response.data,
                        }))];
                case 3:
                    _b.sent();
                    onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess(response.data);
                    return [3 /*break*/, 6];
                case 4:
                    unknownError_3 = _b.sent();
                    error = unknownError_3;
                    logger.logError({ error: error });
                    return [4 /*yield*/, put(getInfiniteLoadFailed.getAction({
                            query: query,
                            customPath: customPath,
                            page: page,
                            error: error,
                        }))];
                case 5:
                    _b.sent();
                    onFailure === null || onFailure === void 0 ? void 0 : onFailure(error);
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/];
            }
        });
    }
    return getInfiniteLoadForPageStartFlow;
};
var generateGetAllWhereStartFlow = function (params) {
    var legacyGetAllWhere = params.legacyGetAllWhere;
    var _a = generateActions(params), getAllWhereStart = _a.getAllWhereStart, getAllWhereSucceeded = _a.getAllWhereSucceeded, getAllWhereFailed = _a.getAllWhereFailed;
    var _b = generateApi(params), getAllWhere = _b.getAllWhere, getAllWhereLegacy = _b.getAllWhereLegacy;
    function getAllWhereStartFlow(action) {
        var _a, query, customPath, onSuccess, onFailure, DEFAULT_LIMIT, result_1, page_2, hasMorePages_1, response, isExhausted, result, page, hasMorePages, response, unknownError_4, error;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _a = action.payload, query = _a.query, customPath = _a.customPath, onSuccess = _a.onSuccess, onFailure = _a.onFailure;
                    DEFAULT_LIMIT = 30;
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 11, , 13]);
                    if (!legacyGetAllWhere) return [3 /*break*/, 6];
                    result_1 = [];
                    page_2 = 1;
                    hasMorePages_1 = true;
                    _b.label = 2;
                case 2:
                    if (!hasMorePages_1) return [3 /*break*/, 4];
                    return [4 /*yield*/, call(getAllWhereLegacy, {
                            query: __assign(__assign({}, query), { page: 1, limit: DEFAULT_LIMIT }),
                            customPath: customPath,
                        })];
                case 3:
                    response = _b.sent();
                    result_1.push.apply(result_1, response.data);
                    isExhausted = response.data.length < DEFAULT_LIMIT;
                    if (!isExhausted) {
                        hasMorePages_1 = false;
                    }
                    page_2 += 1;
                    return [3 /*break*/, 2];
                case 4: return [4 /*yield*/, put(getAllWhereSucceeded.getAction({
                        query: query,
                        customPath: customPath,
                        result: result_1,
                    }))];
                case 5:
                    _b.sent();
                    onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess(result_1);
                    return [2 /*return*/];
                case 6:
                    result = [];
                    page = 1;
                    hasMorePages = true;
                    _b.label = 7;
                case 7:
                    if (!hasMorePages) return [3 /*break*/, 9];
                    return [4 /*yield*/, call(getAllWhere, {
                            query: __assign(__assign({}, query), { page: page, limit: DEFAULT_LIMIT }),
                            customPath: customPath,
                        })];
                case 8:
                    response = _b.sent();
                    result.push.apply(result, response.data.data.items);
                    if (page >= response.data.data.meta.totalPages) {
                        hasMorePages = false;
                    }
                    page += 1;
                    return [3 /*break*/, 7];
                case 9: return [4 /*yield*/, put(getAllWhereSucceeded.getAction({
                        query: query,
                        customPath: customPath,
                        result: result,
                    }))];
                case 10:
                    _b.sent();
                    onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess(result);
                    return [3 /*break*/, 13];
                case 11:
                    unknownError_4 = _b.sent();
                    error = unknownError_4;
                    logger.logError({ error: error });
                    return [4 /*yield*/, put(getAllWhereFailed.getAction({
                            query: query,
                            customPath: customPath,
                            error: error,
                        }))];
                case 12:
                    _b.sent();
                    onFailure === null || onFailure === void 0 ? void 0 : onFailure(error);
                    return [3 /*break*/, 13];
                case 13: return [2 /*return*/];
            }
        });
    }
    return getAllWhereStartFlow;
};
var generateGetCountStartFlow = function (params) {
    var legacyGetPaged = params.legacyGetPaged;
    var _a = generateActions(params), getCountStart = _a.getCountStart, getCountFailed = _a.getCountFailed, getCountSucceeded = _a.getCountSucceeded;
    var _b = generateApi(params), getCount = _b.getCount, getCountLegacy = _b.getCountLegacy;
    function getCountStartFlow(action) {
        var _a, query, customPath, skipLegacy, onFailure, onSuccess, countResponse, responseMapped, response, unknownError_5, error;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _a = action.payload, query = _a.query, customPath = _a.customPath, skipLegacy = _a.skipLegacy, onFailure = _a.onFailure, onSuccess = _a.onSuccess;
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 7, , 9]);
                    if (!(legacyGetPaged && !skipLegacy)) return [3 /*break*/, 4];
                    return [4 /*yield*/, call(getCountLegacy, { query: query, customPath: customPath })];
                case 2:
                    countResponse = _b.sent();
                    responseMapped = {
                        data: {
                            count: countResponse.data.count,
                        },
                        debugInfo: {
                            legacySagaInterop: true,
                        },
                    };
                    return [4 /*yield*/, put(getCountSucceeded.getAction({
                            query: query,
                            customPath: customPath,
                            result: responseMapped,
                        }))];
                case 3:
                    _b.sent();
                    onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess(responseMapped);
                    return [2 /*return*/];
                case 4: return [4 /*yield*/, call(getCount, { query: query, customPath: customPath })];
                case 5:
                    response = _b.sent();
                    return [4 /*yield*/, put(getCountSucceeded.getAction({
                            query: query,
                            customPath: customPath,
                            result: response.data,
                        }))];
                case 6:
                    _b.sent();
                    onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess(response.data);
                    return [3 /*break*/, 9];
                case 7:
                    unknownError_5 = _b.sent();
                    error = unknownError_5;
                    logger.logError({ error: error });
                    return [4 /*yield*/, put(getCountFailed.getAction({
                            query: query,
                            customPath: customPath,
                            error: error,
                        }))];
                case 8:
                    _b.sent();
                    onFailure === null || onFailure === void 0 ? void 0 : onFailure(error);
                    return [3 /*break*/, 9];
                case 9: return [2 /*return*/];
            }
        });
    }
    return getCountStartFlow;
};
var generateCreateStartFlow = function (params) {
    var entity = params.entity, legacyCreate = params.legacyCreate;
    var _a = generateActions(params), createStart = _a.createStart, createSucceeded = _a.createSucceeded, createFailed = _a.createFailed;
    var _b = generateApi(params), create = _b.create, createLegacy = _b.createLegacy;
    function createStartFlow(action) {
        var _a, dataRaw, onSuccess, onFailure, afterCreate, _b, suppressSuccessAlert, _c, suppressFailureAlert, customSuccessMessage, customFailureMessage, customPath, config, data_1, response_2, responseMapped, response, unknownError_6, error;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    _a = action.payload, dataRaw = _a.data, onSuccess = _a.onSuccess, onFailure = _a.onFailure, afterCreate = _a.afterCreate, _b = _a.suppressSuccessAlert, suppressSuccessAlert = _b === void 0 ? false : _b, _c = _a.suppressFailureAlert, suppressFailureAlert = _c === void 0 ? false : _c, customSuccessMessage = _a.customSuccessMessage, customFailureMessage = _a.customFailureMessage, customPath = _a.customPath, config = _a.config;
                    _d.label = 1;
                case 1:
                    _d.trys.push([1, 9, , 11]);
                    data_1 = __assign({}, dataRaw);
                    if (config === null || config === void 0 ? void 0 : config.keysToRemoveFromRequest) {
                        config === null || config === void 0 ? void 0 : config.keysToRemoveFromRequest.forEach(function (key) { return delete data_1[key]; });
                    }
                    if (!legacyCreate) return [3 /*break*/, 4];
                    return [4 /*yield*/, call(createLegacy, {
                            data: data_1,
                            customPath: customPath,
                        })];
                case 2:
                    response_2 = _d.sent();
                    if (!suppressSuccessAlert) {
                        showAlert({
                            type: "success",
                            message: customSuccessMessage || getGeneralCreateSuccessText(entity),
                        });
                    }
                    responseMapped = {
                        data: response_2.data,
                        debugInfo: {
                            legacySagaInterop: true,
                        },
                    };
                    return [4 /*yield*/, put(createSucceeded.getAction({
                            result: responseMapped,
                        }))];
                case 3:
                    _d.sent();
                    onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess(responseMapped);
                    return [2 /*return*/];
                case 4: return [4 /*yield*/, call(create, {
                        data: data_1,
                        customPath: customPath,
                    })];
                case 5:
                    response = _d.sent();
                    if (!suppressSuccessAlert) {
                        showAlert({
                            type: "success",
                            message: customSuccessMessage || getGeneralCreateSuccessText(entity),
                        });
                    }
                    if (!afterCreate) return [3 /*break*/, 7];
                    return [4 /*yield*/, afterCreate(dataRaw, response.data.data)];
                case 6:
                    _d.sent();
                    _d.label = 7;
                case 7: return [4 /*yield*/, put(createSucceeded.getAction({
                        result: response.data,
                    }))];
                case 8:
                    _d.sent();
                    onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess(response.data);
                    return [3 /*break*/, 11];
                case 9:
                    unknownError_6 = _d.sent();
                    error = unknownError_6;
                    logger.logError({ error: error });
                    if (!suppressFailureAlert) {
                        showAlert({
                            type: "error",
                            error: error,
                            message: customFailureMessage || getGeneralCreateFailureText(entity),
                        });
                    }
                    return [4 /*yield*/, put(createFailed.getAction({
                            error: error,
                        }))];
                case 10:
                    _d.sent();
                    onFailure === null || onFailure === void 0 ? void 0 : onFailure(error);
                    return [3 /*break*/, 11];
                case 11: return [2 /*return*/];
            }
        });
    }
    return createStartFlow;
};
var generateUpdateStartFlow = function (params) {
    var entity = params.entity, legacyUpdate = params.legacyUpdate;
    var _a = generateActions(params), updateStart = _a.updateStart, updateSucceeded = _a.updateSucceeded, updateFailed = _a.updateFailed;
    var _b = generateApi(params), update = _b.update, updateLegacy = _b.updateLegacy;
    function updateStartFlow(action) {
        var _a, id, data, customPath, onSuccess, onFailure, _b, suppressSuccessAlert, _c, suppressFailureAlert, customSuccessMessage, customFailureMessage, response_3, responseMapped, response, unknownError_7, error;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    _a = action.payload, id = _a.id, data = _a.data, customPath = _a.customPath, onSuccess = _a.onSuccess, onFailure = _a.onFailure, _b = _a.suppressSuccessAlert, suppressSuccessAlert = _b === void 0 ? false : _b, _c = _a.suppressFailureAlert, suppressFailureAlert = _c === void 0 ? false : _c, customSuccessMessage = _a.customSuccessMessage, customFailureMessage = _a.customFailureMessage;
                    _d.label = 1;
                case 1:
                    _d.trys.push([1, 7, , 9]);
                    if (!legacyUpdate) return [3 /*break*/, 4];
                    return [4 /*yield*/, call(updateLegacy, {
                            id: id,
                            data: data,
                        })];
                case 2:
                    response_3 = _d.sent();
                    if (!suppressSuccessAlert) {
                        showAlert({
                            type: "success",
                            message: customSuccessMessage || getGeneralUpdateSuccessText(entity),
                        });
                    }
                    responseMapped = {
                        data: response_3.data,
                        debugInfo: {
                            legacySagaInterop: true,
                        },
                    };
                    return [4 /*yield*/, put(updateSucceeded.getAction({
                            id: id,
                            result: responseMapped,
                        }))];
                case 3:
                    _d.sent();
                    onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess(responseMapped);
                    return [2 /*return*/];
                case 4: return [4 /*yield*/, call(update, {
                        id: id,
                        data: data,
                        customPath: customPath,
                    })];
                case 5:
                    response = _d.sent();
                    if (!suppressSuccessAlert) {
                        showAlert({
                            type: "success",
                            message: customSuccessMessage || getGeneralUpdateSuccessText(entity),
                        });
                    }
                    return [4 /*yield*/, put(updateSucceeded.getAction({
                            id: id,
                            result: response.data,
                        }))];
                case 6:
                    _d.sent();
                    onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess(response.data);
                    return [3 /*break*/, 9];
                case 7:
                    unknownError_7 = _d.sent();
                    error = unknownError_7;
                    logger.logError({ error: error });
                    if (!suppressFailureAlert) {
                        showAlert({
                            type: "error",
                            error: error,
                            message: customFailureMessage || getGeneralUpdateFailureText(entity),
                        });
                    }
                    return [4 /*yield*/, put(updateFailed.getAction({ id: id, error: error }))];
                case 8:
                    _d.sent();
                    onFailure === null || onFailure === void 0 ? void 0 : onFailure(error);
                    return [3 /*break*/, 9];
                case 9: return [2 /*return*/];
            }
        });
    }
    return updateStartFlow;
};
var generateRemoveStartFlow = function (params) {
    var entity = params.entity;
    var _a = generateActions(params), removeStart = _a.removeStart, removeSucceeded = _a.removeSucceeded, removeFailed = _a.removeFailed;
    var remove = generateApi(params).remove;
    function removeStartFlow(action) {
        var _a, id, onSuccess, onFailure, _b, suppressSuccessAlert, _c, suppressFailureAlert, customSuccessMessage, customFailureMessage, unknownError_8, error;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    _a = action.payload, id = _a.id, onSuccess = _a.onSuccess, onFailure = _a.onFailure, _b = _a.suppressSuccessAlert, suppressSuccessAlert = _b === void 0 ? false : _b, _c = _a.suppressFailureAlert, suppressFailureAlert = _c === void 0 ? false : _c, customSuccessMessage = _a.customSuccessMessage, customFailureMessage = _a.customFailureMessage;
                    _d.label = 1;
                case 1:
                    _d.trys.push([1, 4, , 6]);
                    return [4 /*yield*/, call(remove, {
                            id: id,
                        })];
                case 2:
                    _d.sent();
                    if (!suppressSuccessAlert) {
                        showAlert({
                            type: "success",
                            message: customSuccessMessage || getGeneralRemoveSuccessText(entity),
                        });
                    }
                    return [4 /*yield*/, put(removeSucceeded.getAction({
                            id: id,
                        }))];
                case 3:
                    _d.sent();
                    onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess();
                    return [3 /*break*/, 6];
                case 4:
                    unknownError_8 = _d.sent();
                    error = unknownError_8;
                    logger.logError({ error: error });
                    if (!suppressFailureAlert) {
                        showAlert({
                            type: "error",
                            error: error,
                            message: customFailureMessage || getGeneralRemoveFailureText(entity),
                        });
                    }
                    return [4 /*yield*/, put(removeFailed.getAction({ id: id, error: error }))];
                case 5:
                    _d.sent();
                    onFailure === null || onFailure === void 0 ? void 0 : onFailure(error);
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/];
            }
        });
    }
    return removeStartFlow;
};
// TODO: most of the logic is the same as updateMembersStartFlow, maybe we can merge them
var generateUpdateEmployeesStartFlow = function (params) {
    var entity = params.entity;
    var _a = generateActions(params), updateEmployeesStart = _a.updateEmployeesStart, updateEmployeesSucceeded = _a.updateEmployeesSucceeded, updateEmployeesFailed = _a.updateEmployeesFailed;
    var getDetailBase = generateSagaBase(params).getDetailBase;
    var _b = generateApi(params), addEmployees = _b.addEmployees, removeEmployees = _b.removeEmployees;
    function updateEmployeesStartFlow(action) {
        var _a, id, employeesToAdd, employeesToRemove, onSuccess, onFailure, _b, suppressSuccessAlert, _c, suppressFailureAlert, customSuccessMessage, customFailureMessage, response, key, roles, unknownError_9, error;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    _a = action.payload, id = _a.id, employeesToAdd = _a.employeesToAdd, employeesToRemove = _a.employeesToRemove, onSuccess = _a.onSuccess, onFailure = _a.onFailure, _b = _a.suppressSuccessAlert, suppressSuccessAlert = _b === void 0 ? false : _b, _c = _a.suppressFailureAlert, suppressFailureAlert = _c === void 0 ? false : _c, customSuccessMessage = _a.customSuccessMessage, customFailureMessage = _a.customFailureMessage;
                    _d.label = 1;
                case 1:
                    _d.trys.push([1, 8, , 10]);
                    if (!(employeesToRemove.length > 0)) return [3 /*break*/, 3];
                    return [4 /*yield*/, call(removeEmployees, {
                            id: id,
                            employees: employeesToRemove,
                        })];
                case 2:
                    _d.sent();
                    _d.label = 3;
                case 3:
                    if (!(employeesToAdd.length > 0)) return [3 /*break*/, 5];
                    return [4 /*yield*/, call(addEmployees, {
                            id: id,
                            employees: employeesToAdd,
                        })];
                case 4:
                    _d.sent();
                    _d.label = 5;
                case 5: return [4 /*yield*/, call(getDetailBase, action.payload)];
                case 6:
                    response = (_d.sent());
                    if (!suppressSuccessAlert) {
                        key = "member.update.success";
                        if (employeesToAdd.length > 0 && employeesToRemove.length === 0) {
                            key = "member.add.success";
                        }
                        else if (employeesToAdd.length === 0 &&
                            employeesToRemove.length > 0) {
                            key = "member.delete.success";
                        }
                        roles = join(uniq(flatten(__spreadArray(__spreadArray([], employeesToAdd, true), employeesToRemove, true).map(function (member) { return member.roles; }))).map(function (role) {
                            return getLocalizedText("role.".concat(role).toLowerCase(), undefined, 1);
                        }));
                        showAlert({
                            type: "success",
                            message: customSuccessMessage || getLocalizedText(key, { role: roles }),
                        });
                    }
                    return [4 /*yield*/, put(updateEmployeesSucceeded.getAction({
                            id: id,
                            result: response,
                        }))];
                case 7:
                    _d.sent();
                    onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess(response);
                    return [3 /*break*/, 10];
                case 8:
                    unknownError_9 = _d.sent();
                    error = unknownError_9;
                    logger.logError({ error: error });
                    if (!suppressFailureAlert) {
                        showAlert({
                            type: "error",
                            error: error,
                            message: customFailureMessage || getGeneralUpdateFailureText(entity),
                        });
                    }
                    return [4 /*yield*/, put(updateEmployeesFailed.getAction({ id: id, error: error }))];
                case 9:
                    _d.sent();
                    onFailure === null || onFailure === void 0 ? void 0 : onFailure(error);
                    return [3 /*break*/, 10];
                case 10: return [2 /*return*/];
            }
        });
    }
    return updateEmployeesStartFlow;
};
var generateUpdateMembersStartFlow = function (params) {
    var entity = params.entity;
    var _a = generateActions(params), updateMembersStart = _a.updateMembersStart, updateMembersSucceeded = _a.updateMembersSucceeded, updateMembersFailed = _a.updateMembersFailed;
    var _b = generateApi(params), addMembers = _b.addMembers, removeMembers = _b.removeMembers;
    var getDetailBase = generateSagaBase(params).getDetailBase;
    function updateMembersStartFlow(action) {
        var _a, id, membersToAdd, membersToRemove, additionalParams, onSuccess, onFailure, _b, suppressSuccessAlert, _c, suppressFailureAlert, customSuccessMessage, customFailureMessage, response, key, roles, unknownError_10, error;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    _a = action.payload, id = _a.id, membersToAdd = _a.membersToAdd, membersToRemove = _a.membersToRemove, additionalParams = _a.additionalParams, onSuccess = _a.onSuccess, onFailure = _a.onFailure, _b = _a.suppressSuccessAlert, suppressSuccessAlert = _b === void 0 ? false : _b, _c = _a.suppressFailureAlert, suppressFailureAlert = _c === void 0 ? false : _c, customSuccessMessage = _a.customSuccessMessage, customFailureMessage = _a.customFailureMessage;
                    _d.label = 1;
                case 1:
                    _d.trys.push([1, 8, , 10]);
                    if (!(membersToAdd.length > 0)) return [3 /*break*/, 3];
                    return [4 /*yield*/, call(addMembers, {
                            id: id,
                            members: membersToAdd,
                            additionalParams: additionalParams,
                        })];
                case 2:
                    _d.sent();
                    _d.label = 3;
                case 3:
                    if (!(membersToRemove.length > 0)) return [3 /*break*/, 5];
                    return [4 /*yield*/, call(removeMembers, {
                            id: id,
                            members: membersToRemove,
                        })];
                case 4:
                    _d.sent();
                    _d.label = 5;
                case 5: return [4 /*yield*/, call(getDetailBase, action.payload)];
                case 6:
                    response = (_d.sent());
                    if (!suppressSuccessAlert) {
                        key = "member.add.success";
                        if (membersToAdd.length > 0 && membersToRemove.length === 0) {
                            key = "member.add.success";
                        }
                        else if (membersToAdd.length === 0 && membersToRemove.length > 0) {
                            key = "member.delete.success";
                        }
                        roles = join(flatten(__spreadArray(__spreadArray([], membersToAdd, true), membersToRemove, true).map(function (member) { return member.roles; })).map(function (role) {
                            return getLocalizedText("role.".concat(role).toLowerCase(), undefined, 1);
                        }));
                        showAlert({
                            type: "success",
                            message: customSuccessMessage || getLocalizedText(key, { role: roles }),
                        });
                    }
                    return [4 /*yield*/, put(updateMembersSucceeded.getAction({
                            id: id,
                            result: response,
                        }))];
                case 7:
                    _d.sent();
                    onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess(response);
                    return [3 /*break*/, 10];
                case 8:
                    unknownError_10 = _d.sent();
                    error = unknownError_10;
                    logger.logError({ error: error });
                    if (!suppressFailureAlert) {
                        showAlert({
                            type: "error",
                            error: error,
                            message: customFailureMessage || getGeneralUpdateFailureText(entity),
                        });
                    }
                    return [4 /*yield*/, put(updateMembersFailed.getAction({ id: id, error: error }))];
                case 9:
                    _d.sent();
                    onFailure === null || onFailure === void 0 ? void 0 : onFailure(error);
                    return [3 /*break*/, 10];
                case 10: return [2 /*return*/];
            }
        });
    }
    return updateMembersStartFlow;
};
var generateArchivingStartFlow = function (params) {
    var entity = params.entity;
    var _a = generateActions(params), archiveStart = _a.archiveStart, archiveSucceeded = _a.archiveSucceeded, archiveFailed = _a.archiveFailed, unarchiveStart = _a.unarchiveStart, unarchiveSucceeded = _a.unarchiveSucceeded, unarchiveFailed = _a.unarchiveFailed;
    var _b = generateApi(params), archive = _b.archive, unarchive = _b.unarchive;
    function archiveStartFlow(action) {
        var _a, id, onSuccess, onFailure, _b, suppressSuccessAlert, _c, suppressFailureAlert, customSuccessMessage, customFailureMessage, result, unknownError_11, error;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    _a = action.payload, id = _a.id, onSuccess = _a.onSuccess, onFailure = _a.onFailure, _b = _a.suppressSuccessAlert, suppressSuccessAlert = _b === void 0 ? false : _b, _c = _a.suppressFailureAlert, suppressFailureAlert = _c === void 0 ? false : _c, customSuccessMessage = _a.customSuccessMessage, customFailureMessage = _a.customFailureMessage;
                    _d.label = 1;
                case 1:
                    _d.trys.push([1, 4, , 6]);
                    return [4 /*yield*/, call(archive, {
                            id: id,
                        })];
                case 2:
                    result = _d.sent();
                    if (!suppressSuccessAlert) {
                        showAlert({
                            type: "success",
                            message: customSuccessMessage || getGeneralArchiveSuccessText(entity),
                        });
                    }
                    return [4 /*yield*/, put(archiveSucceeded.getAction({
                            id: id,
                            result: result.data,
                        }))];
                case 3:
                    _d.sent();
                    onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess(result.data.data);
                    return [3 /*break*/, 6];
                case 4:
                    unknownError_11 = _d.sent();
                    error = unknownError_11;
                    logger.logError({ error: error });
                    if (!suppressFailureAlert) {
                        showAlert({
                            type: "error",
                            error: error,
                            message: customFailureMessage || getGeneralArchiveFailureText(entity),
                        });
                    }
                    return [4 /*yield*/, put(archiveFailed.getAction({ id: id, error: error }))];
                case 5:
                    _d.sent();
                    onFailure === null || onFailure === void 0 ? void 0 : onFailure(error);
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/];
            }
        });
    }
    function unarchiveStartFlow(action) {
        var _a, id, onSuccess, onFailure, _b, suppressSuccessAlert, _c, suppressFailureAlert, customSuccessMessage, customFailureMessage, result, unknownError_12, error;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    _a = action.payload, id = _a.id, onSuccess = _a.onSuccess, onFailure = _a.onFailure, _b = _a.suppressSuccessAlert, suppressSuccessAlert = _b === void 0 ? false : _b, _c = _a.suppressFailureAlert, suppressFailureAlert = _c === void 0 ? false : _c, customSuccessMessage = _a.customSuccessMessage, customFailureMessage = _a.customFailureMessage;
                    _d.label = 1;
                case 1:
                    _d.trys.push([1, 4, , 6]);
                    return [4 /*yield*/, call(unarchive, {
                            id: id,
                        })];
                case 2:
                    result = _d.sent();
                    if (!suppressSuccessAlert) {
                        showAlert({
                            type: "success",
                            message: customSuccessMessage || getGeneralUnarchiveSuccessText(entity),
                        });
                    }
                    return [4 /*yield*/, put(unarchiveSucceeded.getAction({
                            id: id,
                            result: result.data,
                        }))];
                case 3:
                    _d.sent();
                    onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess(result.data.data);
                    return [3 /*break*/, 6];
                case 4:
                    unknownError_12 = _d.sent();
                    error = unknownError_12;
                    logger.logError({ error: error });
                    if (!suppressFailureAlert) {
                        showAlert({
                            type: "error",
                            error: error,
                            message: customFailureMessage || getGeneralUnarchiveFailureText(entity),
                        });
                    }
                    return [4 /*yield*/, put(unarchiveFailed.getAction({ id: id, error: error }))];
                case 5:
                    _d.sent();
                    onFailure === null || onFailure === void 0 ? void 0 : onFailure(error);
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/];
            }
        });
    }
    return { archiveStartFlow: archiveStartFlow, unarchiveStartFlow: unarchiveStartFlow };
};
export function generateSaga(params) {
    var _a, getDetailStart, getDetailStartNoThrottle, getPagedStart, getPagedStartNoThrottle, getInfiniteLoadStart, getInfiniteLoadStartNoThrottle, getInfiniteLoadForPageStart, getAllWhereStart, getAllWhereStartNoThrottle, getCountStart, getCountStartNoThrottle, createStart, updateStart, updateMembersStart, updateEmployeesStart, removeStart, archiveStart, unarchiveStart, getDetailStartFlow, getPagedStartFlow, getInfiniteLoadStartFlow, getInfiniteLoadForPageStartFlow, getAllWhereStartFlow, getCountStartFlow, createStartFlow, updateStartFlow, updateMembersStartFlow, updateEmployeesStartFlow, removeStartFlow, _b, archiveStartFlow, unarchiveStartFlow;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = generateActions(params), getDetailStart = _a.getDetailStart, getDetailStartNoThrottle = _a.getDetailStartNoThrottle, getPagedStart = _a.getPagedStart, getPagedStartNoThrottle = _a.getPagedStartNoThrottle, getInfiniteLoadStart = _a.getInfiniteLoadStart, getInfiniteLoadStartNoThrottle = _a.getInfiniteLoadStartNoThrottle, getInfiniteLoadForPageStart = _a.getInfiniteLoadForPageStart, getAllWhereStart = _a.getAllWhereStart, getAllWhereStartNoThrottle = _a.getAllWhereStartNoThrottle, getCountStart = _a.getCountStart, getCountStartNoThrottle = _a.getCountStartNoThrottle, createStart = _a.createStart, updateStart = _a.updateStart, updateMembersStart = _a.updateMembersStart, updateEmployeesStart = _a.updateEmployeesStart, removeStart = _a.removeStart, archiveStart = _a.archiveStart, unarchiveStart = _a.unarchiveStart;
                getDetailStartFlow = generateGetDetailStartFlow(params);
                getPagedStartFlow = generateGetPagedStartFlow(params);
                getInfiniteLoadStartFlow = generateGetInfiniteLoadStartFlow(params);
                getInfiniteLoadForPageStartFlow = generateGetInfiniteLoadForPageStartFlow(params);
                getAllWhereStartFlow = generateGetAllWhereStartFlow(params);
                getCountStartFlow = generateGetCountStartFlow(params);
                createStartFlow = generateCreateStartFlow(params);
                updateStartFlow = generateUpdateStartFlow(params);
                updateMembersStartFlow = generateUpdateMembersStartFlow(params);
                updateEmployeesStartFlow = generateUpdateEmployeesStartFlow(params);
                removeStartFlow = generateRemoveStartFlow(params);
                _b = generateArchivingStartFlow(params), archiveStartFlow = _b.archiveStartFlow, unarchiveStartFlow = _b.unarchiveStartFlow;
                return [4 /*yield*/, throttleWithUniquePayload({
                        patternThrottle: getDetailStart.type,
                        patternNoThrottle: getDetailStartNoThrottle.type,
                        saga: getDetailStartFlow,
                    })];
            case 1:
                _c.sent();
                return [4 /*yield*/, throttleWithUniquePayload({
                        patternThrottle: getPagedStart.type,
                        patternNoThrottle: getPagedStartNoThrottle.type,
                        saga: getPagedStartFlow,
                    })];
            case 2:
                _c.sent();
                return [4 /*yield*/, throttleWithUniquePayload({
                        patternThrottle: getInfiniteLoadStart.type,
                        patternNoThrottle: getInfiniteLoadStartNoThrottle.type,
                        saga: getInfiniteLoadStartFlow,
                    })];
            case 3:
                _c.sent();
                return [4 /*yield*/, takeEvery(getInfiniteLoadForPageStart.type, getInfiniteLoadForPageStartFlow)];
            case 4:
                _c.sent();
                return [4 /*yield*/, throttleWithUniquePayload({
                        patternThrottle: getAllWhereStart.type,
                        patternNoThrottle: getAllWhereStartNoThrottle.type,
                        saga: getAllWhereStartFlow,
                    })];
            case 5:
                _c.sent();
                return [4 /*yield*/, throttleWithUniquePayload({
                        patternThrottle: getCountStart.type,
                        patternNoThrottle: getCountStartNoThrottle.type,
                        saga: getCountStartFlow,
                    })];
            case 6:
                _c.sent();
                return [4 /*yield*/, takeEvery(createStart.type, createStartFlow)];
            case 7:
                _c.sent();
                return [4 /*yield*/, takeEvery(updateStart.type, updateStartFlow)];
            case 8:
                _c.sent();
                return [4 /*yield*/, takeEvery(updateMembersStart.type, updateMembersStartFlow)];
            case 9:
                _c.sent();
                return [4 /*yield*/, takeEvery(updateEmployeesStart.type, updateEmployeesStartFlow)];
            case 10:
                _c.sent();
                return [4 /*yield*/, takeEvery(removeStart.type, removeStartFlow)];
            case 11:
                _c.sent();
                return [4 /*yield*/, takeEvery(archiveStart.type, archiveStartFlow)];
            case 12:
                _c.sent();
                return [4 /*yield*/, takeEvery(unarchiveStart.type, unarchiveStartFlow)];
            case 13:
                _c.sent();
                return [2 /*return*/];
        }
    });
}
