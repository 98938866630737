var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { ThemeConsumer } from "@rentiohq/web-theme";
import hash from "object-hash";
import { rem, stripUnit, transitions } from "polished";
import React from "react";
import { components as selectComponents } from "react-select";
import { Icon, Labelled } from "..";
import * as t from "../../services/translationService";
export var createSelect = function (WrappedComponent) {
    var RentioSelect = function (props) {
        var selectId = "select_field_".concat(hash("".concat(props.id, "_").concat(props.name, "_").concat(props.label)));
        var label = props.label, name = props.name, noResultsMessage = props.noResultsMessage, value = props.value, createMessage = props.createMessage, helpText = props.helpText, id = props.id, error = props.error, _a = props.errorMessageHidden, errorMessageHidden = _a === void 0 ? false : _a, _b = props.labelHidden, labelHidden = _b === void 0 ? false : _b, options = props.options, defaultOptions = props.defaultOptions, optionRender = props.optionRender, noOptionRender = props.noOptionRender, _c = props.onChange, onChange = _c === void 0 ? null : _c, onFocus = props.onFocus, onMenuOpen = props.onMenuOpen, _d = props.components, components = _d === void 0 ? {} : _d, allowNewOption = props.allowNewOption, _e = props.placeholder, placeholder = _e === void 0 ? t.system("select") : _e, descriptionText = props.descriptionText, optional = props.optional, _f = props.isDropdownIndicatorHidden, isDropdownIndicatorHidden = _f === void 0 ? false : _f, _g = props.icon, icon = _g === void 0 ? _jsx(Icon, { source: "chevronDown" }) : _g, _h = props.clearValueAfterSelection, clearValueAfterSelection = _h === void 0 ? false : _h, onInputChange = props.onInputChange, onPressExtraInfo = props.onPressExtraInfo, _j = props.menuPortalTarget, menuPortalTarget = _j === void 0 ? document.getElementById(selectId) : _j, field = __rest(props, ["label", "name", "noResultsMessage", "value", "createMessage", "helpText", "id", "error", "errorMessageHidden", "labelHidden", "options", "defaultOptions", "optionRender", "noOptionRender", "onChange", "onFocus", "onMenuOpen", "components", "allowNewOption", "placeholder", "descriptionText", "optional", "isDropdownIndicatorHidden", "icon", "clearValueAfterSelection", "onInputChange", "onPressExtraInfo", "menuPortalTarget"]);
        var _k = React.useState(), currentValue = _k[0], setCurrentValue = _k[1];
        React.useEffect(function () {
            if (options) {
                var initValue = options.find(function (option) { return option.value === value; });
                setCurrentValue(initValue ? initValue : null);
            }
            if (defaultOptions instanceof Array) {
                var initValue = defaultOptions.find(function (option) { return option.value === value; });
                setCurrentValue(initValue ? initValue : null);
            }
        }, [defaultOptions, options, value]);
        var DropdownIndicator = function (props) {
            return isDropdownIndicatorHidden
                ? null
                : selectComponents.DropdownIndicator && (_jsx(selectComponents.DropdownIndicator, __assign({}, props, { children: icon })));
        };
        var Option = function (props) {
            return (_jsx(selectComponents.Option, __assign({}, props, { children: optionRender ? optionRender(props) : props.children })));
        };
        var NoOptionsMessage = function (props) {
            return (_jsx(selectComponents.NoOptionsMessage, __assign({}, props, { children: noOptionRender ? noOptionRender(props) : props.children })));
        };
        var Placeholder = function (props) {
            return props.isFocused ? null : (_jsx(selectComponents.Placeholder, __assign({}, props)));
        };
        if (typeof allowNewOption !== "undefined") {
            field.isValidNewOption = function () { return allowNewOption; };
        }
        var handleChange = function (selectedOption) {
            clearValueAfterSelection
                ? setCurrentValue([])
                : setCurrentValue(selectedOption);
            if (onChange) {
                onChange(selectedOption);
            }
        };
        var handleInputChange = function (value) {
            if (onInputChange) {
                onInputChange(value);
            }
            clearValueAfterSelection && setCurrentValue([]);
        };
        var addNoOptionsMessage = function () {
            return noResultsMessage ? noResultsMessage : t.searchNoResultsDefault();
        };
        var addCreateMessage = function () {
            return createMessage ? createMessage : t.searchCreateCTADefault();
        };
        var renderSelect = (_jsx(ThemeConsumer, { children: function (theme) {
                var getOptionBackgroundColor = function (state) {
                    if (state.isSelected) {
                        return theme.getColor("primary");
                    }
                    else if (state.isFocused) {
                        return theme.getColor("primary", 0);
                    }
                    else {
                        theme.getColor("white");
                    }
                };
                var getControlBorderColor = function (state) {
                    if (error) {
                        return theme.inputStyles.borderColor.error;
                    }
                    if (state.isFocused) {
                        return theme.inputStyles.borderColor.focus;
                    }
                    else {
                        return theme.inputStyles.borderColor.base;
                    }
                };
                var getControlBoxShadow = function (state) {
                    if (error && state.isFocused) {
                        return theme.inputStyles.boxShadowColor.error;
                    }
                    if (state.isFocused) {
                        return theme.inputStyles.boxShadowColor.focus;
                    }
                    else {
                        return "transparent";
                    }
                };
                return (_jsx(WrappedComponent, __assign({}, field, { id: selectId, options: options, defaultOptions: defaultOptions, value: currentValue, components: __assign({ DropdownIndicator: DropdownIndicator, Option: Option, NoOptionsMessage: NoOptionsMessage, Placeholder: Placeholder, IndicatorSeparator: null }, components), onFocus: onFocus, onMenuOpen: onMenuOpen, onChange: handleChange, onInputChange: handleInputChange, menuShouldScrollIntoView: true, formatCreateLabel: addCreateMessage, noOptionsMessage: addNoOptionsMessage, menuPortalTarget: menuPortalTarget, placeholder: placeholder, styles: {
                        option: function (base, state) {
                            var isNew = state.data.__isNew__;
                            var optionStyles = isNew
                                ? __assign(__assign({}, base), { backgroundColor: "".concat(theme.getColor("primary", 600)), borderRadius: "".concat(theme.borderRadius("small")), color: theme.getColor("white"), lineHeight: 1, textAlign: "center", margin: "".concat(theme.spacing("extraTight")), marginBottom: 0, padding: "".concat(theme.spacing()), width: "auto", "&:hover": {
                                        background: theme.getColor("primary"),
                                    } }) : __assign(__assign({}, base), { backgroundColor: getOptionBackgroundColor(state), color: state.isSelected
                                    ? theme.getColor("white")
                                    : theme.getColor("gray", 1000), opacity: state.isDisabled ? 0.5 : 1, pointerEvents: state.isDisabled ? "none" : "auto", "&:hover": {
                                    background: theme.getColor("primary", 0),
                                    color: theme.getColor("gray", 1000),
                                }, "&:focus": {
                                    background: theme.getColor("primary", 0),
                                    color: theme.getColor("gray", 1000),
                                } });
                            return optionStyles;
                        },
                        dropdownIndicator: function (base) { return (__assign(__assign({}, base), { color: theme.colors.neutral60, padding: "calc(".concat(theme.spacing("tight"), " - 1px)") })); },
                        singleValue: function (base) { return (__assign(__assign({}, base), { marginLeft: 0, marginRight: 0, maxWidth: "calc(100% - ".concat(theme.inputStyles.spacing.horizontal, ")") })); },
                        valueContainer: function (base) { return (__assign(__assign({}, base), { padding: "".concat(theme.inputStyles.spacing.vertical, " 0"), paddingLeft: theme.inputStyles.spacing.horizontal, lineHeight: rem(40 - 2 - Number(stripUnit(theme.spacing("tight"))) * 2, theme.baseFontSize) })); },
                        input: function (base) { return (__assign(__assign({}, base), { margin: 0, padding: 0, opacity: 1 })); },
                        menu: function (base) { return (__assign(__assign({}, base), { zIndex: 99999 })); },
                        placeholder: function (base) { return (__assign(__assign({}, base), { color: theme.getColor("gray", 200) })); },
                        control: function (base, state) { return (__assign(__assign(__assign({}, base), transitions("border-color 250ms ease-in", "box-shadow 150ms ease-in")), { borderRadius: theme.inputStyles.borderRadius, outline: state.isFocused && "none", border: "1px solid ".concat(getControlBorderColor(state)), boxShadow: "0 0 2px 1px ".concat(getControlBoxShadow(state)), backgroundColor: error
                                ? "".concat(theme.inputStyles.backgroundColor.error)
                                : "".concat(theme.inputStyles.backgroundColor.base), "&:hover": {
                                border: !error &&
                                    "1px solid ".concat(theme.inputStyles.borderColor.hover),
                            }, 'input[id^="react-select-"]': {
                                opacity: "1 !important",
                            } })); },
                        noOptionsMessage: function (base) { return (__assign(__assign({}, base), { textAlign: "left" })); },
                        menuPortal: function (base) { return (__assign(__assign({}, base), { top: "42px", left: 0, zIndex: 99999 })); },
                    } })));
            } }));
        return (_jsx(Labelled, { label: label, labelHidden: labelHidden, error: errorMessageHidden ? undefined : error, helpText: helpText, descriptionText: descriptionText, optional: optional, onPressExtraInfo: onPressExtraInfo, children: renderSelect }));
    };
    return React.memo(RentioSelect);
};
