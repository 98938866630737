var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Field as FormikField, getIn } from "formik";
import { Icon } from "../../../Icon";
import { StyledError, StyledHelpText } from "../../Form.styled";
import { Checkbox } from "./components/Checkbox";
import { Radio } from "./components/Radio";
import * as S from "./Option.styled";
export var Option = function (_a) {
    // const hasIcon = !!icon
    var label = _a.label, name = _a.name, helpText = _a.helpText, _b = _a.type, type = _b === void 0 ? "checkbox" : _b, icon = _a.icon, id = _a.id, _c = _a.disabled, disabled = _c === void 0 ? false : _c, _d = _a.hideError, hideError = _d === void 0 ? false : _d, _e = _a.labelHidden, labelHidden = _e === void 0 ? false : _e, _f = _a.onChange, onChange = _f === void 0 ? function () {
        return;
    } : _f, rest = __rest(_a, ["label", "name", "helpText", "type", "icon", "id", "disabled", "hideError", "labelHidden", "onChange"]);
    var render = function (_a) {
        var _b = _a.field, value = _b.value, onFormikChange = _b.onChange, field = __rest(_b, ["value", "onChange"]), _c = _a.form, touched = _c.touched, errors = _c.errors;
        var error = getIn(errors, name);
        var touch = getIn(touched, name);
        var errorMarkup = error && touch && (_jsx(StyledError, { id: "".concat(label, "Error"), children: error }));
        var iconMarkup = icon ? _jsx(Icon, { source: icon, size: "extraLarge" }) : null;
        var inputValue = type === "checkbox" ? value : id;
        var checked = type === "checkbox" ? value : String(id) === String(value);
        var hasError = error && touch;
        var handleChange = function (e) {
            onFormikChange(e);
            onChange(e);
        };
        return (_jsxs(S.Option, __assign({ isDisabled: disabled }, rest, { children: [type === "checkbox" ? (_jsx(Checkbox
                // @ts-ignore TODO: Typescript upgrade
                , __assign({ 
                    // @ts-ignore TODO: Typescript upgrade
                    name: name, id: id, type: type, onChange: handleChange, value: inputValue, error: hasError, disabled: disabled, checked: checked }, field))) : (_jsx(S.OptionInput, { checked: checked, children: _jsx(Radio
                    // @ts-ignore TODO: Typescript upgrade
                    , __assign({ 
                        // @ts-ignore TODO: Typescript upgrade
                        name: name, id: id, type: type, onChange: handleChange, value: inputValue, disabled: disabled, error: hasError, checked: checked }, field)) })), _jsxs(S.OptionContent, { children: [iconMarkup, !labelHidden && label, helpText || (!hideError && errorMarkup) ? (_jsxs("div", { children: [helpText && _jsx(StyledHelpText, { children: helpText }), !hideError && errorMarkup] })) : null] })] })));
    };
    return _jsx(FormikField, { name: name, render: render });
};
export { Checkbox };
