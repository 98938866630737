var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { REDUCER_KEY_FORM, } from "@rentiohq/shared-frontend/dist/redux/form/form.reducer";
import { getFieldInfo, getFieldTitle, } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { removeExtraSpaces } from "@rentiohq/shared-frontend/dist/utils/string.utils";
import { useSelector } from "react-redux";
import { StyledHelpText } from "../../../Form/Form.styled";
import { Labelled } from "../../../Labelled";
import { BaseInput } from "../BaseInput";
export var InputTextField = function (_a) {
    var name = _a.name, schema = _a.schema, uiSchema = _a.uiSchema, idSchema = _a.idSchema, formData = _a.formData, _b = _a.formContext, formContext = _b === void 0 ? {} : _b, _c = _a.rawErrors, rawErrors = _c === void 0 ? [] : _c, required = _a.required, onChange = _a.onChange, disabled = _a.disabled, readonly = _a.readonly, _d = _a.type, type = _d === void 0 ? "text" : _d, rest = __rest(_a, ["name", "schema", "uiSchema", "idSchema", "formData", "formContext", "rawErrors", "required", "onChange", "disabled", "readonly", "type"]);
    var $id = idSchema.$id;
    // @ts-ignore TODO: Typescript upgrade
    var title = getFieldTitle(uiSchema, formContext);
    // @ts-ignore TODO: Typescript upgrade
    var info = getFieldInfo(uiSchema, formContext);
    var error = rawErrors[0];
    var _e = uiSchema, placeholder = _e.placeholder, suffixText = _e.suffixText, multiline = _e.multiline, autoFocus = _e.autoFocus, isDisabled = _e.isDisabled, optional = _e.optional, iMaskProps = _e.iMaskProps, getIMaskProps = _e.getIMaskProps, _f = _e.secureTextEntry, secureTextEntry = _f === void 0 ? false : _f;
    var element = multiline ? "textarea" : "input";
    var id = "".concat($id, "-").concat(name);
    var formSchemaChangeCount = useSelector(function (state) { return state.form[formContext.formId].schemaChangeCount; });
    var uiDisabled = isDisabled
        ? isDisabled(formContext.getAllFormData())
        : undefined;
    var handleChange = function (input) {
        var formattedInput = input.length === 0 ? undefined : input;
        onChange(formattedInput);
        if (formContext.onFieldChange) {
            setTimeout(function () {
                formContext.onFieldChange(name, formattedInput);
            }, 0);
        }
    };
    var inputMarkup = (_jsxs(_Fragment, { children: [_jsx(BaseInput, __assign({}, rest, { minLength: schema.minLength, maxLength: schema.maxLength, id: id, value: formData, placeholder: placeholder, suffix: suffixText, onChange: handleChange, error: error, type: secureTextEntry ? "password" : type, elementType: element, onBlur: function () { return handleChange(removeExtraSpaces(formData)); }, 
                // @ts-ignore
                disabled: uiDisabled || disabled, readOnly: readonly, autoFocus: formSchemaChangeCount === 0 && autoFocus, iMaskProps: (getIMaskProps === null || getIMaskProps === void 0 ? void 0 : getIMaskProps(formContext.getAllFormData())) || iMaskProps })), info && _jsx(StyledHelpText, { children: info })] }));
    var handlePressExtraInfo = function () {
        if (formContext.onPressExtraInfo) {
            formContext.onPressExtraInfo(uiSchema.extraInfoUrl);
        }
    };
    return (_jsx(_Fragment, { children: title ? (_jsx(Labelled, { label: title, error: error, optional: optional, onPressExtraInfo: uiSchema.extraInfoUrl && handlePressExtraInfo, children: inputMarkup })) : (_jsx(_Fragment, { children: inputMarkup })) }));
};
