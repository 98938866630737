var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import addIbanSchemas from "@rentiohq/shared-frontend/dist/forms/addIban";
import { isPerformingSubmitActionSelector, performingSubmitActionErrorSelector, performingSubmitActionResultSelector, submitActionCreator, } from "@rentiohq/shared-frontend/dist/forms/addIban/schema.addIban.actions";
import { REDUCER_KEY_CONTACT, } from "@rentiohq/shared-frontend/dist/redux/contact/contact.reducer";
import { getContactNamesForAccountIds } from "@rentiohq/shared-frontend/dist/redux/contact/contact.selectors";
import { generateFormId } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { EExtraIbanSelectValues, } from "@rentiohq/shared-frontend/dist/types/contact.types";
import { memo, useEffect, useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import { StyledHelpText } from "../../../../Form/Form.styled";
import { MultiStepForm } from "../../../../SharedForm";
import { TextStyle } from "../../../../TextStyle";
import { OptionListShared } from "../.../../../../../OptionList";
import * as S from "./IbanSelect.styled";
var AddIbanForm = memo(function (props) {
    var handleSuccess = props.handleSuccess, ownerNames = props.ownerNames, accountId = props.accountId;
    var formId = useState(generateFormId())[0];
    return (_jsx(S.Form, { children: _jsx(MultiStepForm, { formId: "add-iban-".concat(formId), schemas: addIbanSchemas({ ownerName: ownerNames[accountId] }), withSummary: false, withAside: false, showErrorList: false, isPerformingSubmitActionSelector: isPerformingSubmitActionSelector(), performingSubmitActionResultSelector: performingSubmitActionResultSelector(accountId), performingSubmitActionErrorSelector: performingSubmitActionErrorSelector(), submitActionCreator: submitActionCreator(accountId, undefined, handleSuccess), onSuccess: handleSuccess }) }));
});
export var IbanSelect = function (props) {
    var id = props.id, selectedIbanId = props.selectedIbanId, title = props.title, info = props.info, accountId = props.accountId, addIbanCtaTitle = props.addIbanCtaTitle, ibans = props.ibans, getIbans = props.getIbans, error = props.error, onPressAddIban = props.onPressAddIban, onSelectIban = props.onSelectIban, onPressExtraInfo = props.onPressExtraInfo, optional = props.optional, showUnknownIbanOption = props.showUnknownIbanOption, unknownIbanLabel = props.unknownIbanLabel;
    // State
    var _a = useState(false), addIbanFormVisible = _a[0], setAddIbanFormVisible = _a[1];
    var _b = useState([]), options = _b[0], setOptions = _b[1];
    // Redux
    var ownerNames = useSelector(function (state) {
        return getContactNamesForAccountIds(state, [accountId]);
    });
    // Lifecycle methods
    useEffect(getIbans, []);
    var handleSuccess = function (id) {
        onPressAddIban(id);
    };
    useLayoutEffect(function () {
        if (!ibans)
            return;
        var mappedOptions = ibans.map(function (option) {
            var label = (_jsxs(_Fragment, { children: [_jsx(TextStyle, { variation: "strong", children: option.iban }), _jsx(TextStyle, { element: "p", size: "small", variation: "subdued", children: option.ownerName })] }));
            return __assign(__assign({}, option), { value: option.id, label: label });
        });
        if (addIbanCtaTitle) {
            mappedOptions.push({
                id: EExtraIbanSelectValues.AddIban,
                value: EExtraIbanSelectValues.AddIban,
                label: addIbanCtaTitle,
            });
        }
        if (showUnknownIbanOption) {
            mappedOptions.push({
                id: EExtraIbanSelectValues.Unknown,
                value: EExtraIbanSelectValues.Unknown,
                label: unknownIbanLabel,
            });
        }
        setOptions(mappedOptions);
    }, [ibans]);
    // Event handlers
    var handleChange = function (values) {
        var value = values[0];
        var ibanId = Number(value);
        if (!value) {
            onSelectIban(0);
            return;
        }
        // Splice adds and removes the form to add an Iban in the right place in the option list
        // We used to just show / hide it based on the 'addIbanFormVisible' state, but that would
        // always show the form at the end, instead of at the right place in the option list
        if (ibanId === -1) {
            onSelectIban([]);
            setAddIbanFormVisible(true);
            var duplicate = __spreadArray([], options, true);
            duplicate.splice(ibans.length, 1, {
                id: EExtraIbanSelectValues.AddIban,
                value: EExtraIbanSelectValues.AddIban,
                label: (_jsx(AddIbanForm, { handleSuccess: handleSuccess, ownerNames: ownerNames, accountId: accountId })),
            });
            setOptions(duplicate);
            return;
        }
        onSelectIban(ibanId);
        if (addIbanFormVisible) {
            setAddIbanFormVisible(false);
            var duplicate = __spreadArray([], options, true);
            duplicate.splice(ibans.length, 1, {
                id: EExtraIbanSelectValues.AddIban,
                value: EExtraIbanSelectValues.AddIban,
                label: addIbanCtaTitle,
            });
            setOptions(duplicate);
        }
    };
    // Render
    return (_jsxs(_Fragment, { children: [_jsx(OptionListShared, { id: id, value: addIbanFormVisible ? [-1] : [selectedIbanId], options: options, title: title, error: error, onChange: handleChange, onPressExtraInfo: onPressExtraInfo, optional: optional }), info && _jsx(StyledHelpText, { children: info })] }));
};
