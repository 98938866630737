var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import Fuse from "fuse.js";
import { groupBy, orderBy, sortBy } from "lodash";
import { CONFIG } from "../../config/app.config";
import { startOfDay } from "../../utils/date-fns.utils";
import { formatDateWithCustomClosebyFormat } from "../../utils/date.utils";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { ALL_DOCUMENT_CATEGORIES, DOCUMENT_CATEGORY_LABELS, } from "./document.constants";
export var getCategoriesSortedByTranslatedLabel = function () {
    return sortBy(ALL_DOCUMENT_CATEGORIES.map(function (id) { return ({
        id: id,
        key: getDocumentCategoryName(id),
    }); }), "key").map(function (_a) {
        var id = _a.id;
        return id;
    });
};
// https://stackoverflow.com/questions/8801377/getting-the-default-file-extension-for-a-content-type-in-javascript
var MIME_TYPES_EXTENSIONS = {
    // cspell:disable
    "text/html": ["html", "htm", "shtml"],
    "text/css": ["css"],
    "text/xml": ["xml"],
    "image/gif": ["gif"],
    "image/jpeg": ["jpeg", "jpg"],
    "application/x-javascript": ["js"],
    "application/atom+xml": ["atom"],
    "application/rss+xml": ["rss"],
    "text/mathml": ["mml"],
    "text/plain": ["txt"],
    "text/vnd.sun.j2me.app-descriptor": ["jad"],
    "text/vnd.wap.wml": ["wml"],
    "text/x-component": ["htc"],
    "image/png": ["png"],
    "image/tiff": ["tif", "tiff"],
    "image/vnd.wap.wbmp": ["wbmp"],
    "image/x-icon": ["ico"],
    "image/x-jng": ["jng"],
    "image/x-ms-bmp": ["bmp"],
    "image/svg+xml": ["svg"],
    "image/webp": ["webp"],
    "application/java-archive": ["jar", "war", "ear"],
    "application/mac-binhex40": ["hqx"],
    "application/msword": ["doc"],
    "application/pdf": ["pdf"],
    "application/postscript": ["ps", "eps", "ai"],
    "application/rtf": ["rtf"],
    "application/vnd.ms-excel": ["xls"],
    "application/vnd.ms-powerpoint": ["ppt"],
    "application/vnd.wap.wmlc": ["wmlc"],
    "application/vnd.google-earth.kml+xml": ["kml"],
    "application/vnd.google-earth.kmz": ["kmz"],
    "application/x-7z-compressed": ["7z"],
    "application/x-cocoa": ["cco"],
    "application/x-java-archive-diff": ["jardiff"],
    "application/x-java-jnlp-file": ["jnlp"],
    "application/x-makeself": ["run"],
    "application/x-perl": ["pl", "pm"],
    "application/x-pilot": ["prc", "pdb"],
    "application/x-rar-compressed": ["rar"],
    "application/x-redhat-package-manager": ["rpm"],
    "application/x-sea": ["sea"],
    "application/x-shockwave-flash": ["swf"],
    "application/x-stuffit": ["sit"],
    "application/x-tcl": ["tcl", "tk"],
    "application/x-x509-ca-cert": ["der", "pem", "crt"],
    "application/x-xpinstall": ["xpi"],
    "application/xhtml+xml": ["xhtml"],
    "application/zip": ["zip"],
    "application/octet-stream": [
        "bin",
        "exe",
        "dll",
        "iso",
        "img",
        "msi",
        "msp",
        "msm",
        "deb",
        "dmg",
        "eot",
    ],
    "audio/midi": ["mid", "midi", "kar"],
    "audio/mpeg": ["mp3"],
    "audio/ogg": ["ogg"],
    "audio/x-realaudio": ["ra"],
    "video/3gpp": ["3gpp", "3gp"],
    "video/mpeg": ["mpeg", "mpg"],
    "video/quicktime": ["mov"],
    "video/x-flv": ["flv"],
    "video/x-mng": ["mng"],
    "video/x-ms-asf": ["asx", "asf"],
    "video/x-ms-wmv": ["wmv"],
    "video/x-msvideo": ["avi"],
    "video/mp4": ["m4v", "mp4"],
    // cspell:enable
};
var CONTACT_FUZZY_SEARCH_POSTFIX = ["firstname", "lastname", "company"];
var DOCUMENT_FUZZY_SEARCH_PROPERTIES = __spreadArray([
    "filename"
], CONTACT_FUZZY_SEARCH_POSTFIX.map(function (postfix) { return "members.".concat(postfix); }), true);
export var guessExtensionFromPathAndMime = function (path, mime) {
    // Get extension from path
    // https://stackoverflow.com/questions/680929/how-to-extract-extension-from-filename-string-in-javascript/680982
    var possibleExtension = /(?:\.([^.]+))?$/.exec(path);
    if (possibleExtension === null || possibleExtension === void 0 ? void 0 : possibleExtension[0]) {
        return possibleExtension[0].replace(".", "");
    }
    // Get extension from MIME type
    if (mime) {
        var extensionsForMime = MIME_TYPES_EXTENSIONS[mime];
        if (extensionsForMime === null || extensionsForMime === void 0 ? void 0 : extensionsForMime[0]) {
            return extensionsForMime[0];
        }
    }
    return "";
};
export var getDocumentCategoryName = function (category) {
    return getLocalizedText("document.category.".concat(DOCUMENT_CATEGORY_LABELS[category]).toLowerCase());
};
export var getDocumentsSectionListDataSource = function (documents, searchQuery) {
    if (!documents) {
        return;
    }
    var filteredDocuments = documents;
    if (searchQuery) {
        var options = {
            threshold: 0.2,
            minMatchCharLength: 2,
            keys: DOCUMENT_FUZZY_SEARCH_PROPERTIES,
        };
        var fuse = new Fuse(documents, options);
        filteredDocuments = fuse.search(searchQuery);
    }
    var groupedDocuments = groupBy(filteredDocuments, function (document) {
        return startOfDay(document.createdAt || new Date());
    });
    var sortedActivitiesKeys = Object.keys(groupedDocuments).sort(function (a, b) { return new Date(b).getTime() - new Date(a).getTime(); });
    var dataSource = sortedActivitiesKeys.map(function (date) { return ({
        title: formatDateWithCustomClosebyFormat(new Date(date)),
        data: orderBy(groupedDocuments[date], ["createdAt", "id"], ["desc"]),
    }); });
    return dataSource;
};
export var getDocumentPagedQuery = function (params) {
    var _a = params.page, page = _a === void 0 ? 1 : _a, extraFilterData = params.extraFilterData, search = params.search, customFilters = params.customFilters;
    var documentPagedQuery = {
        page: page,
        limit: CONFIG.DEFAULT_FETCH_LIMIT,
        sort: [{ field: "createdAt", method: "DESC" }],
        filter: __assign({}, extraFilterData),
        search: getSearchValue(search),
        customFilters: customFilters,
    };
    return { query: documentPagedQuery, shouldRefetch: true };
};
export var getDocumentCountQuery = function (params) {
    var extraFilterData = params.extraFilterData, search = params.search, customFilters = params.customFilters;
    var documentCountQuery = {
        filter: __assign({}, extraFilterData),
        search: getSearchValue(search),
        customFilters: customFilters,
    };
    return documentCountQuery;
};
function getSearchValue(search) {
    if (search === "") {
        return;
    }
    return search;
}
