var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from "@rebass/grid";
import { toast as toastify } from "react-toastify";
import Transition from "react-transition-group/Transition";
import { ESpacings } from "../../components";
import { Button, ButtonActions } from "../../components/Button";
import { Icon } from "../../components/Icon";
import * as S from "./Toast.styled";
export var VariationToIcon = {
    default: "alertDiamond",
    info: "alertDiamond",
    warning: "lightBulb",
    error: "cloudWarning",
    success: "party",
};
var SlideIn = function (_a) {
    var children = _a.children, position = _a.position, _ = _a.preventExitTransition, props = __rest(_a, ["children", "position", "preventExitTransition"]);
    return (_jsx(Transition, __assign({}, props, { timeout: 300, 
        // @ts-ignore TODO: Typescript upgrade
        onEnter: function (node) { return node.classList.add("slideIn"); }, onExit: function (node) {
            node.classList.remove("slideIn");
            node.classList.add("slideOut");
        }, children: children })));
};
// @deprecated Use `showAlert` instead
export var toast = function (props) {
    var heading = props.heading, content = props.content, action = props.action, customIcon = props.icon, _a = props.autoDismiss, autoDismiss = _a === void 0 ? true : _a, _b = props.variation, variation = _b === void 0 ? "info" : _b, onDismiss = props.onDismiss, navigate = props.navigate;
    var toastId = null;
    var icon = customIcon ? customIcon : VariationToIcon[variation];
    var autoClose = autoDismiss ? 5000 : false;
    var close = function () {
        toastify.dismiss(toastId);
        if (onDismiss) {
            onDismiss();
        }
    };
    var handleActionClick = function () {
        if (action === null || action === void 0 ? void 0 : action.onClick) {
            close();
            action.onClick();
            return;
        }
        if ((action === null || action === void 0 ? void 0 : action.link) && navigate) {
            close();
            // window.open(action.link, "_self");
            navigate(action.link);
        }
    };
    var renderContent = function () {
        return (_jsxs(S.Toast, { variation: variation, children: [_jsxs(S.ToastHeading, { children: [_jsx(S.Icon, { children: icon && _jsx(Icon, { source: icon }) }), _jsx(S.Heading, { children: heading }), _jsx(S.Close, { children: _jsx(Button, { appearance: "plain", onClick: close, children: _jsx(Icon, { size: "small", source: "close" }) }) })] }), _jsxs(S.ToastContent, { children: [content && _jsx("div", { children: content }), action && (_jsx(Box, { mt: content ? ESpacings.base : ESpacings.none, children: _jsx(ButtonActions, { actions: [action], options: {
                                    overrides: { appearance: "link", onClick: handleActionClick },
                                } }) }))] })] }));
    };
    toastId = toastify(renderContent(), {
        type: variation,
        closeButton: false,
        closeOnClick: false,
        autoClose: autoClose,
        transition: SlideIn,
    });
};
