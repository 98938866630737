var _a, _b;
import { generateActions } from "../generators/generator.actions";
import { EActionState, createCustomAction } from "../utils/action.utils";
import { GENERATOR_PARAMS } from "./property.constants";
var EActionCustom;
(function (EActionCustom) {
    EActionCustom["PropertyGroupActions"] = "PROPERTY_GROUP_ACTIONS";
})(EActionCustom || (EActionCustom = {}));
var generatePropertyGroupActionsActions = function () { return ({
    propertyGroupActionsStart: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.PropertyGroupActions,
        state: EActionState.Start,
    }),
    propertyGroupActionsSucceeded: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.PropertyGroupActions,
        state: EActionState.Success,
    }),
    propertyGroupActionsFailed: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.PropertyGroupActions,
        state: EActionState.Fail,
    }),
}); };
export var getDetailStart = (_a = generateActions(GENERATOR_PARAMS), _a.getDetailStart), getDetailSucceeded = _a.getDetailSucceeded, getDetailFailed = _a.getDetailFailed, getPagedStart = _a.getPagedStart, getPagedFailed = _a.getPagedFailed, getPagedSucceeded = _a.getPagedSucceeded, getAllWhereStart = _a.getAllWhereStart, getAllWhereFailed = _a.getAllWhereFailed, getAllWhereSucceeded = _a.getAllWhereSucceeded, getCountStart = _a.getCountStart, getCountFailed = _a.getCountFailed, getCountSucceeded = _a.getCountSucceeded, 
// getInfiniteLoadStart,
// getInfiniteLoadFailed,
// getInfiniteLoadSucceeded,
updateMembersFailed = _a.updateMembersFailed, updateMembersStart = _a.updateMembersStart, updateMembersSucceeded = _a.updateMembersSucceeded, createStart = _a.createStart, createSucceeded = _a.createSucceeded, createFailed = _a.createFailed, updateStart = _a.updateStart, updateSucceeded = _a.updateSucceeded, updateFailed = _a.updateFailed, removeStart = _a.removeStart, removeSucceeded = _a.removeSucceeded, removeFailed = _a.removeFailed;
export var propertyGroupActionsStart = (_b = generatePropertyGroupActionsActions(), _b.propertyGroupActionsStart), propertyGroupActionsSucceeded = _b.propertyGroupActionsSucceeded, propertyGroupActionsFailed = _b.propertyGroupActionsFailed;
