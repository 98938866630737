var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { ELocale } from "@rentiohq/shared-frontend/dist/types/i18n.types";
import { EIdCardOptions } from "./schema.editContact.3";
import { EField } from "./schema.editContact.types";
export var getInitialValues = function (_a) {
    var _b, _c;
    var contact = _a.contact;
    var street = contact.street, state = contact.state, number = contact.number, box = contact.box, zip = contact.zip, city = contact.city, countryId = contact.countryId, placeOfBirth = contact.placeOfBirth, countryOfBirth = contact.countryOfBirth, dateOfBirth = contact.dateOfBirth, nationality = contact.nationality, nationalRegisterNumber = contact.nationalRegisterNumber, foreignIdentityNumber = contact.foreignIdentityNumber, enableReporting = contact.enableReporting, automaticReporting = contact.automaticReporting, reportingStartedAt = contact.reportingStartedAt, reportingFrequency = contact.reportingFrequency, includeOpenPayments = contact.includeOpenPayments, contactRest = __rest(contact, ["street", "state", "number", "box", "zip", "city", "countryId", "placeOfBirth", "countryOfBirth", "dateOfBirth", "nationality", "nationalRegisterNumber", "foreignIdentityNumber", "enableReporting", "automaticReporting", "reportingStartedAt", "reportingFrequency", "includeOpenPayments"]);
    var addressMapped = undefined;
    if (street) {
        addressMapped = {
            street: street,
            state: state,
            number: number,
            box: box,
            zip: zip,
            city: city,
            country: countryId,
        };
    }
    var placeOfBirthMapped = undefined;
    if (placeOfBirth) {
        placeOfBirthMapped = {
            city: placeOfBirth,
            country: countryOfBirth,
        };
    }
    return __assign(__assign((_b = {}, _b[EField.Language] = ELocale.NL, _b), contactRest), (_c = {}, _c[EField.Address] = addressMapped, _c[EField.DateOfBirth] = dateOfBirth ? dateOfBirth : undefined, _c[EField.PlaceOfBirth] = placeOfBirthMapped, _c[EField.Nationality] = nationality ? nationality : undefined, _c[EField.IdType] = nationality === "BE" || nationalRegisterNumber
        ? EIdCardOptions.BelgianIdCardNumber
        : EIdCardOptions.PassportNumber, _c[EField.NationalRegisterNumber] = nationalRegisterNumber
        ? nationalRegisterNumber
        : undefined, _c[EField.ForeignIdentityNumber] = foreignIdentityNumber
        ? foreignIdentityNumber
        : undefined, _c[EField.EnableReporting] = enableReporting, _c[EField.AutomaticReporting] = automaticReporting, _c[EField.ReportingStart] = reportingStartedAt, _c[EField.ReportingFrequency] = reportingFrequency, _c[EField.IncludeOpenPayments] = includeOpenPayments, _c));
};
