import {
  ELeadStatus,
  ILead,
} from "@rentiohq/shared-frontend/dist/types/insurance.lead.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { stringToSnakeCase } from "@rentiohq/shared-frontend/dist/utils/string.utils";
import { Lozenge } from "@rentiohq/web-shared/dist/components";
import { TAppearance } from "@rentiohq/web-shared/dist/types";
import React from "react";

interface IProps {
  lead: ILead;
}

const LeadStatusLozenge: React.FC<IProps> = props => {
  const { lead } = props;

  let appearanceForLeadStatus: TAppearance = "error";

  switch (lead.status) {
    case ELeadStatus.ProcessedByBaloise:
      appearanceForLeadStatus = "success";
      break;

    case ELeadStatus.Pending:
    case ELeadStatus.Signed:
    case ELeadStatus.Paid:
    case ELeadStatus.SentToBaloise:
      appearanceForLeadStatus = "info";
      break;

    case ELeadStatus.New:
      appearanceForLeadStatus = "default";
      break;

    case ELeadStatus.Expired:
    case ELeadStatus.TechnicalError:
    default:
      break;
  }

  return (
    <Lozenge isBold={true} appearance={appearanceForLeadStatus}>
      {getLocalizedText(
        `insurance.lead.status.${stringToSnakeCase(lead.status)}`.toLowerCase(),
      )}
    </Lozenge>
  );
};

// eslint-disable-next-line import/no-default-export
export default LeadStatusLozenge;
