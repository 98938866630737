var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import _ from "lodash";
import keyBy from "lodash/keyBy";
import merge from "lodash/merge";
import { createReducer } from "redux-act";
import { EPaymentRequestFetchType, } from "../../types/paymentRequest.types";
import * as paymentActions from "./payment.actions";
import * as paymentUtils from "./payment.utils";
export var REDUCER_KEY_PAYMENT = "payment";
var DEFAULT_PAYMENT_REQUEST_STATE = {
    isFetching: false,
    fetchError: undefined,
    paymentRequests: undefined,
    isExhausted: false,
};
var getDefaultState = function () { return ({
    isFetchingPaymentOrders: false,
    fetchPaymentOrdersError: undefined,
    paymentOrders: {},
    pagedPaymentOrders: {},
    ibans: {},
    ibansById: {},
    paymentOrdersByIdentifier: {},
    isExhaustedFetchingPaymentOrders: false,
    isFetchingPaymentOrdersCount: false,
    fetchPaymentOrdersErrorCount: undefined,
    paymentOrdersCount: undefined,
    isCreatingPaymentOrder: false,
    createPaymentOrderError: undefined,
    getPaymentOrdersForProperty: {},
    getPaymentOrderById: {},
    updatePaymentOrders: {},
    deletePaymentOrders: {},
    paymentRequestsByIdentifier: {},
    paymentRequests: {},
    paymentRequestsById: {},
    pagedPaymentRequests: {},
    openPaymentRequests: DEFAULT_PAYMENT_REQUEST_STATE,
    pendingPaymentRequests: DEFAULT_PAYMENT_REQUEST_STATE,
    donePaymentRequests: DEFAULT_PAYMENT_REQUEST_STATE,
    updatePaymentRequests: {},
    deletePaymentRequests: {},
    deleteMandate: {},
    markThirdPartyReceived: {},
    markPaid: {},
    followUp: {},
    missingKYCs: {},
    sendInvitation: {},
}); };
var reducer = createReducer({}, getDefaultState());
reducer.on("LOG_OUT", function () { return getDefaultState(); });
reducer.on(paymentActions.getPaymentOrders.types.START, function (state) { return (__assign(__assign({}, state), { isFetchingPaymentOrders: true, fetchPaymentOrdersError: undefined })); });
reducer.on(paymentActions.getPaymentOrders.types.SUCCESS, function (state, _a) {
    var paymentOrders = _a.paymentOrders, refetch = _a.refetch, isExhausted = _a.isExhausted;
    return __assign(__assign({}, state), { isFetchingPaymentOrders: false, fetchPaymentOrdersError: undefined, isExhaustedFetchingPaymentOrders: isExhausted, paymentOrders: refetch
            ? paymentOrders
            : __assign(__assign({}, state.paymentOrders), keyBy(paymentOrders, "id")) });
});
reducer.on(paymentActions.getPaymentOrders.types.FAILURE, function (state, _a) {
    var error = _a.error;
    return (__assign(__assign({}, state), { isFetchingPaymentOrders: false, fetchPaymentOrdersError: error }));
});
reducer.on(paymentActions.getPaymentOrdersPaged.types.START, function (state, _a) {
    var _b, _c;
    var _d, _e, _f;
    var identifier = _a.identifier, _g = _a.page, page = _g === void 0 ? 0 : _g, filterData = _a.filterData;
    return __assign(__assign({}, state), { pagedPaymentOrders: __assign(__assign({}, state.pagedPaymentOrders), (_b = {}, _b[identifier] = __assign(__assign({}, state.pagedPaymentOrders[identifier]), { filterData: filterData, pages: __assign(__assign({}, (_d = state.pagedPaymentOrders[identifier]) === null || _d === void 0 ? void 0 : _d.pages), (_c = {}, _c[page] = __assign(__assign({}, (_f = (_e = state.pagedPaymentOrders[identifier]) === null || _e === void 0 ? void 0 : _e.pages) === null || _f === void 0 ? void 0 : _f[page]), { isFetching: true, fetchError: undefined }), _c)) }), _b)) });
});
reducer.on(paymentActions.getPaymentOrdersPaged.types.SUCCESS, function (state, _a) {
    var _b, _c;
    var _d;
    var identifier = _a.identifier, _e = _a.page, page = _e === void 0 ? 0 : _e, paymentOrders = _a.paymentOrders;
    return __assign(__assign({}, state), { paymentOrders: __assign(__assign({}, state.paymentOrders), keyBy(paymentOrders, "id")), pagedPaymentOrders: __assign(__assign({}, state.pagedPaymentOrders), (_b = {}, _b[identifier] = __assign(__assign({}, state.pagedPaymentOrders[identifier]), { pages: __assign(__assign({}, (_d = state.pagedPaymentOrders[identifier]) === null || _d === void 0 ? void 0 : _d.pages), (_c = {}, _c[page] = {
                paymentOrderIds: paymentOrders.map(function (paymentOrder) { return paymentOrder.id; }),
                isFetching: false,
                fetchError: undefined,
            }, _c)) }), _b)) });
});
reducer.on(paymentActions.getPaymentOrdersPaged.types.FAILURE, function (state, _a) {
    var _b, _c;
    var _d, _e, _f;
    var identifier = _a.identifier, _g = _a.page, page = _g === void 0 ? 0 : _g, error = _a.error;
    return __assign(__assign({}, state), { pagedPaymentOrders: __assign(__assign({}, state.pagedPaymentOrders), (_b = {}, _b[identifier] = __assign(__assign({}, state.pagedPaymentOrders[identifier]), { pages: __assign(__assign({}, (_d = state.pagedPaymentOrders[identifier]) === null || _d === void 0 ? void 0 : _d.pages), (_c = {}, _c[page] = __assign(__assign({}, (_f = (_e = state.pagedPaymentOrders[identifier]) === null || _e === void 0 ? void 0 : _e.pages) === null || _f === void 0 ? void 0 : _f[page]), { isFetching: false, fetchError: error }), _c)) }), _b)) });
});
reducer.on(paymentActions.getIban.types.START, function (state, _a) {
    var _b;
    var paymentOrderId = _a.paymentOrderId, payeeId = _a.payeeId, payerId = _a.payerId;
    var ibanId = paymentUtils.getIbanId(paymentOrderId, payeeId, payerId);
    if (!ibanId) {
        return state;
    }
    return __assign(__assign({}, state), { ibansById: __assign(__assign({}, state.ibansById), (_b = {}, _b[ibanId] = {
            isFetching: true,
            fetchError: undefined,
        }, _b)) });
});
reducer.on(paymentActions.getIban.types.SUCCESS, function (state, _a) {
    var _b, _c;
    var paymentOrderId = _a.paymentOrderId, payeeId = _a.payeeId, payerId = _a.payerId, iban = _a.iban;
    var ibanId = paymentUtils.getIbanId(paymentOrderId, payeeId, payerId);
    if (!ibanId) {
        return state;
    }
    return __assign(__assign({}, state), { ibans: __assign(__assign({}, state.ibans), (_b = {}, _b[ibanId] = iban, _b)), ibansById: __assign(__assign({}, state.ibansById), (_c = {}, _c[ibanId] = {
            isFetching: false,
            fetchError: undefined,
        }, _c)) });
});
reducer.on(paymentActions.getIban.types.FAILURE, function (state, _a) {
    var _b;
    var paymentOrderId = _a.paymentOrderId, payeeId = _a.payeeId, payerId = _a.payerId, error = _a.error;
    var ibanId = paymentUtils.getIbanId(paymentOrderId, payeeId, payerId);
    if (!ibanId) {
        return state;
    }
    return __assign(__assign({}, state), { ibansById: __assign(__assign({}, state.ibansById), (_b = {}, _b[ibanId] = {
            isFetching: false,
            fetchError: error,
        }, _b)) });
});
reducer.on(paymentActions.getPaymentOrdersByIdentifier.types.START, function (state, _a) {
    var _b;
    var paymentOrdersIdentifier = _a.paymentOrdersIdentifier, filterData = _a.filterData;
    var prevStateForIdentifier = state.paymentOrdersByIdentifier[paymentOrdersIdentifier] || {};
    return __assign(__assign({}, state), { paymentOrdersByIdentifier: __assign(__assign({}, state.paymentOrdersByIdentifier), (_b = {}, _b[paymentOrdersIdentifier] = __assign(__assign({ paymentOrderIds: undefined, isExhausted: false }, prevStateForIdentifier), { filterData: filterData, isFetching: true, fetchError: undefined }), _b)) });
});
reducer.on(paymentActions.getPaymentOrdersByIdentifier.types.SUCCESS, function (state, _a) {
    var _b;
    var paymentOrdersIdentifier = _a.paymentOrdersIdentifier, refetch = _a.refetch, paymentOrders = _a.paymentOrders, isExhausted = _a.isExhausted;
    var prevStateForIdentifier = state.paymentOrdersByIdentifier[paymentOrdersIdentifier] || {};
    var paymentOrderIds = paymentOrders.map(function (_a) {
        var id = _a.id;
        return id;
    });
    return __assign(__assign({}, state), { paymentOrders: __assign(__assign({}, state.paymentOrders), keyBy(paymentOrders, "id")), paymentOrdersByIdentifier: __assign(__assign({}, state.paymentOrdersByIdentifier), (_b = {}, _b[paymentOrdersIdentifier] = __assign(__assign({ filterData: undefined }, prevStateForIdentifier), { isFetching: false, fetchError: undefined, isExhausted: isExhausted, paymentOrderIds: refetch
                ? paymentOrderIds
                : __spreadArray(__spreadArray([], (prevStateForIdentifier.paymentOrderIds || []), true), paymentOrderIds, true) }), _b)) });
});
reducer.on(paymentActions.getPaymentOrdersByIdentifier.types.FAILURE, function (state, _a) {
    var _b;
    var paymentOrdersIdentifier = _a.paymentOrdersIdentifier, error = _a.error;
    var prevStateForIdentifier = state.paymentOrdersByIdentifier[paymentOrdersIdentifier] || {};
    return __assign(__assign({}, state), { paymentOrdersByIdentifier: __assign(__assign({}, state.paymentOrdersByIdentifier), (_b = {}, _b[paymentOrdersIdentifier] = __assign(__assign({ filterData: undefined, paymentOrderIds: undefined, isExhausted: false }, prevStateForIdentifier), { isFetching: false, fetchError: error }), _b)) });
});
reducer.on(paymentActions.getPaymentOrdersForProperty.types.START, function (state, _a) {
    var _b;
    var _c;
    var propertyId = _a.propertyId;
    return __assign(__assign({}, state), { getPaymentOrdersForProperty: __assign(__assign({}, state.getPaymentOrdersForProperty), (_b = {}, _b[propertyId] = {
            isFetchingPaymentOrders: true,
            fetchPaymentOrdersError: undefined,
            paymentOrders: (_c = state.getPaymentOrdersForProperty[propertyId]) === null || _c === void 0 ? void 0 : _c.paymentOrders,
        }, _b)) });
});
reducer.on(paymentActions.getPaymentOrdersForProperty.types.SUCCESS, function (state, _a) {
    var _b;
    var propertyId = _a.propertyId, paymentOrders = _a.paymentOrders;
    return __assign(__assign({}, state), { getPaymentOrdersForProperty: __assign(__assign({}, state.getPaymentOrdersForProperty), (_b = {}, _b[propertyId] = {
            isFetchingPaymentOrders: false,
            fetchPaymentOrdersError: undefined,
            paymentOrders: paymentOrders.reverse(),
        }, _b)) });
});
reducer.on(paymentActions.getPaymentOrdersForProperty.types.FAILURE, function (state, _a) {
    var _b;
    var _c;
    var propertyId = _a.propertyId, error = _a.error;
    return (__assign(__assign({}, state), { getPaymentOrdersForProperty: __assign(__assign({}, state.getPaymentOrdersForProperty), (_b = {}, _b[propertyId] = {
            isFetchingPaymentOrders: false,
            fetchPaymentOrdersError: error,
            paymentOrders: (_c = state.getPaymentOrdersForProperty[propertyId]) === null || _c === void 0 ? void 0 : _c.paymentOrders,
        }, _b)) }));
});
reducer.on(paymentActions.createPaymentOrder.types.START, function (state) { return (__assign(__assign({}, state), { isCreatingPaymentOrder: true, createPaymentOrderError: undefined })); });
reducer.on(paymentActions.createPaymentOrder.types.SUCCESS, function (state, paymentOrders) {
    var _a;
    var _b;
    var propertyId = paymentOrders[0].propertyId;
    if (!propertyId) {
        return state;
    }
    return __assign(__assign({}, state), { isCreatingPaymentOrder: false, createPaymentOrderError: undefined, getPaymentOrdersForProperty: __assign(__assign({}, state.getPaymentOrdersForProperty), (_a = {}, _a[propertyId] = __assign(__assign({}, state.getPaymentOrdersForProperty[propertyId]), { paymentOrders: __spreadArray(__spreadArray([], paymentOrders, true), (((_b = state.getPaymentOrdersForProperty[propertyId]) === null || _b === void 0 ? void 0 : _b.paymentOrders) ||
                []), true) }), _a)) });
});
reducer.on(paymentActions.createPaymentOrder.types.FAILURE, function (state, _a) {
    var error = _a.error;
    return (__assign(__assign({}, state), { isCreatingPaymentOrder: false, createPaymentOrderError: error }));
});
reducer.on(paymentActions.getPaymentOrder.types.START, function (state, _a) {
    var _b;
    var paymentOrderId = _a.paymentOrderId;
    return (__assign(__assign({}, state), { getPaymentOrderById: (_b = {},
            _b[paymentOrderId] = {
                isFetching: true,
                fetchError: undefined,
            },
            _b) }));
});
reducer.on(paymentActions.getPaymentOrder.types.SUCCESS, function (state, _a) {
    var _b, _c;
    var paymentOrderId = _a.paymentOrderId, paymentOrder = _a.paymentOrder;
    return (__assign(__assign({}, state), { paymentOrders: __assign(__assign({}, state.paymentOrders), (_b = {}, _b[paymentOrderId] = paymentOrder, _b)), getPaymentOrderById: (_c = {},
            _c[paymentOrderId] = {
                isFetching: false,
                fetchError: undefined,
            },
            _c) }));
});
reducer.on(paymentActions.getPaymentOrder.types.FAILURE, function (state, _a) {
    var _b;
    var paymentOrderId = _a.paymentOrderId, error = _a.error;
    return (__assign(__assign({}, state), { getPaymentOrderById: (_b = {},
            _b[paymentOrderId] = {
                isFetching: false,
                fetchError: error,
            },
            _b) }));
});
reducer.on(paymentActions.updatePaymentOrder.types.START, function (state, _a) {
    var _b;
    var paymentOrderId = _a.paymentOrderId;
    return (__assign(__assign({}, state), { updatePaymentOrders: (_b = {},
            _b[paymentOrderId] = {
                isUpdatingPaymentOrder: true,
                fetchPaymentOrdersError: undefined,
            },
            _b) }));
});
reducer.on(paymentActions.updatePaymentOrder.types.SUCCESS, function (state, _a) {
    var _b, _c, _d;
    var _e;
    var paymentOrderId = _a.paymentOrderId, paymentOrder = _a.paymentOrder;
    var propertyId = paymentOrder.propertyId;
    return __assign(__assign({}, state), { paymentOrders: __assign(__assign({}, state.paymentOrders), (_b = {}, _b[paymentOrderId] = paymentOrder, _b)), updatePaymentOrders: (_c = {},
            _c[paymentOrderId] = {
                isUpdatingPaymentOrder: false,
                fetchPaymentOrdersError: undefined,
            },
            _c), getPaymentOrdersForProperty: __assign(__assign({}, state.getPaymentOrdersForProperty), (_d = {}, _d[propertyId] = __assign(__assign({}, state.getPaymentOrdersForProperty[propertyId]), { paymentOrders: __spreadArray([
                paymentOrder
            ], (((_e = state.getPaymentOrdersForProperty[propertyId]) === null || _e === void 0 ? void 0 : _e.paymentOrders) ||
                []), true) }), _d)) });
});
reducer.on(paymentActions.updatePaymentOrder.types.FAILURE, function (state, _a) {
    var _b;
    var paymentOrderId = _a.paymentOrderId, error = _a.error;
    return (__assign(__assign({}, state), { updatePaymentOrders: (_b = {},
            _b[paymentOrderId] = {
                isUpdatingPaymentOrder: false,
                fetchPaymentOrdersError: error,
            },
            _b) }));
});
reducer.on(paymentActions.deletePaymentOrder.types.START, function (state, _a) {
    var _b;
    var paymentOrderId = _a.paymentOrderId;
    return (__assign(__assign({}, state), { deletePaymentOrders: (_b = {},
            _b[paymentOrderId] = {
                isDeleting: true,
                error: undefined,
            },
            _b) }));
});
reducer.on(paymentActions.deletePaymentOrder.types.SUCCESS, function (state, _a) {
    var _b;
    var paymentOrderId = _a.paymentOrderId;
    // Helpers
    var filterDeletedPaymentOrder = function (paymentOrders) {
        return paymentOrders.filter(function (paymentOrder) { return paymentOrder.id !== paymentOrderId; });
    };
    // New state: Payment orders
    var filteredPaymentOrders = filterDeletedPaymentOrder(Object.values(state.paymentOrders).filter(function (paymentOrder) { return paymentOrder !== undefined; }));
    var paymentOrders = state.paymentOrders
        ? _.keyBy(filteredPaymentOrders, "id")
        : [];
    // New state: Payment orders for property
    var getPaymentOrdersForProperty = {};
    var getPaymentOrdersForPropertyKeys = Object.keys(state.getPaymentOrdersForProperty);
    getPaymentOrdersForPropertyKeys.forEach(function (key) {
        var paymentOrders = state.getPaymentOrdersForProperty[key].paymentOrders;
        getPaymentOrdersForProperty[key] = __assign(__assign({}, state.getPaymentOrdersForProperty[key]), { paymentOrders: paymentOrders
                ? filterDeletedPaymentOrder(paymentOrders)
                : undefined });
    });
    // Payment orders by identifier
    var paymentOrdersByIdentifier = {};
    var paymentOrderIdentifiers = Object.keys(state.paymentOrdersByIdentifier);
    paymentOrderIdentifiers.forEach(function (identifier) {
        var paymentOrderIds = state.paymentOrdersByIdentifier[identifier].paymentOrderIds;
        paymentOrdersByIdentifier[identifier] = __assign(__assign({}, state.paymentOrdersByIdentifier[identifier]), { paymentOrderIds: paymentOrderIds
                ? paymentOrderIds.filter(function (poId) { return poId !== paymentOrderId; })
                : undefined });
    });
    delete state.paymentOrders[paymentOrderId];
    // New state
    return __assign(__assign({}, state), { deletePaymentOrders: (_b = {},
            _b[paymentOrderId] = {
                isDeleting: false,
                error: undefined,
            },
            _b), paymentOrders: paymentOrders, getPaymentOrdersForProperty: getPaymentOrdersForProperty, paymentOrdersByIdentifier: paymentOrdersByIdentifier });
});
reducer.on(paymentActions.deletePaymentOrder.types.FAILURE, function (state, _a) {
    var _b;
    var paymentOrderId = _a.paymentOrderId, error = _a.error;
    return (__assign(__assign({}, state), { deletePaymentOrders: (_b = {},
            _b[paymentOrderId] = {
                isDeleting: false,
                error: error,
            },
            _b) }));
});
var getFetchTypeKey = function (fetchType) {
    switch (fetchType) {
        case EPaymentRequestFetchType.Done:
            return "donePaymentRequests";
        case EPaymentRequestFetchType.Pending:
            return "pendingPaymentRequests";
        case EPaymentRequestFetchType.Open:
        default:
            return "openPaymentRequests";
    }
};
reducer.on(paymentActions.getPaymentRequests.types.START, function (state, _a) {
    var _b;
    var fetchType = _a.fetchType;
    var fetchTypeKey = getFetchTypeKey(fetchType);
    return __assign(__assign({}, state), (_b = {}, _b[fetchTypeKey] = __assign(__assign({ 
        // @ts-ignore TODO: Typescript upgrade
        isExhausted: false, paymentRequests: undefined }, state[fetchTypeKey]), { isFetching: true, fetchError: undefined }), _b));
});
reducer.on(paymentActions.getPaymentRequests.types.SUCCESS, function (state, _a) {
    var _b;
    var fetchType = _a.fetchType, refetch = _a.refetch, isExhausted = _a.isExhausted, paymentRequests = _a.paymentRequests;
    var fetchTypeKey = getFetchTypeKey(fetchType);
    return __assign(__assign({}, state), (_b = { paymentRequests: __assign(__assign({}, state.paymentRequests), keyBy(paymentRequests, "id")) }, _b[fetchTypeKey] = __assign(__assign({}, state[fetchTypeKey]), { isFetching: false, fetchError: undefined, isExhausted: isExhausted, paymentRequests: refetch
            ? paymentRequests
            : __spreadArray(__spreadArray([], paymentRequests, true), (state[fetchTypeKey].paymentRequests || []), true) }), _b));
});
reducer.on(paymentActions.getPaymentRequests.types.FAILURE, function (state, _a) {
    var _b;
    var fetchType = _a.fetchType, error = _a.error;
    var fetchTypeKey = getFetchTypeKey(fetchType);
    return __assign(__assign({}, state), (_b = {}, _b[fetchTypeKey] = __assign(__assign({ paymentRequests: undefined, 
        // @ts-ignore TODO: Typescript upgrade
        isExhausted: false }, state[fetchTypeKey]), { isFetching: false, fetchError: error }), _b));
});
reducer.on(paymentActions.getPaymentRequestsPaged.types.START, function (state, _a) {
    var _b, _c;
    var _d, _e, _f;
    var identifier = _a.identifier, _g = _a.page, page = _g === void 0 ? 0 : _g, filterData = _a.filterData;
    return __assign(__assign({}, state), { pagedPaymentRequests: __assign(__assign({}, state.pagedPaymentRequests), (_b = {}, _b[identifier] = __assign(__assign({}, state.pagedPaymentRequests[identifier]), { filterData: filterData, pages: __assign(__assign({}, (_d = state.pagedPaymentRequests[identifier]) === null || _d === void 0 ? void 0 : _d.pages), (_c = {}, _c[page] = __assign(__assign({}, (_f = (_e = state.pagedPaymentRequests[identifier]) === null || _e === void 0 ? void 0 : _e.pages) === null || _f === void 0 ? void 0 : _f[page]), { isFetching: true, fetchError: undefined }), _c)) }), _b)) });
});
reducer.on(paymentActions.getPaymentRequestsPaged.types.SUCCESS, function (state, _a) {
    var _b, _c;
    var _d;
    var identifier = _a.identifier, _e = _a.page, page = _e === void 0 ? 0 : _e, paymentRequests = _a.paymentRequests;
    return __assign(__assign({}, state), { paymentRequests: __assign(__assign({}, state.paymentRequests), keyBy(paymentRequests, "id")), pagedPaymentRequests: __assign(__assign({}, state.pagedPaymentRequests), (_b = {}, _b[identifier] = __assign(__assign({}, state.pagedPaymentRequests[identifier]), { pages: __assign(__assign({}, (_d = state.pagedPaymentRequests[identifier]) === null || _d === void 0 ? void 0 : _d.pages), (_c = {}, _c[page] = {
                paymentRequestIds: paymentRequests.map(function (paymentRequest) { return paymentRequest.id; }),
                isFetching: false,
                fetchError: undefined,
            }, _c)) }), _b)) });
});
reducer.on(paymentActions.getPaymentRequestsPaged.types.FAILURE, function (state, _a) {
    var _b, _c;
    var _d, _e, _f;
    var identifier = _a.identifier, _g = _a.page, page = _g === void 0 ? 0 : _g, error = _a.error;
    return __assign(__assign({}, state), { pagedPaymentRequests: __assign(__assign({}, state.pagedPaymentRequests), (_b = {}, _b[identifier] = __assign(__assign({}, state.pagedPaymentRequests[identifier]), { pages: __assign(__assign({}, (_d = state.pagedPaymentRequests[identifier]) === null || _d === void 0 ? void 0 : _d.pages), (_c = {}, _c[page] = __assign(__assign({}, (_f = (_e = state.pagedPaymentRequests[identifier]) === null || _e === void 0 ? void 0 : _e.pages) === null || _f === void 0 ? void 0 : _f[page]), { isFetching: false, fetchError: error }), _c)) }), _b)) });
});
reducer.on(paymentActions.getPaymentRequestsByIdentifier.types.START, function (state, _a) {
    var _b;
    var paymentRequestsIdentifier = _a.paymentRequestsIdentifier, filterData = _a.filterData;
    var prevStateForIdentifier = state.paymentRequestsByIdentifier[paymentRequestsIdentifier] || {};
    return __assign(__assign({}, state), { paymentRequestsByIdentifier: __assign(__assign({}, state.paymentRequestsByIdentifier), (_b = {}, _b[paymentRequestsIdentifier] = __assign(__assign({ paymentRequestIds: undefined, isExhausted: false }, prevStateForIdentifier), { filterData: filterData, isFetching: true, fetchError: undefined }), _b)) });
});
reducer.on(paymentActions.getPaymentRequestsByIdentifier.types.SUCCESS, function (state, _a) {
    var _b;
    var paymentRequestsIdentifier = _a.paymentRequestsIdentifier, refetch = _a.refetch, paymentRequests = _a.paymentRequests, isExhausted = _a.isExhausted;
    var prevStateForIdentifier = state.paymentRequestsByIdentifier[paymentRequestsIdentifier] || {};
    var paymentRequestIds = paymentRequests.map(function (_a) {
        var id = _a.id;
        return id;
    });
    return __assign(__assign({}, state), { paymentRequests: __assign(__assign({}, state.paymentRequests), keyBy(paymentRequests, "id")), paymentRequestsByIdentifier: __assign(__assign({}, state.paymentRequestsByIdentifier), (_b = {}, _b[paymentRequestsIdentifier] = __assign(__assign({ filterData: undefined }, prevStateForIdentifier), { isFetching: false, fetchError: undefined, isExhausted: isExhausted, paymentRequestIds: refetch
                ? paymentRequestIds
                : __spreadArray(__spreadArray([], (prevStateForIdentifier.paymentRequestIds || []), true), paymentRequestIds, true) }), _b)) });
});
reducer.on(paymentActions.getPaymentRequestsByIdentifier.types.FAILURE, function (state, _a) {
    var _b;
    var paymentRequestsIdentifier = _a.paymentRequestsIdentifier, error = _a.error;
    var prevStateForIdentifier = state.paymentRequestsByIdentifier[paymentRequestsIdentifier] || {};
    return __assign(__assign({}, state), { paymentRequestsByIdentifier: __assign(__assign({}, state.paymentRequestsByIdentifier), (_b = {}, _b[paymentRequestsIdentifier] = __assign(__assign({ filterData: undefined, paymentRequestIds: undefined, isExhausted: false }, prevStateForIdentifier), { isFetching: false, fetchError: error }), _b)) });
});
reducer.on(paymentActions.getPaymentRequest.types.START, function (state, _a) {
    var _b;
    var id = _a.id;
    return __assign(__assign({}, state), { paymentRequestsById: __assign(__assign({}, state.paymentRequestsById), (_b = {}, _b[id] = __assign(__assign({}, state.paymentRequestsById[id]), { isFetching: true, fetchError: undefined }), _b)) });
});
reducer.on(paymentActions.getPaymentRequest.types.SUCCESS, function (state, _a) {
    var _b, _c;
    var id = _a.id, paymentRequest = _a.paymentRequest;
    return __assign(__assign({}, state), { paymentRequests: __assign(__assign({}, state.paymentRequests), (_b = {}, _b[id] = paymentRequest, _b)), paymentRequestsById: __assign(__assign({}, state.paymentRequestsById), (_c = {}, _c[id] = __assign(__assign({}, state.paymentRequestsById[id]), { isFetching: false, fetchError: undefined }), _c)) });
});
reducer.on(paymentActions.getPaymentRequest.types.FAILURE, function (state, _a) {
    var _b;
    var id = _a.id, error = _a.error;
    return __assign(__assign({}, state), { paymentRequestsById: __assign(__assign({}, state.paymentRequestsById), (_b = {}, _b[id] = __assign(__assign({}, state.paymentRequestsById[id]), { isFetching: false, fetchError: error }), _b)) });
});
reducer.on(paymentActions.updatePaymentRequestInvoicedAt.types.START, function (state, _a) {
    var _b;
    var paymentRequestId = _a.paymentRequestId;
    return (__assign(__assign({}, state), { updatePaymentRequests: (_b = {},
            _b[paymentRequestId] = {
                isUpdatingPaymentRequest: true,
                fetchPaymentOrdersError: undefined,
            },
            _b) }));
});
reducer.on(paymentActions.updatePaymentRequestInvoicedAt.types.SUCCESS, function (state, _a) {
    var _b, _c;
    var paymentRequestId = _a.paymentRequestId, paymentRequest = _a.paymentRequest;
    return merge({}, state, {
        updatePaymentRequests: (_b = {},
            _b[paymentRequestId] = {
                isUpdatingPaymentRequest: false,
                fetchPaymentRequestsError: undefined,
            },
            _b),
        paymentRequests: (_c = {},
            _c[paymentRequestId] = paymentRequest,
            _c),
    });
});
reducer.on(paymentActions.updatePaymentRequestInvoicedAt.types.FAILURE, function (state, _a) {
    var _b;
    var paymentRequestId = _a.paymentRequestId, error = _a.error;
    return (__assign(__assign({}, state), { updatePaymentRequests: (_b = {},
            _b[paymentRequestId] = {
                isUpdatingPaymentRequest: false,
                fetchPaymentRequestsError: error,
            },
            _b) }));
});
reducer.on(paymentActions.deletePaymentRequest.types.START, function (state, _a) {
    var _b;
    var id = _a.id;
    return (__assign(__assign({}, state), { deletePaymentRequests: (_b = {},
            _b[id] = {
                isDeleting: true,
                error: undefined,
            },
            _b) }));
});
reducer.on(paymentActions.deletePaymentRequest.types.SUCCESS, function (state, _a) {
    var _b;
    var id = _a.id;
    // Helpers
    var filterDeletedPaymentRequest = function (paymentRequests) {
        return paymentRequests.filter(function (PaymentRequest) { return PaymentRequest.id !== id; });
    };
    // New state: Payment requests
    var paymentRequests = __assign({}, state.paymentRequests);
    delete paymentRequests[id];
    // New state: Payment requests per state
    var openPaymentRequests = __assign(__assign({}, state.openPaymentRequests), { paymentRequests: state.openPaymentRequests.paymentRequests
            ? filterDeletedPaymentRequest(state.openPaymentRequests.paymentRequests)
            : undefined });
    var pendingPaymentRequests = __assign(__assign({}, state.openPaymentRequests), { paymentRequests: state.pendingPaymentRequests.paymentRequests
            ? filterDeletedPaymentRequest(state.pendingPaymentRequests.paymentRequests)
            : undefined });
    var donePaymentRequests = __assign(__assign({}, state.donePaymentRequests), { paymentRequests: state.donePaymentRequests.paymentRequests
            ? filterDeletedPaymentRequest(state.donePaymentRequests.paymentRequests)
            : undefined });
    // New state
    return __assign(__assign({}, state), { deletePaymentRequests: (_b = {},
            _b[id] = {
                isDeleting: false,
                error: undefined,
            },
            _b), paymentRequests: paymentRequests, openPaymentRequests: openPaymentRequests, pendingPaymentRequests: pendingPaymentRequests, donePaymentRequests: donePaymentRequests });
});
reducer.on(paymentActions.deletePaymentRequest.types.FAILURE, function (state, _a) {
    var _b;
    var id = _a.id, error = _a.error;
    return (__assign(__assign({}, state), { deletePaymentRequests: (_b = {},
            _b[id] = {
                isDeleting: false,
                error: error,
            },
            _b) }));
});
reducer.on(paymentActions.deleteMandate.types.START, function (state, _a) {
    var _b;
    var mandateId = _a.mandateId;
    return (__assign(__assign({}, state), { deleteMandate: __assign(__assign({}, state.deleteMandate), (_b = {}, _b[mandateId] = {
            isDeletingMandate: true,
            deleteMandateError: undefined,
        }, _b)) }));
});
reducer.on(paymentActions.deleteMandate.types.SUCCESS, function (state, _a) {
    var _b;
    var mandateId = _a.mandateId;
    return (__assign(__assign({}, state), { deleteMandate: __assign(__assign({}, state.deleteMandate), (_b = {}, _b[mandateId] = {
            isDeletingMandate: false,
            deleteMandateError: undefined,
        }, _b)) }));
});
reducer.on(paymentActions.deleteMandate.types.FAILURE, function (state, _a) {
    var _b;
    var mandateId = _a.mandateId, error = _a.error;
    return (__assign(__assign({}, state), { deleteMandate: __assign(__assign({}, state.deleteMandate), (_b = {}, _b[mandateId] = {
            isDeletingMandate: false,
            deleteMandateError: error,
        }, _b)) }));
});
reducer.on(paymentActions.markThirdPartyReceivedPaymentRequest.types.START, function (state, _a) {
    var _b;
    var paymentRequestId = _a.paymentRequestId;
    return (__assign(__assign({}, state), { markThirdPartyReceived: __assign(__assign({}, state.markThirdPartyReceived), (_b = {}, _b[paymentRequestId] = {
            isMarkingReceived: true,
            markReceivedError: undefined,
        }, _b)) }));
});
reducer.on(paymentActions.markThirdPartyReceivedPaymentRequest.types.SUCCESS, function (state, _a) {
    var _b;
    var paymentRequestId = _a.paymentRequestId;
    return (__assign(__assign({}, state), { markThirdPartyReceived: __assign(__assign({}, state.markThirdPartyReceived), (_b = {}, _b[paymentRequestId] = {
            isMarkingReceived: false,
            markReceivedError: undefined,
        }, _b)) }));
});
reducer.on(paymentActions.markThirdPartyReceivedPaymentRequest.types.FAILURE, function (state, _a) {
    var _b;
    var paymentRequestId = _a.paymentRequestId, error = _a.error;
    return (__assign(__assign({}, state), { markThirdPartyReceived: __assign(__assign({}, state.markThirdPartyReceived), (_b = {}, _b[paymentRequestId] = {
            isMarkingReceived: false,
            markReceivedError: error,
        }, _b)) }));
});
reducer.on(paymentActions.markPaymentRequestPaid.types.START, function (state, _a) {
    var _b;
    var paymentRequestId = _a.paymentRequestId;
    return (__assign(__assign({}, state), { markPaid: __assign(__assign({}, state.markPaid), (_b = {}, _b[paymentRequestId] = {
            isMarkingPaid: true,
            markPaidError: undefined,
        }, _b)) }));
});
reducer.on(paymentActions.markPaymentRequestPaid.types.SUCCESS, function (state, _a) {
    var _b;
    var paymentRequestId = _a.paymentRequestId;
    return (__assign(__assign({}, state), { markPaid: __assign(__assign({}, state.markPaid), (_b = {}, _b[paymentRequestId] = {
            isMarkingPaid: false,
            markPaidError: undefined,
        }, _b)) }));
});
reducer.on(paymentActions.markPaymentRequestPaid.types.FAILURE, function (state, _a) {
    var _b;
    var paymentRequestId = _a.paymentRequestId, error = _a.error;
    return (__assign(__assign({}, state), { markPaid: __assign(__assign({}, state.markPaid), (_b = {}, _b[paymentRequestId] = {
            isMarkingPaid: false,
            markPaidError: error,
        }, _b)) }));
});
reducer.on(paymentActions.getMissingKYCs.types.START, function (state, _a) {
    var _b;
    var missingKYCsIdentifier = _a.missingKYCsIdentifier;
    return __assign(__assign({}, state), { missingKYCs: __assign(__assign({}, state.missingKYCs), (_b = {}, _b[missingKYCsIdentifier] = __assign(__assign({ isExhausted: false }, state.missingKYCs[missingKYCsIdentifier]), { isFetching: true, fetchError: undefined }), _b)) });
});
reducer.on(paymentActions.getMissingKYCs.types.SUCCESS, function (state, _a) {
    var _b;
    var missingKYCsIdentifier = _a.missingKYCsIdentifier, items = _a.items, isExhausted = _a.isExhausted;
    return __assign(__assign({}, state), { missingKYCs: __assign(__assign({}, state.missingKYCs), (_b = {}, _b[missingKYCsIdentifier] = __assign(__assign({}, state.missingKYCs[missingKYCsIdentifier]), { isFetching: false, fetchError: undefined, isExhausted: isExhausted, items: items }), _b)) });
});
reducer.on(paymentActions.getMissingKYCs.types.FAILURE, function (state, _a) {
    var _b;
    var missingKYCsIdentifier = _a.missingKYCsIdentifier, error = _a.error;
    return __assign(__assign({}, state), { missingKYCs: __assign(__assign({}, state.missingKYCs), (_b = {}, _b[missingKYCsIdentifier] = __assign(__assign({ isExhausted: false }, state.missingKYCs[missingKYCsIdentifier]), { isFetching: false, fetchError: error }), _b)) });
});
reducer.on(paymentActions.sendPaymentRequestInvitation.types.START, function (state, _a) {
    var _b;
    var paymentRequestId = _a.paymentRequestId;
    return (__assign(__assign({}, state), { sendInvitation: __assign(__assign({}, state.sendInvitation), (_b = {}, _b[paymentRequestId] = {
            isSendingInvitation: true,
            sendInvitationError: undefined,
        }, _b)) }));
});
reducer.on(paymentActions.sendPaymentRequestInvitation.types.SUCCESS, function (state, _a) {
    var _b;
    var paymentRequestId = _a.paymentRequestId;
    return (__assign(__assign({}, state), { sendInvitation: __assign(__assign({}, state.sendInvitation), (_b = {}, _b[paymentRequestId] = {
            isSendingInvitation: false,
            sendInvitationError: undefined,
        }, _b)) }));
});
reducer.on(paymentActions.sendPaymentRequestInvitation.types.FAILURE, function (state, _a) {
    var _b;
    var paymentRequestId = _a.paymentRequestId, error = _a.error;
    return (__assign(__assign({}, state), { sendInvitation: __assign(__assign({}, state.sendInvitation), (_b = {}, _b[paymentRequestId] = {
            isSendingInvitation: false,
            sendInvitationError: error,
        }, _b)) }));
});
// eslint-disable-next-line import/no-default-export
export default reducer;
