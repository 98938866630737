var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as propertyHooks from "@rentiohq/shared-frontend/dist/reduxV2/property/property.hooks";
import { PropertySelect } from "../../../index";
import { Loading } from "../../../Loading/Loading.styled";
import { ELoadingSize } from "../../../Loading/Loading.types";
export var FormPropertySelect = function (_a) {
    var selectedPropertyId = _a.selectedPropertyId, onSelectProperty = _a.onSelectProperty, disabled = _a.disabled, customPropertyFilter = _a.customPropertyFilter, rest = __rest(_a, ["selectedPropertyId", "onSelectProperty", "disabled", "customPropertyFilter"]);
    var _b = propertyHooks.useDetail({
        shouldRefetch: false,
        id: selectedPropertyId,
    }), selectedProperty = _b.detail, isFetching = _b.isFetching;
    var handlePropertyAdd = function (property) {
        onSelectProperty(property.id);
    };
    var handlePropertyRemove = function (_) {
        if (!onSelectProperty) {
            return;
        }
        onSelectProperty();
    };
    if (isFetching)
        return _jsx(Loading, { size: ELoadingSize.Large });
    return (_jsx(PropertySelect, __assign({ onPropertyAdd: handlePropertyAdd, onPropertyRemove: handlePropertyRemove, showPropertyList: true, properties: selectedProperty ? [selectedProperty] : [], 
        // properties={data && data.property ? [data.property] : []}
        maxItems: 1, disabled: disabled, customFilter: customPropertyFilter, label: rest.title }, rest)));
};
