var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var DEFAULT_ADDRESS = {
    number: "",
    box: "",
    street: "",
    city: "",
    zip: "",
    state: "",
    country: "",
};
var ADDRESS_MAP_KEYS = {
    street_number: "number",
    route: "street",
    sublocality: "city",
    locality: "city",
    postal_code: "zip",
    country: "country",
    administrative_area_level_1: "state",
};
var ADDRESS_MAP_LENGTH = {
    street_number: "short_name",
    route: "long_name",
    sublocality: "short_name",
    locality: "long_name",
    country: "short_name",
    postal_code: "short_name",
    administrative_area_level_1: "long_name",
};
export var mapGooglePlaceToAddress = function (input) {
    var result = __assign({}, DEFAULT_ADDRESS);
    Object.keys(ADDRESS_MAP_KEYS).forEach(function (googleKey) {
        var _a;
        var rentioKey = ADDRESS_MAP_KEYS[googleKey];
        // Already filled in
        if (result[rentioKey].length > 0) {
            return;
        }
        var component = (_a = input.address_components) === null || _a === void 0 ? void 0 : _a.find(function (x) {
            return x.types.includes(googleKey);
        });
        if (!component) {
            return;
        }
        var value = component[ADDRESS_MAP_LENGTH[googleKey]];
        if (!value) {
            return;
        }
        result[rentioKey] = value;
    });
    return result;
};
export var belgiumBounds = {
    south: 49.4967,
    west: 2.546,
    north: 51.505,
    east: 6.4038,
};
