var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
import api from "../../utils/api/api.utils";
import { mapObjectWithTimeZonedDatesToUtcIsoString } from "../../utils/date.utils";
import { appendQueryParams } from "../../utils/url.utils";
import { generateApi } from "../generators/generator.api";
import { GENERATOR_PARAMS } from "./paymentRequest.constants";
export var getPaged = (_a = generateApi(GENERATOR_PARAMS), _a.getPaged), getDetail = _a.getDetail, getCount = _a.getCount, getInfiniteLoad = _a.getInfiniteLoad, update = _a.update, updateLegacy = _a.updateLegacy, create = _a.create, remove = _a.remove;
export var paymentRequestPaidIncorrectWallet = function (paymentRequestId, data) {
    return api.post("/".concat(GENERATOR_PARAMS.basePathEntity, "/").concat(paymentRequestId, "/paid-incorrect-wallet"), {
        data: __assign({ paymentRequestId: paymentRequestId }, data),
        isServerless: true,
    });
};
export var getMollieUrl = function (paymentRequestId, callbackUrl) {
    return api.post("/generate-payments/".concat(paymentRequestId, "/mollie"), {
        data: {
            callbackUrl: callbackUrl,
        },
        isServerless: true,
    });
};
export var generateWebPayment = function (paymentRequestId, paymentMethod, callbackUrl) {
    return api.post(appendQueryParams({
        path: "/generate-payments/".concat(paymentRequestId, "/web"),
        queryParams: {
            paymentMethod: paymentMethod,
        },
    }), {
        data: {
            callbackUrl: callbackUrl,
        },
        isServerless: true,
    });
};
export var payPaymentRequest = function (id, ibanId, createRentioMandate) {
    return api.post("/".concat(GENERATOR_PARAMS.basePathEntity, "/").concat(id, "/pay"), {
        data: { bankaccountId: ibanId, createRentioMandate: createRentioMandate },
        isServerless: true,
    });
};
export var getMandate = function (ibanId, callbackUrl) {
    return api.post("/bankaccounts/".concat(ibanId, "/mandate"), {
        data: {
            callbackUrl: callbackUrl,
        },
        isServerless: true,
    });
};
export var generateBankWirePayment = function (id) {
    return api.post("/generate-payments/".concat(id, "/mangopay-bankwire"), {
        data: {},
        isServerless: true,
    });
};
export var markPaymentRequestPaid = function (params) {
    var id = params.id, manuallyPaidAt = params.manuallyPaidAt, amount = params.amount;
    return api.post("/".concat(GENERATOR_PARAMS.basePathEntity, "/").concat(id, "/mark-manually-paid"), {
        data: { manuallyPaidAt: manuallyPaidAt, amount: amount },
        isServerless: true,
    });
};
export var paymentRequestInvoicedAt = function (id, invoicedAt) { return __awaiter(void 0, void 0, void 0, function () {
    var result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, api.post("/".concat(GENERATOR_PARAMS.basePathEntity, "/").concat(id, "/invoiced-at"), {
                    data: { invoicedAt: invoicedAt },
                    isV2: true,
                })];
            case 1:
                result = _a.sent();
                return [2 /*return*/, result === null || result === void 0 ? void 0 : result.data];
        }
    });
}); };
export var generatePaymentLink = function (id) { return __awaiter(void 0, void 0, void 0, function () {
    var result;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, api.get("/".concat(GENERATOR_PARAMS.basePathEntity, "/").concat(id, "/payment-link"), { isV2: true })];
            case 1:
                result = _b.sent();
                return [2 /*return*/, (_a = result === null || result === void 0 ? void 0 : result.data) === null || _a === void 0 ? void 0 : _a.data];
        }
    });
}); };
export var grantAccessPaymentRequest = function (paymentRequestId, data) { return __awaiter(void 0, void 0, void 0, function () {
    var result;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, api.post("/".concat(GENERATOR_PARAMS.basePathEntity, "/").concat(paymentRequestId, "/grant-access"), { data: data, isV2: true })];
            case 1:
                result = _b.sent();
                return [2 /*return*/, (_a = result === null || result === void 0 ? void 0 : result.data) === null || _a === void 0 ? void 0 : _a.data];
        }
    });
}); };
export var sendPaymentRequestInvitation = function (paymentRequestId) { return __awaiter(void 0, void 0, void 0, function () {
    var result;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, api.post("/".concat(GENERATOR_PARAMS.basePathEntity, "/").concat(paymentRequestId, "/invite"), { isV2: true })];
            case 1:
                result = _b.sent();
                return [2 /*return*/, (_a = result === null || result === void 0 ? void 0 : result.data) === null || _a === void 0 ? void 0 : _a.data];
        }
    });
}); };
export var paymentRequestReminderMail = function (paymentRequestId, data) {
    return api.post("/payment-requests/".concat(paymentRequestId, "/reminder/email"), {
        data: mapObjectWithTimeZonedDatesToUtcIsoString(data),
        isV2: true,
    });
};
export var paymentRequestReminderChatApp = function (paymentRequestId, data) {
    return api.post("/payment-requests/".concat(paymentRequestId, "/reminder/chat"), {
        data: mapObjectWithTimeZonedDatesToUtcIsoString(data),
        isV2: true,
    });
};
export var paymentRequestReminderPhone = function (paymentRequestId, data) {
    return api.post("/payment-requests/".concat(paymentRequestId, "/reminder/phone"), {
        data: mapObjectWithTimeZonedDatesToUtcIsoString(data),
        isV2: true,
    });
};
export var paymentRequestReminderSms = function (paymentRequestId, data) {
    return api.post("/payment-requests/".concat(paymentRequestId, "/reminder/sms"), {
        data: mapObjectWithTimeZonedDatesToUtcIsoString(data),
        isV2: true,
    });
};
export var paymentRequestReminderRegisteredMail = function (paymentRequestId, data) {
    return api.post("/registered-mails", {
        data: mapObjectWithTimeZonedDatesToUtcIsoString(__assign({ paymentRequestId: paymentRequestId }, data)),
        isServerless: true,
    });
};
