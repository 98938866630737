import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { closeModal } from "./components/modal.utils";
import { ModalFooter } from "./components/ModalFooter";
import { ModalHeader } from "./components/ModalHeader";
import * as S from "./Modal.styled";
export var ModalInternal = function (props) {
    var id = props.id, type = props.type, actions = props.actions, children = props.children, components = props.components, footer = props.footer, _a = props.forceRenderTabPanel, forceRenderTabPanel = _a === void 0 ? true : _a, _b = props.fullBody, fullBody = _b === void 0 ? false : _b, _c = props.hasDismiss, hasDismiss = _c === void 0 ? true : _c, heading = props.heading, onClose = props.onClose, _d = props.shouldCloseOnOverlayClick, shouldCloseOnOverlayClick = _d === void 0 ? true : _d, tabs = props.tabs, _e = props.width, width = _e === void 0 ? "large" : _e, onChangeTab = props.onChangeTab;
    React.useEffect(function () {
        return function () {
            if (id) {
                closeModal(id);
            }
        };
    }, []);
    // Render
    var renderHeader = (_jsx(ModalHeader, { type: type, heading: heading, component: components === null || components === void 0 ? void 0 : components.Header, onClose: onClose, hasDismiss: hasDismiss }));
    var renderFooter = (_jsx(ModalFooter, { actions: actions, component: (components === null || components === void 0 ? void 0 : components.Footer) || footer, onClose: onClose }));
    return (_jsxs(S.OuterWrap, { children: [_jsx(S.Background, { onClick: shouldCloseOnOverlayClick ? onClose : undefined }), _jsx(S.ModalContainer, { width: width, children: _jsx(S.Dialog, { children: _jsxs(S.Wrapper, { as: (components === null || components === void 0 ? void 0 : components.Container) || "div", children: [renderHeader, (tabs || children) && (_jsxs(S.Content, { children: [tabs && (_jsx(S.Tabs, { onChangeTab: onChangeTab, tabs: tabs, forceRenderTabPanel: forceRenderTabPanel })), children && _jsx(S.Body, { fullBody: fullBody, children: children })] })), renderFooter] }) }) })] }));
};
