var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { formatAddress } from "@rentiohq/shared-frontend/dist/utils/address.utils";
import isEmpty from "ramda/es/isEmpty";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ts } from "../../services";
import utils from "../../utils";
import { Icon } from "../Icon";
import { PropertyFetchListItem } from "../PropertyFetchListItem";
import { ResourceList, ResourceListItem, } from "../ResourceList";
import { TextStyle } from "../TextStyle";
var defaultPropertyMeta = function (property) {
    var propertyMetaDataKeys = ["name"];
    return propertyMetaDataKeys.reduce(
    // @ts-ignore
    function (currentKeys, key) {
        // @ts-ignore
        return property.hasOwnProperty(key) && property[key]
            ? // @ts-ignore
             __spreadArray(__spreadArray([], currentKeys, true), [property[key]], false) : currentKeys;
    }, []);
};
export var DefaultPropertyListItemRender = function (_a) {
    var propertyTitle = _a.propertyTitle, propertyMeta = _a.propertyMeta, property = _a.property;
    return (_jsxs(_Fragment, { children: [_jsx("div", { children: propertyTitle || formatAddress(property) }), _jsx(TextStyle, { variation: "subdued", size: "small", children: propertyMeta || defaultPropertyMeta(property) })] }));
};
export var PropertyList = function (_a) {
    var properties = _a.properties, propertyIds = _a.propertyIds, actions = _a.actions, onItemClick = _a.onItemClick, _b = _a.hasSeparator, hasSeparator = _b === void 0 ? true : _b, _c = _a.mediaSize, mediaSize = _c === void 0 ? "large" : _c, propertyListItemRender = _a.propertyListItemRender, _d = _a.readOnly, readOnly = _d === void 0 ? false : _d, _e = _a.boxProps, boxProps = _e === void 0 ? { p: 2 } : _e;
    var navigate = useNavigate();
    var renderPropertyItem = function (property) {
        var propertyMetaData = defaultPropertyMeta(property);
        var mappedActions = readOnly
            ? undefined
            : __spreadArray(__spreadArray([], (actions || []).map(function (action) {
                var newAction = __assign({}, action);
                newAction.onClick = function () { return action.onClick(property); };
                return newAction;
            }), true), [
                {
                    content: ts.propertyViewPropertyAction(),
                    onClick: function () {
                        navigate("/properties/".concat(property.id));
                    },
                },
            ], false);
        var handleClick = function () {
            onItemClick === null || onItemClick === void 0 ? void 0 : onItemClick()(property);
        };
        var iconSource = property.typeId
            ? utils.properties.getIcon(property.typeId)
            : "buildings";
        return (_jsx(ResourceListItem, { item: property, boxProps: __assign({}, boxProps), onClick: onItemClick && handleClick, mediaSize: mediaSize, media: _jsx(Icon, { source: iconSource, size: mediaSize }), actions: mappedActions, children: propertyListItemRender ? (React.createElement(propertyListItemRender, {
                property: property,
                propertyTitle: formatAddress(property),
                propertyMeta: !isEmpty(propertyMetaData) && propertyMetaData.join(" | "),
            })) : (_jsx(DefaultPropertyListItemRender, { property: property, propertyTitle: formatAddress(property), propertyMeta: !isEmpty(propertyMetaData) && propertyMetaData.join(" | ") })) }));
    };
    var renderPropertyIdItem = function (propertyId) {
        return (_jsx(PropertyFetchListItem, { shouldRefetch: false, propertyId: propertyId, renderProperty: renderPropertyItem }));
    };
    if (propertyIds) {
        return (_jsx(ResourceList, { items: propertyIds, renderItem: renderPropertyIdItem, hasSeparator: hasSeparator }));
    }
    if (properties) {
        return (_jsx(ResourceList, { items: properties, renderItem: renderPropertyItem, hasSeparator: hasSeparator }));
    }
    return null;
};
