import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import * as propertyHooks from "@rentiohq/shared-frontend/dist/reduxV2/property/property.hooks";
import { formatAddress } from "@rentiohq/shared-frontend/dist/utils/address.utils";
import React from "react";
import { Loader } from "../LoadingWrapper/LoadingWrapper.styled";
import { StyledSearchResultLink, StyledSearchResultMembers, StyledSearchResultMeta, } from "./SearchList.styled";
var PropertyDetails = function (_a) {
    var propertyId = _a.propertyId;
    var _b = propertyHooks.useDetail({
        shouldRefetch: false,
        id: propertyId,
    }), property = _b.detail, isFetching = _b.isFetching;
    if (isFetching || !property)
        return _jsx(Loader, {});
    return (_jsxs(_Fragment, { children: [_jsx("div", { children: formatAddress(property) }), _jsx(StyledSearchResultMeta, { children: property.name }), _jsx(StyledSearchResultMembers, { children: property.members.map(function (member, memberIndex) {
                    return (_jsxs(React.Fragment, { children: [getName(member.account), memberIndex < property.members.length - 1 && ", "] }, "member".concat(memberIndex)));
                }) })] }));
};
export var SearchItemContract = function (_a) {
    var _b = _a.data, data = _b === void 0 ? [] : _b, linkHandler = _a.linkHandler;
    return (_jsx(_Fragment, { children: data.map(function (item, index) {
            if (!item.propertyId) {
                return null;
            }
            var link = "/properties/".concat(item.propertyId, "/contracts");
            return (_jsx(StyledSearchResultLink, { to: link, onClick: linkHandler, children: _jsx(PropertyDetails, { propertyId: item.propertyId }) }, "contract".concat(index)));
        }) }));
};
