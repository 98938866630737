var _a;
import { generateSelectors } from "../generators/generator.selectors";
import { GENERATOR_PARAMS, REDUCER_KEY } from "./paymentRequest.constants";
export var getDetail = (_a = generateSelectors(GENERATOR_PARAMS), _a.getDetail), getPaged = _a.getPaged, getCreatedDetail = _a.getCreatedDetail, getCount = _a.getCount, getInfiniteLoad = _a.getInfiniteLoad, // (Only app)
isFetchingDetail = _a.isFetchingDetail, fetchDetailError = _a.fetchDetailError, isCreating = _a.isCreating, createError = _a.createError, isUpdating = _a.isUpdating, updateError = _a.updateError, isRemoving = _a.isRemoving, removeError = _a.removeError;
export var isMarkingPaid = function (state, paymentRequestId) {
    return state.PAYMENT_REQUESTS_V2.markPaid[paymentRequestId]
        ? state.PAYMENT_REQUESTS_V2.markPaid[paymentRequestId].isMarkingPaid
        : false;
};
export var markPaidError = function (state, paymentRequestId) {
    return state.PAYMENT_REQUESTS_V2.markPaid[paymentRequestId]
        ? state.PAYMENT_REQUESTS_V2.markPaid[paymentRequestId].markPaidError
        : undefined;
};
export var isFetchingPaymentLink = function (state, paymentRequestId) { var _a; return ((_a = state.PAYMENT_REQUESTS_V2.paymentLink[paymentRequestId]) === null || _a === void 0 ? void 0 : _a.isFetching) || false; };
export var getPaymentLink = function (state, paymentRequestId) { var _a; return (_a = state.PAYMENT_REQUESTS_V2.paymentLink[paymentRequestId]) === null || _a === void 0 ? void 0 : _a.url; };
export var isFetchingGrantAccessPaymentRequest = function (state, paymentRequestId) { var _a; return ((_a = state.PAYMENT_REQUESTS_V2.grantAccess[paymentRequestId]) === null || _a === void 0 ? void 0 : _a.isFetching) || false; };
export var getGrantAccessPaymentRequest = function (state, paymentRequestId) { var _a; return (_a = state.PAYMENT_REQUESTS_V2.detail[paymentRequestId]) === null || _a === void 0 ? void 0 : _a.entity; };
export var getGrantAccessPaymentRequestError = function (state, paymentRequestId) { var _a; return (_a = state.PAYMENT_REQUESTS_V2.grantAccess[paymentRequestId]) === null || _a === void 0 ? void 0 : _a.fetchError; };
export var isPendingFollowUp = function (state, paymentRequestId, type) {
    var _a;
    return state.PAYMENT_REQUESTS_V2.reminder[paymentRequestId]
        ? (_a = state.PAYMENT_REQUESTS_V2.reminder[paymentRequestId][type]) === null || _a === void 0 ? void 0 : _a.isFetching
        : false;
};
export var followUpError = function (state, paymentRequestId, type) {
    var _a;
    return state.PAYMENT_REQUESTS_V2.reminder[paymentRequestId]
        ? (_a = state.PAYMENT_REQUESTS_V2.reminder[paymentRequestId][type]) === null || _a === void 0 ? void 0 : _a.fetchError
        : undefined;
};
