import { ERentioFormField } from "@rentiohq/shared-frontend/dist/components/components/Form";
import { getValue } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { EReportingFrequencyType } from "@rentiohq/shared-frontend/dist/types/report.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { findKey } from "lodash";
import { ts } from "../../../../services";
import { EField } from "./schema.editContact.types";
export var EIdCardOptions;
(function (EIdCardOptions) {
    EIdCardOptions["BelgianIdCardNumber"] = "belgian_id_card_number";
    EIdCardOptions["PassportNumber"] = "passport_number";
})(EIdCardOptions || (EIdCardOptions = {}));
export var getMappedFrequencies = function () {
    return Object.values(EReportingFrequencyType).map(function (value, index) { return ({
        id: value,
        value: getLocalizedText("contact.reporting_frequency.".concat(findKey(EReportingFrequencyType, function (v) { return v === value; })).toLowerCase()),
    }); });
};
export var getSchema = function (initialValues, _a) {
    var contact = _a.contact;
    return function (defaultValues) {
        var _a;
        return ({
            required: [],
            properties: (_a = {},
                _a[EField.EnableReporting] = {
                    type: "boolean",
                    default: getValue(EField.EnableReporting, initialValues, defaultValues),
                },
                _a[EField.AutomaticReporting] = {
                    type: "boolean",
                    default: getValue(EField.AutomaticReporting, initialValues, defaultValues),
                    // @ts-ignore
                    showIf: function (formData) { return !!formData[EField.EnableReporting]; },
                },
                _a[EField.ReportingStart] = {
                    default: getValue(EField.ReportingStart, initialValues, defaultValues),
                    // @ts-ignore
                    requiredIf: function (formData) { return !!formData[EField.AutomaticReporting]; },
                    // @ts-ignore
                    showIf: function (formData) { return !!formData[EField.AutomaticReporting]; },
                },
                _a[EField.ReportingFrequency] = {
                    default: getValue(EField.ReportingFrequency, initialValues, defaultValues),
                    // @ts-ignore
                    options: getMappedFrequencies(),
                    // @ts-ignore
                    requiredIf: function (formData) { return !!formData[EField.AutomaticReporting]; },
                    // @ts-ignore
                    showIf: function (formData) { return !!formData[EField.AutomaticReporting]; },
                },
                _a[EField.IncludeOpenPayments] = {
                    type: "boolean",
                    default: getValue(EField.IncludeOpenPayments, initialValues, defaultValues),
                    // @ts-ignore
                    showIf: function (formData) { return !!formData[EField.AutomaticReporting]; },
                },
                _a),
        });
    };
};
export var uiSchema = function (extraData) {
    var _a;
    return (_a = {
            "rentio:navigationTitle": ts.contactEditContact(),
            "rentio:trackingKey": "contact_edit"
        },
        _a[EField.EnableReporting] = {
            "ui:field": ERentioFormField.Switch,
            label: getLocalizedText("contact.enable_reporting.label"),
        },
        _a[EField.AutomaticReporting] = {
            "ui:field": ERentioFormField.Switch,
            label: getLocalizedText("contact.automate_reporting.label"),
        },
        _a[EField.ReportingStart] = {
            "ui:field": ERentioFormField.Date,
            title: getLocalizedText("contact.start_reporting.label"),
            minDate: new Date(),
        },
        _a[EField.ReportingFrequency] = {
            "ui:field": ERentioFormField.DropdownSelect,
            title: getLocalizedText("contact.reporting_frequency.label"),
        },
        _a[EField.IncludeOpenPayments] = {
            "ui:field": ERentioFormField.Switch,
            label: getLocalizedText("contact.include_open_payments.label"),
        },
        _a);
};
