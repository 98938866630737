var _a;
import { compact } from "lodash";
import { generateSelectors } from "../generators/generator.selectors";
import { GENERATOR_PARAMS, REDUCER_KEY } from "./paymentOrder.constants";
export var getDetail = (_a = generateSelectors(GENERATOR_PARAMS), _a.getDetail), getPaged = _a.getPaged, getCreatedDetail = _a.getCreatedDetail, getCount = _a.getCount, 
// getInfiniteLoad,
isCreating = _a.isCreating, createError = _a.createError, isUpdating = _a.isUpdating, isFetchingDetail = _a.isFetchingDetail, fetchDetailError = _a.fetchDetailError, updateError = _a.updateError, isRemoving = _a.isRemoving, removeError = _a.removeError;
export var getPayeeBankAccount = function (id, state) { var _a; return (_a = state.PAYMENT_ORDERS_V2.payee[id]) === null || _a === void 0 ? void 0 : _a.bankAccount; };
export var getPayerBankAccount = function (id, state) { var _a; return (_a = state.PAYMENT_ORDERS_V2.payer[id]) === null || _a === void 0 ? void 0 : _a.bankAccount; };
export var getMissingKYCs = function (state, propertyId) {
    var _a;
    var id = propertyId ? propertyId : "all";
    return (_a = state.PAYMENT_ORDERS_V2.missingKycs[id]) === null || _a === void 0 ? void 0 : _a.missingKycs;
};
export var isFetchingMissingKYCs = function (state, propertyId) {
    var _a;
    var id = propertyId ? propertyId : "all";
    return ((_a = state.PAYMENT_ORDERS_V2.missingKycs[id]) === null || _a === void 0 ? void 0 : _a.pending) || false;
};
export var fetchErrorMissingKYCs = function (state, propertyId) {
    var _a;
    var id = propertyId ? propertyId : "all";
    return (_a = state.PAYMENT_ORDERS_V2.missingKycs[id]) === null || _a === void 0 ? void 0 : _a.error;
};
export var getCreatedPaymentOrdersDetail = function (state) {
    var _a;
    var ids = (_a = state.PAYMENT_ORDERS_V2.createPaymentOrders) === null || _a === void 0 ? void 0 : _a.ids;
    var items = compact(ids === null || ids === void 0 ? void 0 : ids.map(function (id) { var _a; return (_a = state.PAYMENT_ORDERS_V2.detail["".concat(id)]) === null || _a === void 0 ? void 0 : _a.entity; }));
    return items;
};
export var isCreatingPaymentOrders = function (state) {
    return state.PAYMENT_ORDERS_V2.createPaymentOrders.pending || false;
};
export var createPaymentOrdersError = function (state) {
    return state.PAYMENT_ORDERS_V2.createPaymentOrders.error;
};
