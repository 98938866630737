import activityReducer, {
  IActivityState,
  REDUCER_KEY_ACTIVITY,
} from "@rentiohq/shared-frontend/dist/redux/activity/activity.reducer";
import {
  IAuthState,
  REDUCER_KEY_AUTH,
} from "@rentiohq/shared-frontend/dist/redux/auth/auth.reducer";
import authReducer from "@rentiohq/shared-frontend/dist/redux/auth/auth.reducerPersist";
import contactReducer, {
  IContactState,
  REDUCER_KEY_CONTACT,
} from "@rentiohq/shared-frontend/dist/redux/contact/contact.reducer";
import countReducer, {
  ICountState,
  REDUCER_KEY_COUNT,
} from "@rentiohq/shared-frontend/dist/redux/count/count.reducer";
import formReducer, {
  IFormState,
  REDUCER_KEY_FORM,
} from "@rentiohq/shared-frontend/dist/redux/form/form.reducer";
import agreementReducer, {
  IAgreementState,
  REDUCER_KEY_AGREEMENT,
} from "@rentiohq/shared-frontend/dist/reduxInsurance/agreement/agreement.reducer";
import leadReducer, {
  ILeadState,
  REDUCER_KEY_LEAD,
} from "@rentiohq/shared-frontend/dist/reduxInsurance/lead/lead.reducer";
import quoteReducer, {
  IQuoteState,
  REDUCER_KEY_QUOTE,
} from "@rentiohq/shared-frontend/dist/reduxInsurance/quote/quote.reducer";
import {
  REDUCER_KEY_CHATS as REDUCER_KEY_CHATS_2,
  chatModule,
} from "@rentiohq/shared-frontend/dist/reduxV2/chats";
import statsReducer, {
  IStatsState,
  REDUCER_KEY_STATS,
} from "@rentiohq/web-shared/dist/redux/stats/stats.reducer";
import {
  ISystemLocalState,
  REDUCER_KEY_SYSTEM_LOCAL,
} from "@rentiohq/web-shared/dist/redux/system/system.reducer";
import systemReducer from "@rentiohq/web-shared/dist/redux/system/system.reducerPersist";
import { combineReducers } from "redux";

export interface IRootState {
  [REDUCER_KEY_ACTIVITY]: IActivityState;
  [REDUCER_KEY_AGREEMENT]: IAgreementState;
  [REDUCER_KEY_AUTH]: IAuthState;
  [REDUCER_KEY_CHATS_2]: typeof chatModule.reducerState;
  [REDUCER_KEY_CONTACT]: IContactState;
  [REDUCER_KEY_COUNT]: ICountState;
  [REDUCER_KEY_FORM]: IFormState;
  [REDUCER_KEY_LEAD]: ILeadState;
  [REDUCER_KEY_QUOTE]: IQuoteState;
  [REDUCER_KEY_STATS]: IStatsState;
  [REDUCER_KEY_SYSTEM_LOCAL]: ISystemLocalState;
}

// eslint-disable-next-line import/no-default-export
export default combineReducers({
  [REDUCER_KEY_CHATS_2]: chatModule.reducer,
  activity: activityReducer,
  agreement: agreementReducer,
  auth: authReducer,
  contact: contactReducer,
  count: countReducer,
  form: formReducer,
  lead: leadReducer,
  quote: quoteReducer,
  stats: statsReducer,
  systemLocal: systemReducer,
});
