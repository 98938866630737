var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import api from "../../utils/api/api.utils";
import { append, appendFilter, appendWhere } from "../../utils/api.utils";
import { mapObjectWithTimeZonedDatesToUtcIsoString } from "../../utils/date.utils";
import { appendQueryParams } from "../../utils/url.utils";
var mapRequestData = function (generatorParams, data) {
    var _a = generatorParams.shouldMapRequestBodyToUtc, shouldMapRequestBodyToUtc = _a === void 0 ? true : _a, requestBodyToUtcWhitelist = generatorParams.requestBodyToUtcWhitelist;
    if (shouldMapRequestBodyToUtc) {
        return mapObjectWithTimeZonedDatesToUtcIsoString(data, false, requestBodyToUtcWhitelist);
    }
    return data;
};
var generateGetDetail = function (generatorParams) {
    var basePathEntity = generatorParams.basePathEntity;
    var getDetail = function (params) {
        var id = params.id, customPath = params.customPath, extraQueryParams = params.extraQueryParams;
        return api.get(appendQueryParams({
            path: customPath || "/".concat(basePathEntity, "/").concat(id),
            queryParams: extraQueryParams || {},
        }), {
            isV2: true,
        });
    };
    return getDetail;
};
var generateGetDetailLegacy = function (generatorParams) {
    var basePathEntity = generatorParams.basePathEntity;
    var getDetail = function (params) {
        var id = params.id, customPath = params.customPath;
        return api.get(customPath || "/".concat(basePathEntity, "/").concat(id), {
            isV2: false,
        });
    };
    return getDetail;
};
var generateGetPaged = function (generatorParams) {
    var basePathEntity = generatorParams.basePathEntity, defaultSort = generatorParams.defaultSort;
    var getPaged = function (params) {
        var query = params.query, customPath = params.customPath;
        return api.get(append(customPath || "/".concat(basePathEntity), __assign(__assign({}, query), { sort: query.sort || defaultSort })), { isV2: true });
    };
    return getPaged;
};
var generateGetPagedLegacy = function (generatorParams) {
    var basePathEntity = generatorParams.basePathEntity, defaultSort = generatorParams.defaultSort;
    var getPaged = function (params) {
        var query = params.query, customPath = params.customPath;
        var page = query.page, limit = query.limit, _a = query.sort, sort = _a === void 0 ? defaultSort : _a, filter = query.filter;
        var skip = Math.max(0, page * limit - limit);
        return api.get(appendFilter(customPath || "/".concat(basePathEntity), {
            skip: skip,
            limit: limit,
            order: sort === null || sort === void 0 ? void 0 : sort.map(function (_a) {
                var field = _a.field, method = _a.method;
                return "".concat(field, " ").concat(method || "ASC");
            }),
            where: __assign({}, filter),
        }), { isV2: false });
    };
    return getPaged;
};
var generateGetInfiniteLoad = function (generatorParams) {
    var basePathEntity = generatorParams.basePathEntity, defaultSort = generatorParams.defaultSort;
    var getInfiniteLoad = function (params) {
        var query = params.query, customPath = params.customPath;
        return api.get(append(customPath || "/".concat(basePathEntity), __assign(__assign({}, query), { sort: query.sort || defaultSort })), { isV2: true });
    };
    return getInfiniteLoad;
};
var generateGetAllWhere = function (generatorParams) {
    var basePathEntity = generatorParams.basePathEntity, defaultSort = generatorParams.defaultSort;
    var getAllWhere = function (params) {
        var query = params.query, customPath = params.customPath;
        return api.get(append(customPath || "/".concat(basePathEntity), __assign(__assign({}, query), { sort: (query === null || query === void 0 ? void 0 : query.sort) || defaultSort })), { isV2: true });
    };
    return getAllWhere;
};
var generateGetAllWhereLegacy = function (generatorParams) {
    var basePathEntity = generatorParams.basePathEntity, defaultSort = generatorParams.defaultSort;
    var getAllWhere = function (params) {
        var _a;
        var query = params.query, customPath = params.customPath;
        return api.get(appendFilter(customPath || "/".concat(basePathEntity), {
            order: (_a = ((query === null || query === void 0 ? void 0 : query.sort) || defaultSort)) === null || _a === void 0 ? void 0 : _a.map(function (_a) {
                var field = _a.field, method = _a.method;
                return "".concat(field, " ").concat(method || "ASC");
            }),
            where: query === null || query === void 0 ? void 0 : query.filter,
        }), { isV2: false });
    };
    return getAllWhere;
};
var generateGetCount = function (generatorParams) {
    var basePathEntity = generatorParams.basePathEntity;
    var getCount = function (params) {
        var customPath = params.customPath, query = params.query;
        return api.get(append(customPath || "/".concat(basePathEntity, "/count"), query), { isV2: true });
    };
    return getCount;
};
var generateGetCountLegacy = function (generatorParams) {
    var basePathEntity = generatorParams.basePathEntity;
    var getCount = function (params) {
        var customPath = params.customPath, query = params.query;
        var filter = query.filter;
        return api.get(appendWhere("".concat(customPath || "/".concat(basePathEntity), "/count"), __assign({}, filter)), { isV2: false });
    };
    return getCount;
};
var generateCreate = function (generatorParams) {
    var basePathEntity = generatorParams.basePathEntity;
    var create = function (params) {
        var data = params.data, customPath = params.customPath;
        return api.post(customPath || "/".concat(basePathEntity), {
            isV2: true,
            data: mapRequestData(generatorParams, data),
        });
    };
    return create;
};
var generateCreateLegacy = function (generatorParams) {
    var basePathEntity = generatorParams.basePathEntity;
    var create = function (params) {
        var data = params.data, customPath = params.customPath;
        return api.post(customPath || "/".concat(basePathEntity), {
            isV2: false,
            data: mapRequestData(generatorParams, data),
        });
    };
    return create;
};
var generateUpdate = function (generatorParams) {
    var basePathEntity = generatorParams.basePathEntity;
    var update = function (params) {
        var id = params.id, data = params.data, customPath = params.customPath;
        // TODO: Is custom path always a POST
        if (customPath) {
            return api.post(customPath, {
                isV2: true,
                data: mapRequestData(generatorParams, data),
            });
        }
        return api.patch("/".concat(basePathEntity, "/").concat(id), {
            isV2: true,
            data: mapRequestData(generatorParams, data),
        });
    };
    return update;
};
var generateUpdateLegacy = function (generatorParams) {
    var basePathEntity = generatorParams.basePathEntity;
    var update = function (params) {
        var id = params.id, data = params.data, customPath = params.customPath;
        // TODO: Is custom path always a POST
        if (customPath) {
            return api.post(customPath, {
                isV2: false,
                data: mapRequestData(generatorParams, data),
            });
        }
        return api.put("/".concat(basePathEntity, "/").concat(id), {
            isV2: false,
            data: mapRequestData(generatorParams, data),
        });
    };
    return update;
};
var generateAddMembers = function (generatorParams) {
    var basePathEntity = generatorParams.basePathEntity, _a = generatorParams.legacyAddRemoveMembers, legacyAddRemoveMembers = _a === void 0 ? false : _a;
    var addMembers = function (params) {
        var _a;
        var id = params.id, members = params.members, additionalParams = params.additionalParams;
        var key = "members";
        return api.post("/".concat(basePathEntity, "/").concat(id, "/").concat(key), {
            isV2: !legacyAddRemoveMembers,
            data: legacyAddRemoveMembers
                ? members
                : __assign((_a = {}, _a[key] = members, _a), additionalParams),
        });
    };
    return addMembers;
};
var generateRemoveMembers = function (generatorParams) {
    var basePathEntity = generatorParams.basePathEntity, _a = generatorParams.legacyAddRemoveMembers, legacyAddRemoveMembers = _a === void 0 ? false : _a;
    var removeMembers = function (params) {
        var _a;
        var id = params.id, members = params.members;
        var key = "members";
        return api.delete("/".concat(basePathEntity, "/").concat(id, "/").concat(key), {
            isV2: !legacyAddRemoveMembers,
            data: legacyAddRemoveMembers ? members : (_a = {}, _a[key] = members, _a),
        });
    };
    return removeMembers;
};
var generateAddEmployees = function (generatorParams) {
    var basePathEntity = generatorParams.basePathEntity;
    var addEmployees = function (params) {
        var id = params.id, employees = params.employees;
        return api.post("/".concat(basePathEntity, "/").concat(id, "/employees"), {
            isV2: true,
            data: { members: employees },
        });
    };
    return addEmployees;
};
var generateRemoveEmployees = function (generatorParams) {
    var basePathEntity = generatorParams.basePathEntity;
    var removeEmployees = function (params) {
        var id = params.id, employees = params.employees;
        return api.delete("/".concat(basePathEntity, "/").concat(id, "/employees"), {
            isV2: true,
            data: { members: employees },
        });
    };
    return removeEmployees;
};
var generateRemove = function (generatorParams) {
    var basePathEntity = generatorParams.basePathEntity;
    var remove = function (params) {
        var id = params.id;
        return api.delete("/".concat(basePathEntity, "/").concat(id), {
            isV2: true,
        });
    };
    return remove;
};
var generateArchive = function (generatorParams) {
    var basePathEntity = generatorParams.basePathEntity;
    var archive = function (params) {
        var id = params.id;
        return api.post("/".concat(basePathEntity, "/").concat(id, "/archive"), {
            isV2: true,
        });
    };
    return archive;
};
var generateUnarchive = function (generatorParams) {
    var basePathEntity = generatorParams.basePathEntity;
    var unarchive = function (params) {
        var id = params.id;
        return api.post("/".concat(basePathEntity, "/").concat(id, "/unarchive"), {
            isV2: true,
        });
    };
    return unarchive;
};
export var generateApi = function (generatorParams) {
    var getDetail = generateGetDetail(generatorParams);
    var getDetailLegacy = generateGetDetailLegacy(generatorParams);
    var getPaged = generateGetPaged(generatorParams);
    var getPagedLegacy = generateGetPagedLegacy(generatorParams);
    var getInfiniteLoad = generateGetInfiniteLoad(generatorParams);
    var getAllWhere = generateGetAllWhere(generatorParams);
    var getAllWhereLegacy = generateGetAllWhereLegacy(generatorParams);
    var getCount = generateGetCount(generatorParams);
    var getCountLegacy = generateGetCountLegacy(generatorParams);
    var create = generateCreate(generatorParams);
    var createLegacy = generateCreateLegacy(generatorParams);
    var update = generateUpdate(generatorParams);
    var updateLegacy = generateUpdateLegacy(generatorParams);
    var addMembers = generateAddMembers(generatorParams);
    var removeMembers = generateRemoveMembers(generatorParams);
    var addEmployees = generateAddEmployees(generatorParams);
    var removeEmployees = generateRemoveEmployees(generatorParams);
    var remove = generateRemove(generatorParams);
    var archive = generateArchive(generatorParams);
    var unarchive = generateUnarchive(generatorParams);
    return {
        getDetail: getDetail,
        getDetailLegacy: getDetailLegacy,
        getPaged: getPaged,
        getPagedLegacy: getPagedLegacy,
        getInfiniteLoad: getInfiniteLoad,
        getAllWhere: getAllWhere,
        getAllWhereLegacy: getAllWhereLegacy,
        getCount: getCount,
        getCountLegacy: getCountLegacy,
        create: create,
        createLegacy: createLegacy,
        update: update,
        updateLegacy: updateLegacy,
        addMembers: addMembers,
        removeMembers: removeMembers,
        addEmployees: addEmployees,
        removeEmployees: removeEmployees,
        remove: remove,
        archive: archive,
        unarchive: unarchive,
    };
};
