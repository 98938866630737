var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { CONFIG } from "@rentiohq/shared-frontend/dist/config/app.config";
import { belgiumBounds, mapGooglePlaceToAddress, } from "@rentiohq/shared-frontend/dist/utils/address.google.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { debounce } from "lodash";
import React, { useMemo } from "react";
import scriptjs from "scriptjs";
import * as t from "../../services/translationService";
import { address as addressUtils, object } from "../../utils";
import { Icon } from "../Icon";
import { AsyncSelect } from "../Select";
export var AddressLookupField = function (_a) {
    var onAddressSelect = _a.onAddressSelect, value = _a.value, _b = _a.types, types = _b === void 0 ? ["address"] : _b, props = __rest(_a, ["onAddressSelect", "value", "types"]);
    var _c = React.useState(), inputValue = _c[0], setInputValue = _c[1];
    var _d = React.useState(null), autocompleteService = _d[0], setAutocompleteService = _d[1];
    var _e = React.useState(null), placesService = _e[0], setPlacesService = _e[1];
    var _f = React.useState(false), isMapsLoaded = _f[0], setIsMapsLoaded = _f[1];
    var searchBoxRef = React.useRef();
    React.useEffect(function () {
        var timer = setTimeout(function () {
            var googleMapURL = "https://maps.googleapis.com/maps/api/js?key=".concat(CONFIG.GOOGLE_MAPS_API, "&libraries=places");
            scriptjs(googleMapURL, handleMapsLoaded);
        }, 200);
        return function () {
            clearTimeout(timer);
        };
    }, []);
    React.useEffect(function () {
        if (isMapsLoaded &&
            autocompleteService === null &&
            placesService === null) {
            setAutocompleteService(new window.google.maps.places.AutocompleteService(searchBoxRef.current));
            setPlacesService(new window.google.maps.places.PlacesService(searchBoxRef.current));
        }
    }, [searchBoxRef, isMapsLoaded]);
    React.useEffect(function () {
        setInputValue(addressUtils.addressObjToString(value));
    }, [value]);
    var handleMapsLoaded = function () { return setIsMapsLoaded(true); };
    var getPlacePredictionsBase = function (params) {
        var input = params.input, onComplete = params.onComplete;
        if (!autocompleteService) {
            onComplete([]);
            return;
        }
        autocompleteService.getPlacePredictions({
            input: input,
            types: types,
            locationBias: belgiumBounds,
        }, function (predictions, status) {
            if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
                onComplete([]);
            }
            var options = [];
            if (predictions) {
                options = predictions.map(function (prediction) { return (__assign({ label: prediction.description, value: prediction.place_id }, prediction)); });
            }
            onComplete(options);
        });
    };
    var getPlacePredictions = useMemo(function () { return debounce(getPlacePredictionsBase, 550); }, [autocompleteService]);
    var handleLoadOptions = function (input, callback) {
        var inputSanitized = (input || "").trim();
        var MANUAL_OPTION = {
            label: getLocalizedText("form.address.cta.manual"),
            value: "-1",
        };
        if (inputSanitized.length === 0) {
            callback([MANUAL_OPTION]);
            return;
        }
        getPlacePredictions({
            input: inputSanitized,
            onComplete: function (options) {
                callback(__spreadArray(__spreadArray([], options, true), [MANUAL_OPTION], false));
            },
        });
    };
    var handleChange = function (place) {
        if (!place.place_id) {
            onAddressSelect === null || onAddressSelect === void 0 ? void 0 : onAddressSelect({ country: "BE" }, place.description);
            setInputValue("");
            return;
        }
        placesService.getDetails({
            placeId: place.place_id,
            fields: ["address_component", "opening_hours", "geometry"],
        }, function (googlePlace, status) {
            // @ts-ignore
            if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
                return;
            }
            var address = mapGooglePlaceToAddress(googlePlace);
            onAddressSelect === null || onAddressSelect === void 0 ? void 0 : onAddressSelect(object.removeEmpty(address), place.description);
            setInputValue(addressUtils.addressObjToString(address));
        });
    };
    var handleInputChange = function (newValue) {
        setInputValue(newValue);
    };
    var locationIcon = React.useMemo(function () { return _jsx(Icon, { source: "locationSearch" }); }, []);
    return (_jsxs(_Fragment, { children: [_jsx("div", { ref: searchBoxRef }), _jsx(AsyncSelect, __assign({ cacheOptions: true, onInputChange: handleInputChange, onChange: handleChange, loadOptions: handleLoadOptions, blurInputOnSelect: true, inputValue: inputValue, icon: locationIcon, placeholder: t.addressFullAddressPlaceholder() }, props))] }));
};
