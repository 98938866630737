import { createRoutine } from "../utils/routine.utils";
export var getPaymentOrdersForProperty = createRoutine("PAYMENT_ORDERS_PROPERTY_GET");
export var getPaymentOrders = createRoutine("PAYMENT_ORDERS_GET");
export var getPaymentOrdersByIdentifier = createRoutine("PAYMENT_ORDERS_GET_BY_IDENTIFIER");
export var getPaymentOrdersPaged = createRoutine("PAYMENT_ORDERS_GET_PAGED");
export var createPaymentOrder = createRoutine("PAYMENT_ORDER_CREATE");
export var getPaymentOrder = createRoutine("PAYMENT_ORDER_GET");
export var updatePaymentOrder = createRoutine("PAYMENT_ORDER_UPDATE");
export var deletePaymentOrder = createRoutine("PAYMENT_ORDER_DELETE");
export var getPaymentRequests = createRoutine("PAYMENT_REQUESTS_GET");
export var getPaymentRequestsByIdentifier = createRoutine("PAYMENT_REQUESTS_GET_BY_IDENTIFIER");
export var getPaymentRequestsPaged = createRoutine("PAYMENT_REQUESTS_GET_PAGED");
export var getPaymentRequest = createRoutine("PAYMENT_REQUEST_GET");
export var updatePaymentRequestInvoicedAt = createRoutine("PAYMENT_REQUEST_UPDATE_INVOICED_AT");
export var deletePaymentRequest = createRoutine("PAYMENT_REQUEST_DELETE");
export var markThirdPartyReceivedPaymentRequest = createRoutine("PAYMENT_REQUEST_THIRD_PARTY_RECEIVED");
export var markPaymentRequestPaid = createRoutine("PAYMENT_REQUEST_MARK_PAID");
export var deleteMandate = createRoutine("PAYMENT_DELETE_MANDATE");
export var getMissingKYCs = createRoutine("MISSING_KYCS_GET");
export var sendPaymentRequestInvitation = createRoutine("PAYMENT_REQUEST_SEND_INVITATION");
export var getIban = createRoutine("PAYMENT_ORDER_IBAN_GET");
