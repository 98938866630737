import { CONFIG } from "@rentiohq/shared-frontend/dist/config/app.config";
import { EEnvironment } from "@rentiohq/shared-frontend/dist/config/app.config.types";
import { useGetAgreement } from "@rentiohq/shared-frontend/dist/reduxInsurance/agreement/agreement.hooks";
import * as leadApi from "@rentiohq/shared-frontend/dist/reduxInsurance/lead/lead.api";
import { useGetLead } from "@rentiohq/shared-frontend/dist/reduxInsurance/lead/lead.hooks";
import { formatAddress } from "@rentiohq/shared-frontend/dist/reduxInsurance/lead/lead.utils";
import { useGetQuote } from "@rentiohq/shared-frontend/dist/reduxInsurance/quote/quote.hooks";
import { getQuoteBasePrice } from "@rentiohq/shared-frontend/dist/reduxInsurance/quote/quote.utils";
import { ELeadStatus } from "@rentiohq/shared-frontend/dist/types/insurance.lead.types";
import { EPropertyTypeId } from "@rentiohq/shared-frontend/dist/types/property.types";
import { showAlert } from "@rentiohq/shared-frontend/dist/utils/alert/alert.utils";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { formatCurrency } from "@rentiohq/shared-frontend/dist/utils/number.utils";
import { formatPhone } from "@rentiohq/shared-frontend/dist/utils/phone/phone.utils";
import { stringToSnakeCase } from "@rentiohq/shared-frontend/dist/utils/string.utils";
import {
  Card,
  DisplayText,
  Error,
  ESpacings,
  Loading,
  Page,
  ResourceList,
  ResourceListItem,
  TextStyle,
} from "@rentiohq/web-shared/dist/components";
import { TabAction } from "@rentiohq/web-shared/dist/types";
import React from "react";
import assets from "../../../../assets";
import LeadActivities from "../LeadActivities";
import LeadStatusLozenge from "../LeadStatusLozenge";
import * as S from "./LeadDetail.styles";

enum ELeadDetailTab {
  Info = "info",
  Activities = "activities",
}

interface IProps {
  leadId: string;
}

const LeadDetail: React.FC<IProps> = props => {
  const { leadId } = props;

  // Custom hooks
  const {
    lead,
    isFetching: isFetchingLead,
    fetchError: fetchLeadError,
    storeUpdatedLead,
  } = useGetLead({
    leadId,
  });
  const { quote, isFetching: isFetchingQuote } = useGetQuote({
    leadId,
    quoteId: lead?.activeQuoteId,
  });
  const { agreement, isFetching: isFetchingAgreement } = useGetAgreement({
    leadId,
    quoteId: lead?.activeQuoteId,
    agreementId: quote?.activeAgreementId,
  });

  // State
  const [tab, setTab] = React.useState<ELeadDetailTab>(ELeadDetailTab.Info);

  // Helpers
  const tabs = React.useCallback((): TabAction[] => {
    if (!lead) {
      return [];
    }

    return [ELeadDetailTab.Info, ELeadDetailTab.Activities].map(randomTab => ({
      content: randomTab,
      onClick: () => {
        setTab(randomTab);
      },
      isActive: tab === randomTab,
    }));
  }, [lead, tab]);

  // Render
  if (!lead) {
    if (isFetchingLead) {
      return <Loading />;
    }

    if (fetchLeadError) {
      return <Error errors={[fetchLeadError]} />;
    }

    return null;
  }

  const renderCardHeader = (heading: string) => (
    <DisplayText size="extraSmall" space="tight">
      {heading}
    </DisplayText>
  );

  const renderIconListItem = (params: {
    icon: string;
    title: string;
    itemsPrimary?: string[];
    itemsSecondary?: string[];
  }) => {
    const { icon, title, itemsPrimary = [], itemsSecondary = [] } = params;

    return (
      <S.IconListItem>
        <S.Icon src={icon} />
        <S.IconListItemContent>
          <TextStyle variation={"strong"}>{title}</TextStyle>
          {itemsPrimary.length > 0 && (
            <>
              <br />
              <TextStyle variation={"subdued"}>
                {itemsPrimary.join(" | ")}
              </TextStyle>
            </>
          )}
          {itemsSecondary.length > 0 && (
            <>
              <br />
              <TextStyle variation={"subdued"}>
                {itemsSecondary.join(" | ")}
              </TextStyle>
            </>
          )}
        </S.IconListItemContent>
      </S.IconListItem>
    );
  };

  const renderRow = (item: {
    title: string;
    value?: string;
    action?: { id: string; content: string; onClick: () => void };
    actions?: { id: string; content: string; onClick: () => void }[];
  }) => {
    let formattedValue = item.value;
    if (!item.value || item.value.length === 0) {
      formattedValue = getLocalizedText("tenant_profile.pdf.item.empty");
    }

    return (
      <ResourceListItem
        item={item}
        primaryActions={item.action ? [item.action] : undefined}
        actions={item.actions}
      >
        <TextStyle variation="subdued" element="div">
          {item.title}
        </TextStyle>
        <div>{formattedValue}</div>
      </ResourceListItem>
    );
  };

  const renderInfo = () => {
    const cards: React.ReactNode[] = [];

    const {
      signedContractUrl,
      contactDateOfBirth,
      contactEmail,
      contactFirstname,
      contactLastname,
      contactMobileNumber,
      locale,
      propertyAdjoined,
      propertyFloor,
      propertyNumberOfFloors,
      propertySurfaceArea,
      propertyType,
      propertyValue,
      propertyValueCalculationMethod,
    } = lead;
    const isHouse = propertyType === EPropertyTypeId.House;
    const isApartment = propertyType === EPropertyTypeId.Apartment;

    const propertyItemsPrimary = [`${propertySurfaceArea} m²`];
    const propertyItemsSecondary = [];

    if (isHouse) {
      propertyItemsPrimary.push(
        getLocalizedText(
          `property.adjoined.option.${propertyAdjoined}.label`.toLowerCase(),
        ),
      );
    }

    if (isApartment) {
      propertyItemsSecondary.push(
        getLocalizedText("insurance.lead.detail.building_layers", {
          value: `${propertyNumberOfFloors}`,
        }),
      );
      propertyItemsSecondary.push(
        getLocalizedText("insurance.lead.detail.building_floor", {
          value: `${propertyFloor}`,
        }),
      );
    }

    const icon = isApartment
      ? assets.ICO_PROPERTY_APARTMENT
      : assets.ICO_PROPERTY_HOUSE;

    cards.push(
      <Card
        heading={renderCardHeader(
          getLocalizedText("insurance.lead.detail.property"),
        )}
        space={ESpacings.base}
      >
        {renderIconListItem({
          icon,
          title: formatAddress(lead),
          itemsPrimary: propertyItemsPrimary,
          itemsSecondary: propertyItemsSecondary,
        })}
      </Card>,
    );

    cards.push(
      <Card
        heading={renderCardHeader(
          getLocalizedText("insurance.lead.detail.contact"),
        )}
        space={ESpacings.base}
        actions={
          signedContractUrl
            ? [
                {
                  id: getLocalizedText(
                    "insurance.lead.detail.cta.view_contract",
                  ),
                  content: getLocalizedText(
                    "insurance.lead.detail.cta.view_contract",
                  ),
                  onClick: () => {
                    window.open(signedContractUrl, "_blank");
                  },
                },
              ]
            : undefined
        }
      >
        {renderIconListItem({
          icon: assets.ICO_PROFILE,
          title: `${contactFirstname} ${contactLastname}`,
          itemsPrimary: [
            contactEmail,
            formatPhone(contactMobileNumber) || contactMobileNumber,
          ].filter(Boolean),
          itemsSecondary: [contactDateOfBirth, locale].filter(
            Boolean,
          ) as string[],
        })}
      </Card>,
    );

    if (agreement) {
      const { connectiveSignedAt, connectiveSigningMethod, connectiveStatus } =
        agreement;

      const agreementItemsPrimary = [];
      if (connectiveSigningMethod) {
        agreementItemsPrimary.push(
          getLocalizedText(
            `used_signing_type.${connectiveSigningMethod}`.toLowerCase(),
          ),
        );
      } else {
        agreementItemsPrimary.push(getLocalizedText("system.not_signed"));
      }

      if (connectiveSignedAt) {
        agreementItemsPrimary.push(formatDate(connectiveSignedAt));
      }

      cards.push(
        <Card
          heading={renderCardHeader(
            getLocalizedText("insurance.lead.detail.agreement"),
          )}
          space={ESpacings.base}
        >
          {renderIconListItem({
            icon: assets.ICO_PROFILE,
            title: getLocalizedText(
              `insurance.agreement.connective_status.${connectiveStatus}`.toLowerCase(),
            ),
            itemsPrimary: agreementItemsPrimary,
          })}
        </Card>,
      );
    } else if (isFetchingAgreement) {
      cards.push(
        <Card
          heading={renderCardHeader(
            getLocalizedText("insurance.lead.detail.agreement"),
          )}
          space={ESpacings.base}
        >
          <Loading />
        </Card>,
      );
    }

    if (quote) {
      const {
        calculationValue,
        optionContentPremium,
        optionContentPremiumValue,
        optionContentPremiumAmountOfContent,
        optionTheftPremium,
        optionTheftPremiumValue,
        optionMotorVehiclesPremium,
        optionMotorVehiclesPremiumValue,
        optionMotorVehiclesPremiumAmountOfMotorVehicles,
        optionLegalAssistancePremium,
        optionLegalAssistancePremiumValue,
      } = quote;

      const basePrice = getQuoteBasePrice(quote);

      cards.push(
        <Card
          heading={renderCardHeader(
            getLocalizedText("insurance.lead.detail.options"),
          )}
          space={ESpacings.base}
        >
          <S.OptionsWrap>
            <S.OptionWrap>
              <S.OptionIconWrap>
                <S.OptionIcon
                  src={assets.ICO_HOUSEHOLD_EFFECTS}
                  isActive={optionContentPremium}
                />
                {optionContentPremium && (
                  <S.OptionIconCheck src={assets.ICO_CHECK} />
                )}
              </S.OptionIconWrap>
              <S.OptionName isActive={optionContentPremium}>
                {getLocalizedText("insurance.options.premium.content.title")}
              </S.OptionName>
            </S.OptionWrap>
            <S.OptionWrap>
              <S.OptionIconWrap>
                <S.OptionIcon
                  src={assets.ICO_THEFT}
                  isActive={optionTheftPremium}
                />
                {optionTheftPremium && (
                  <S.OptionIconCheck src={assets.ICO_CHECK} />
                )}
              </S.OptionIconWrap>
              <S.OptionName isActive={optionTheftPremium}>
                {getLocalizedText("insurance.options.premium.theft.title")}
              </S.OptionName>
            </S.OptionWrap>
            <S.OptionWrap>
              <S.OptionIconWrap>
                <S.OptionIcon
                  src={assets.ICO_CAR}
                  isActive={optionMotorVehiclesPremium}
                />
                {optionMotorVehiclesPremium && (
                  <S.OptionIconCheck src={assets.ICO_CHECK} />
                )}
              </S.OptionIconWrap>

              <S.OptionName isActive={optionMotorVehiclesPremium}>
                {getLocalizedText(
                  "insurance.options.premium.motor_vehicles.title",
                )}
              </S.OptionName>
            </S.OptionWrap>
            <S.OptionWrap>
              <S.OptionIconWrap>
                <S.OptionIcon
                  src={assets.ICO_LAW}
                  isActive={optionLegalAssistancePremium}
                />
                {optionLegalAssistancePremium && (
                  <S.OptionIconCheck src={assets.ICO_CHECK} />
                )}
              </S.OptionIconWrap>
              <S.OptionName isActive={optionLegalAssistancePremium}>
                {getLocalizedText(
                  "insurance.options.premium.legal_assistance.title",
                )}
              </S.OptionName>
            </S.OptionWrap>
          </S.OptionsWrap>
        </Card>,
      );

      cards.push(
        <Card
          heading={renderCardHeader(
            getLocalizedText("insurance.lead.detail.pricing"),
          )}
          space={ESpacings.base}
        >
          <ResourceList
            items={[
              {
                title: getLocalizedText("insurance.property_value"),
                value: `${formatCurrency(
                  propertyValue,
                )} (${propertyValueCalculationMethod})`,
              },
              {
                title: getLocalizedText("insurance.base.title"),
                value: formatCurrency(basePrice),
              },
              {
                title: getLocalizedText(
                  "insurance.options.premium.content.title",
                ),
                value:
                  optionContentPremium &&
                  optionContentPremiumValue &&
                  optionContentPremiumAmountOfContent
                    ? `${formatCurrency(
                        optionContentPremiumValue,
                      )} (${getLocalizedText(
                        "insurance.options.premium.content.content_amount",
                      )}: ${formatCurrency(
                        optionContentPremiumAmountOfContent,
                        false,
                      )})`
                    : getLocalizedText("insurance.options.not_insured"),
              },
              {
                title: getLocalizedText(
                  "insurance.options.premium.theft.title",
                ),
                value:
                  optionTheftPremium && optionTheftPremiumValue
                    ? formatCurrency(optionTheftPremiumValue)
                    : getLocalizedText("insurance.options.not_insured"),
              },
              {
                title: getLocalizedText(
                  "insurance.options.premium.motor_vehicles.title",
                ),
                value:
                  optionMotorVehiclesPremium &&
                  optionMotorVehiclesPremiumValue &&
                  optionMotorVehiclesPremiumAmountOfMotorVehicles
                    ? `${formatCurrency(
                        optionMotorVehiclesPremiumValue,
                      )} (${getLocalizedText(
                        "insurance.options.premium.motor_vehicles.motor_vehicles_amount",
                      )}: ${optionMotorVehiclesPremiumAmountOfMotorVehicles})`.toLowerCase()
                    : getLocalizedText("insurance.options.not_insured"),
              },
              {
                title: getLocalizedText(
                  "insurance.options.premium.legal_assistance.title",
                ),
                value:
                  optionLegalAssistancePremium &&
                  optionLegalAssistancePremiumValue
                    ? formatCurrency(optionLegalAssistancePremiumValue)
                    : getLocalizedText("insurance.options.not_insured"),
              },
              {
                title: getLocalizedText("system.total"),
                value: formatCurrency(calculationValue),
              },
            ]}
            renderItem={renderRow}
          />
        </Card>,
      );
    } else if (isFetchingQuote) {
      cards.push(
        <Card
          heading={renderCardHeader(
            getLocalizedText("insurance.lead.detail.options"),
          )}
          space={ESpacings.base}
        >
          <Loading />
        </Card>,
      );

      cards.push(
        <Card
          heading={renderCardHeader(
            getLocalizedText("insurance.lead.detail.pricing"),
          )}
          space={ESpacings.base}
        >
          <Loading />
        </Card>,
      );
    }

    cards.push(
      <Card
        heading={renderCardHeader(
          getLocalizedText("insurance.lead.update.options"),
        )}
        space={ESpacings.base}
      >
        <ResourceList
          items={[
            {
              title: "",
              value: getLocalizedText("insurance.lead.update.status"),
              actions: Object.values(ELeadStatus).map(status => ({
                id: status,
                content: getLocalizedText(
                  `insurance.lead.status.${stringToSnakeCase(
                    status,
                  )}`.toLowerCase(),
                ),
                onClick: async () => {
                  try {
                    const { data: updatedLead } = await leadApi.updateLead(
                      lead.id,
                      {
                        status,
                      },
                    );

                    storeUpdatedLead(updatedLead);

                    showAlert({
                      type: "success",
                      message: getLocalizedText("system.ok"),
                    });
                  } catch (unknownError) {
                    const error = unknownError as any;
                    showAlert({
                      type: "error",
                      error,
                    });
                  }
                },
              })),
            },
          ]}
          renderItem={renderRow}
        />
      </Card>,
    );

    return cards;
  };

  const renderActivities = () => {
    return (
      <Card
        space={ESpacings.base}
        heading={
          <DisplayText size="extraSmall" space="tight">
            {getLocalizedText("system.history")}
          </DisplayText>
        }
      >
        <LeadActivities leadId={lead.id} />
      </Card>
    );
  };

  let title = `${lead.contactFirstname} ${lead.contactLastname}`;
  if (quote) {
    title = `${title} - ${quote.publicCode}`;
  }

  return (
    <Page
      title={title}
      subtitle={<LeadStatusLozenge lead={lead} />}
      metadata={formatDate(lead.createdAt)}
      tabs={tabs()}
      setDocumentTitle={false}
      dropdown={[
        {
          content: getLocalizedText(
            "insurance.lead.detail.cta.view_as_customer",
          ),
          onClick: () => {
            const host =
              CONFIG.ENVIRONMENT === EEnvironment.Dev
                ? "dev.rentio-insurance.be"
                : "app.rentio-insurance.be";
            window.open(
              `https://${host}/?leadId=${lead.id}&publicId=${
                lead.publicId || "x"
              }&internalMode=1`,
              "_blank",
            );
          },
        },
        {
          content: getLocalizedText("insurance.lead.detail.cta.resend_invite"),
          onClick: async () => {
            try {
              await leadApi.resendInvite(lead.id);

              showAlert({
                type: "success",
                message: getLocalizedText(
                  "insurance.lead.detail.alert.success.title",
                  { email: lead.contactEmail },
                ),
              });
            } catch (unknownError) {
              const error = unknownError as any;
              showAlert({
                type: "error",
                error,
              });
            }
          },
        },
        {
          content: (
            <TextStyle variation="negative">
              {getLocalizedText("system.delete")}
            </TextStyle>
          ),
          onClick: async () => {
            try {
              await leadApi.updateLead(lead.id, {
                archivedAt: new Date(),
              });
            } catch (unknownError) {
              window.location.reload();
            }
          },
        },
      ]}
    >
      {tab === ELeadDetailTab.Info && renderInfo()}
      {tab === ELeadDetailTab.Activities && renderActivities()}
    </Page>
  );
};

// eslint-disable-next-line import/no-default-export
export default LeadDetail;
