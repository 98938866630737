import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box } from "@rebass/grid";
import { getFieldInfo, getFieldTitle, } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { ELocale } from "@rentiohq/shared-frontend/dist/types/i18n.types";
import { compareAsc } from "@rentiohq/shared-frontend/dist/utils/date-fns.utils";
import { getLocale } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
// eslint-disable-next-line no-restricted-imports
import { enUS as en, fr, nl } from "date-fns/locale";
import React from "react";
import { registerLocale } from "react-datepicker";
import { ts } from "../../../../services";
import { format } from "../../../../utils/date";
import { BaseDatePicker } from "../../../BaseDatePicker";
import { StyledHelpText } from "../../../Form/Form.styled";
import { Labelled } from "../../../Labelled";
import { Select } from "../../../Select";
import { Stack } from "../../../Stack";
import { defineStartYear, defineYearAmount } from "./DateField.util";
registerLocale(ELocale.NL, nl);
registerLocale(ELocale.FR, fr);
registerLocale(ELocale.EN, en);
var MONTH_NAMES = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(function (month) {
    return format(new Date("1/1/2000").setMonth(month), "MMMM");
});
export var DateField = function (_a) {
    var _b;
    var name = _a.name, placeholder = _a.placeholder, uiSchema = _a.uiSchema, idSchema = _a.idSchema, _c = _a.formData, formData = _c === void 0 ? null : _c, formContext = _a.formContext, _d = _a.rawErrors, rawErrors = _d === void 0 ? [] : _d, required = _a.required, onChange = _a.onChange;
    var initialDate = formData instanceof Date ? formData : new Date(formData);
    var _e = uiSchema.optional, optional = _e === void 0 ? false : _e;
    var _f = React.useState(formData ? initialDate : null), selected = _f[0], setSelected = _f[1];
    var title = getFieldTitle(uiSchema, formContext);
    var info = getFieldInfo(uiSchema, formContext);
    var error = rawErrors[0];
    var $id = idSchema.$id;
    var getDefaultDateFormat = function () {
        var format = "dd/MM/yyyy";
        if (hideDayPicker) {
            format = "MM/yyyy";
        }
        else if (showTimePicker) {
            format = "dd/MM/yyyy HH:mm";
        }
        return format;
    };
    var _g = uiSchema, minDateBase = _g.minDate, maxDateBase = _g.maxDate, showTimePicker = _g.showTimePicker, _h = _g.hideDayPicker, hideDayPicker = _h === void 0 ? false : _h, getMaxDate = _g.getMaxDate, getMinDate = _g.getMinDate, _j = _g.dateFormat, dateFormat = _j === void 0 ? getDefaultDateFormat() : _j, _k = _g.showMonthYearPicker, showMonthYearPicker = _k === void 0 ? false : _k, _l = _g.inverseYears, inverseYears = _l === void 0 ? false : _l;
    var pickerRef = React.useRef(null);
    var id = "".concat(name, "-").concat($id);
    var allFormData = formContext.getAllFormData();
    var isDisabled = ((_b = uiSchema.isDisabled) === null || _b === void 0 ? void 0 : _b.call(uiSchema, allFormData)) || false;
    var minDate = undefined;
    var maxDate = undefined;
    if (minDateBase) {
        minDate = minDateBase;
    }
    if (maxDateBase) {
        maxDate = maxDateBase;
    }
    if (getMinDate) {
        minDate = getMinDate(allFormData);
    }
    if (getMaxDate) {
        maxDate = getMaxDate(allFormData);
    }
    if (typeof minDate === "string") {
        minDate = new Date(minDate);
    }
    if (typeof maxDate === "string") {
        maxDate = new Date(maxDate);
    }
    React.useEffect(function () {
        if (formData === "open-picker") {
            handleIconClick();
            return;
        }
        setSelected(formData ? new Date(formData) : null);
    }, [formData]);
    var getPlaceholder = function () {
        if (placeholder)
            return placeholder;
        else
            return uiSchema.placeholder || ts.datePlaceholder(dateFormat);
    };
    var handleChange = function (input) {
        var compareMax = maxDate ? compareAsc(maxDate, input) : null;
        var compareMin = minDate ? compareAsc(minDate, input) : null;
        var isBeforeMin = compareMin ? compareMin > 0 : null;
        var isAfterMax = compareMax ? compareMax < 0 : null;
        // eslint-disable-next-line no-nested-ternary
        var validatedInput = isBeforeMin ? minDate : isAfterMax ? maxDate : input;
        if (!validatedInput) {
            setSelected(formData);
            return;
        }
        onChange(validatedInput);
        if (formContext.onFieldChange) {
            setTimeout(function () {
                formContext.onFieldChange(name, validatedInput);
            }, 0);
        }
    };
    var yearAmount = defineYearAmount({ minDate: minDate, maxDate: maxDate });
    var yearStart = defineStartYear({ minDate: minDate, maxDate: maxDate, yearAmount: yearAmount });
    var yearsArray = Array(yearAmount)
        .fill(0)
        .map(function (_, idx) { return yearStart + idx; });
    if (inverseYears) {
        yearsArray.reverse();
    }
    var handleClear = function () {
        setSelected(null);
        onChange(null);
        if (formContext.onFieldChange) {
            setTimeout(function () {
                formContext.onFieldChange(name, null);
            }, 0);
        }
    };
    var handleIconClick = function () {
        var _a;
        (_a = pickerRef.current) === null || _a === void 0 ? void 0 : _a.setOpen(true);
    };
    var handleMonthChange = function (month) {
        var newDate = selected || new Date();
        newDate.setMonth(month.value);
        handleChange(newDate);
    };
    var handleYearChange = function (year) {
        var newDate = selected || new Date();
        newDate.setFullYear(year.value);
        handleChange(newDate);
    };
    var fieldMarkup = showMonthYearPicker ? (_jsxs(Stack, { children: [_jsx(Box, { width: 200, children: _jsx(Select, { label: ts.system("month"), labelHidden: true, id: "month-picker", onChange: handleMonthChange, value: selected ? selected.getMonth() : undefined, error: error, options: MONTH_NAMES.map(function (label, value) { return ({
                        value: value,
                        label: label,
                        // isDisabled: selected && maxDate && isAfter(selected.setMonth(value), maxDate),
                    }); }), disabled: isDisabled }) }), _jsx(Box, { width: 100, children: _jsx(Select, { label: ts.system("year"), labelHidden: true, id: "month-picker", onChange: handleYearChange, value: selected ? selected.getFullYear() : undefined, options: yearsArray.map(function (year) { return ({
                        value: year,
                        label: String(year),
                    }); }), disabled: isDisabled }) })] })) : (_jsxs(_Fragment, { children: [_jsx(BaseDatePicker, { id: id, ref: pickerRef, placeholderText: getPlaceholder(), locale: getLocale(), inverseYears: inverseYears, selected: selected, minDate: minDate, maxDate: maxDate, onChange: handleChange, 
                // onMonthChange={handleChange}
                // onYearChange={handleChange}
                showMonthDropdown: true, showYearDropdown: true, dateFormat: dateFormat, dropdownMode: "select", showTimeSelect: !!showTimePicker, showMonthYearDropdown: true, autoComplete: "off", disabled: isDisabled, popperPlacement: "top-end", popperModifiers: [
                    {
                        name: "offset",
                        options: {
                            offset: [5, 10],
                        },
                    },
                    {
                        name: "preventOverflow",
                        options: {
                            rootBoundary: "viewport",
                            tether: false,
                            altAxis: true,
                        },
                    },
                ], hideDayPicker: hideDayPicker, hasError: error, isDisabled: isDisabled, required: required, handleClear: handleClear, handleIconClick: handleIconClick }), info && _jsx(StyledHelpText, { children: info })] }));
    var handlePressExtraInfo = function () {
        if (formContext.onPressExtraInfo) {
            formContext.onPressExtraInfo(uiSchema.extraInfoUrl);
        }
    };
    return (_jsx(_Fragment, { children: title ? (_jsx(Labelled, { label: title, error: error, optional: optional, onPressExtraInfo: uiSchema.extraInfoUrl && handlePressExtraInfo, children: fieldMarkup })) : (_jsx(_Fragment, { children: "fieldMarkup" })) }));
};
