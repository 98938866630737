import { CONFIG } from "@rentiohq/shared-frontend/dist/config/app.config";
import { EProduct } from "@rentiohq/shared-frontend/dist/config/app.config.types";
import { endOfDay, getDay, getHour, getMonth, getYear, startOfDay, startOfMonth, startOfWeek, startOfYear, subDays, subWeeks, subYears, } from "@rentiohq/shared-frontend/dist/utils/date-fns.utils";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { TABS_BACKOFFICE_RENTIO, TABS_BACKOFFICE_RENTIO_INSURANCE, } from "./DashboardStats.config";
import { EStatDuration, EStatInterval } from "./DashboardStats.types";
var NOW = new Date();
var START_OF_DAY = startOfDay(NOW);
export var generateIdentifier = function (params) {
    var entity = params.entity, from = params.from, to = params.to, interval = params.interval, scope = params.scope;
    return [entity, from, to, interval, scope].join("-");
};
export var getAllTabs = function () {
    if (CONFIG.PRODUCT === EProduct.InsuranceBackoffice) {
        return TABS_BACKOFFICE_RENTIO_INSURANCE;
    }
    return TABS_BACKOFFICE_RENTIO;
};
export var getAllCharts = function () {
    var charts = [];
    getAllTabs().forEach(function (tab) {
        charts.push.apply(charts, tab.charts);
    });
    return charts;
};
export var getTabCharts = function (tabName) { var _a; return (_a = getAllTabs().find(function (tab) { return tab.name === tabName; })) === null || _a === void 0 ? void 0 : _a.charts; };
export var statDurationToOption = function (input) { return ({
    value: input,
    label: getLocalizedText("stat.duration.".concat(input)),
}); };
export var statIntervalToOption = function (input) { return ({
    value: input,
    label: getLocalizedText("stat.interval.".concat(input)),
}); };
export var statEntityToOption = function (input) { return ({
    value: input,
    label: getLocalizedText("system.entity.".concat(input).replace(/[/-]/g, "_")),
}); };
export var statScopeToOption = function (input) { return ({
    value: input,
    label: getLocalizedText("stat.scope.".concat(input)),
}); };
export var getFrom = function (statDuration) {
    switch (statDuration) {
        case EStatDuration.Today:
            return START_OF_DAY;
        case EStatDuration.ThisWeek:
            return startOfWeek(START_OF_DAY);
        case EStatDuration.Last7Days:
            return subWeeks(START_OF_DAY, 1);
        case EStatDuration.ThisMonth:
            return startOfMonth(START_OF_DAY);
        case EStatDuration.Last30Days:
            return subDays(START_OF_DAY, 30);
        case EStatDuration.ThisYear:
            return startOfYear(START_OF_DAY);
        case EStatDuration.Last365Days:
            return subYears(START_OF_DAY, 1);
        case EStatDuration.All:
            return new Date(2019, 0, 1); // Very beginning of Rentio
        default:
            return START_OF_DAY;
    }
};
export var getTo = function (_) { return endOfDay(NOW); };
export var formatFrom = function (from, statInterval) {
    switch (statInterval) {
        case EStatInterval.Hourly:
            return "".concat(getHour(from), "u");
        case EStatInterval.Daily:
            return "".concat(getDay(from), "/").concat(getMonth(from) + 1);
        case EStatInterval.Weekly:
            return "".concat(getDay(from), "/").concat(getMonth(from) + 1);
        case EStatInterval.Monthly:
            return "".concat(getMonth(from) + 1, "/").concat(getYear(from));
        case EStatInterval.Yearly:
            return "".concat(getYear(from));
        default:
            formatDate(from);
    }
};
// TODO Clean up unused lokalise keys like: follow_up.rent_deposits.stage.*
// TODO Clean up unused lokalise keys like: payment.payment_order.type.*
