var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import api from "@rentiohq/shared-frontend/dist/utils/api/api.utils";
import { append } from "@rentiohq/shared-frontend/dist/utils/api.utils";
import { mapObjectWithTimeZonedDatesToUtcIsoString } from "@rentiohq/shared-frontend/dist/utils/date.utils";
export var getStats = function (params) {
    var entity = params.entity, parameters = __rest(params, ["entity"]);
    return api.get(append("/system-stats/".concat(entity), {
        parameters: mapObjectWithTimeZonedDatesToUtcIsoString(parameters, true),
    }));
};
