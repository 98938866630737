var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { onMultiple } from "../../redux/utils/reducer.utils";
import { EPaymentRequestFollowUpType } from "../../types/paymentRequest.types";
import { generateReducer, } from "../generators/generator.reducer";
import { getPaymentLinkFailed, getPaymentLinkStart, getPaymentLinkSucceeded, grantAccessPaymentRequestFailed, grantAccessPaymentRequestStart, grantAccessPaymentRequestSucceeded, markPaidFailed, markPaidStart, markPaidSucceeded, reminderChatAppFailed, reminderChatAppStart, reminderChatAppSucceeded, reminderMailFailed, reminderMailStart, reminderMailSucceeded, reminderPhoneFailed, reminderPhoneStart, reminderPhoneSucceeded, reminderRegisteredMailFailed, reminderRegisteredMailStart, reminderRegisteredMailSucceeded, reminderSmsFailed, reminderSmsStart, reminderSmsSucceeded, sendPaymentRequestInvitationFailed, sendPaymentRequestInvitationStart, sendPaymentRequestInvitationSucceeded, } from "./paymentRequest.actions";
import { GENERATOR_PARAMS } from "./paymentRequest.constants";
var getExtraDefaultState = function () { return ({
    markPaid: {},
    paymentLink: {},
    grantAccess: {},
    sendInvitation: {},
    reminder: {},
}); };
var _reducer = generateReducer(GENERATOR_PARAMS, getExtraDefaultState);
var reducer = _reducer;
reducer.on(markPaidStart.type, function (state, payload) {
    var _a;
    return __assign(__assign({}, state), { markPaid: __assign(__assign({}, state.markPaid), (_a = {}, _a[payload.id] = {
            isMarkingPaid: true,
            markPaidError: undefined,
        }, _a)) });
});
reducer.on(markPaidSucceeded.type, function (state, payload) {
    var _a;
    return __assign(__assign({}, state), { markPaid: __assign(__assign({}, state.markPaid), (_a = {}, _a[payload.id] = {
            isMarkingPaid: false,
            markPaidError: undefined,
        }, _a)) });
});
reducer.on(markPaidFailed.type, function (state, payload) {
    var _a;
    return __assign(__assign({}, state), { markPaid: __assign(__assign({}, state.markPaid), (_a = {}, _a[payload.id] = {
            isMarkingPaid: false,
            markPaidError: payload.error,
        }, _a)) });
});
reducer.on(getPaymentLinkStart.type, function (state, payload) {
    var _a;
    return __assign(__assign({}, state), { paymentLink: __assign(__assign({}, state.paymentLink), (_a = {}, _a[payload.id] = {
            isFetching: true,
        }, _a)) });
});
reducer.on(getPaymentLinkSucceeded.type, function (state, payload) {
    var _a;
    return __assign(__assign({}, state), { paymentLink: __assign(__assign({}, state.paymentLink), (_a = {}, _a[payload.id] = {
            isFetching: true,
            url: payload === null || payload === void 0 ? void 0 : payload.result,
        }, _a)) });
});
reducer.on(getPaymentLinkFailed.type, function (state, payload) {
    var _a;
    return __assign(__assign({}, state), { paymentLink: __assign(__assign({}, state.paymentLink), (_a = {}, _a[payload.id] = {
            isFetching: false,
            fetchError: payload.error,
        }, _a)) });
});
reducer.on(grantAccessPaymentRequestStart.type, function (state, payload) {
    var _a;
    return __assign(__assign({}, state), { grantAccess: __assign(__assign({}, state.grantAccess), (_a = {}, _a[payload.id] = {
            isFetching: true,
            fetchError: undefined,
        }, _a)) });
});
reducer.on(grantAccessPaymentRequestSucceeded.type, function (state, payload) {
    var _a, _b;
    return __assign(__assign({}, state), { detail: __assign(__assign({}, state.detail), (_a = {}, _a[payload.id] = {
            pending: false,
            entity: payload === null || payload === void 0 ? void 0 : payload.result,
            error: undefined,
        }, _a)), grantAccess: __assign(__assign({}, state.grantAccess), (_b = {}, _b[payload.id] = {
            isFetching: false,
        }, _b)) });
});
reducer.on(grantAccessPaymentRequestFailed.type, function (state, payload) {
    var _a;
    return __assign(__assign({}, state), { grantAccess: __assign(__assign({}, state.grantAccess), (_a = {}, _a[payload.id] = {
            isFetching: false,
            fetchError: payload.error,
        }, _a)) });
});
reducer.on(sendPaymentRequestInvitationStart.type, function (state, payload) {
    var _a;
    return __assign(__assign({}, state), { sendInvitation: __assign(__assign({}, state.sendInvitation), (_a = {}, _a[payload.id] = {
            isFetching: true,
            fetchError: undefined,
        }, _a)) });
});
reducer.on(sendPaymentRequestInvitationSucceeded.type, function (state, payload) {
    var _a;
    return __assign(__assign({}, state), { sendInvitation: __assign(__assign({}, state.sendInvitation), (_a = {}, _a[payload.id] = {
            isFetching: false,
            fetchError: undefined,
        }, _a)) });
});
reducer.on(sendPaymentRequestInvitationFailed.type, function (state, payload) {
    var _a;
    return __assign(__assign({}, state), { sendInvitation: __assign(__assign({}, state.sendInvitation), (_a = {}, _a[payload.id] = {
            isFetching: false,
            fetchError: payload.error,
        }, _a)) });
});
var getFollowUpTypeFromAction = function (action) {
    switch (action) {
        case reminderSmsStart.type:
        case reminderSmsSucceeded.type:
        case reminderSmsFailed.type:
            return EPaymentRequestFollowUpType.Sms;
        case reminderPhoneStart.type:
        case reminderPhoneSucceeded.type:
        case reminderPhoneFailed.type:
            return EPaymentRequestFollowUpType.Phone;
        case reminderMailStart.type:
        case reminderMailSucceeded.type:
        case reminderMailFailed.type:
            return EPaymentRequestFollowUpType.Mail;
        case reminderChatAppStart.type:
        case reminderChatAppSucceeded.type:
        case reminderChatAppFailed.type:
            return EPaymentRequestFollowUpType.App;
        case reminderRegisteredMailStart.type:
        case reminderRegisteredMailSucceeded.type:
        case reminderRegisteredMailFailed.type:
            return EPaymentRequestFollowUpType.RegisteredMail;
        default:
            return;
    }
};
onMultiple(reducer, [
    reminderSmsStart.type,
    reminderPhoneStart.type,
    reminderMailStart.type,
    reminderChatAppStart.type,
    reminderRegisteredMailStart.type,
], function (state, payload, action) {
    var _a, _b;
    var followUpType = getFollowUpTypeFromAction(action);
    if (!followUpType) {
        return state;
    }
    return __assign(__assign({}, state), { reminder: __assign(__assign({}, state.reminder), (_a = {}, _a[payload.id] = (_b = {},
            _b[followUpType] = {
                isFetching: true,
                fetchError: undefined,
            },
            _b), _a)) });
});
onMultiple(reducer, [
    reminderSmsSucceeded.type,
    reminderPhoneSucceeded.type,
    reminderMailSucceeded.type,
    reminderChatAppSucceeded.type,
    reminderRegisteredMailSucceeded.type,
], function (state, payload, action) {
    var _a, _b;
    var followUpType = getFollowUpTypeFromAction(action);
    if (!followUpType) {
        return state;
    }
    return __assign(__assign({}, state), { reminder: __assign(__assign({}, state.reminder), (_a = {}, _a[payload.id] = (_b = {},
            _b[followUpType] = {
                isFetching: false,
                fetchError: undefined,
            },
            _b), _a)) });
});
onMultiple(reducer, [
    reminderSmsFailed.type,
    reminderPhoneFailed.type,
    reminderMailFailed.type,
    reminderChatAppFailed.type,
    reminderRegisteredMailFailed.type,
], function (state, payload, action) {
    var _a, _b;
    var followUpType = getFollowUpTypeFromAction(action);
    if (!followUpType) {
        return state;
    }
    return __assign(__assign({}, state), { reminder: __assign(__assign({}, state.reminder), (_a = {}, _a[payload.id] = (_b = {},
            _b[followUpType] = {
                isFetching: false,
                fetchError: payload.error,
            },
            _b), _a)) });
});
// eslint-disable-next-line import/no-default-export
export default reducer;
