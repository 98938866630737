var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { createElement as _createElement } from "react";
import { jsx as _jsx } from "react/jsx-runtime";
// @ts-nocheck
import { Box, Flex } from "@rebass/grid";
import logger from "@rentiohq/shared-frontend/dist/logger";
import ObjectField from "react-jsonschema-form/lib/components/fields/ObjectField";
import { retrieveSchema } from "react-jsonschema-form/lib/utils";
import { ESpacings } from "../../../Grid";
var GridField = /** @class */ (function (_super) {
    __extends(GridField, _super);
    function GridField() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.renderRow = function (gridSchema) {
            var rows = gridSchema["row"];
            return (_jsx(Flex, { flexWrap: "wrap", mt: -ESpacings.base, ml: -ESpacings.base, children: _this.renderChildren(rows) }));
        };
        _this.renderCol = function (gridSchema) {
            var _a = gridSchema["col"], children = _a.children, colProps = __rest(_a, ["children"]);
            return (_jsx(Box, __assign({ Reflex: true, pt: ESpacings.base, pl: ESpacings.base }, colProps, { children: _this.renderChildren(children) })));
        };
        _this.renderChildren = function (childrenGridSchema) {
            var definitions = _this.props.registry.definitions;
            var schema = retrieveSchema(_this.props.schema, definitions);
            return childrenGridSchema.map(function (gridSchema, index) { return (
            // <div key={index}>Field</div>
            _createElement(GridField, __assign({}, _this.props, { key: index, schema: schema, gridSchema: gridSchema }))); });
        };
        _this.renderField = function (gridSchema) {
            var _a = _this.props, uiSchema = _a.uiSchema, errorSchema = _a.errorSchema, idSchema = _a.idSchema, disabled = _a.disabled, readonly = _a.readonly, onBlur = _a.onBlur, onFocus = _a.onFocus, formData = _a.formData;
            var _b = _this.props.registry, definitions = _b.definitions, fields = _b.fields;
            var SchemaField = fields.SchemaField;
            var schema = retrieveSchema(_this.props.schema, definitions);
            var name;
            var render;
            if (typeof gridSchema === "string") {
                name = gridSchema;
            }
            else {
                (name = gridSchema.name);
                (render = gridSchema.render);
            }
            if (schema.properties[name]) {
                return (_jsx(SchemaField, { name: name, required: _this.isRequired(name), schema: schema.properties[name], uiSchema: uiSchema[name], errorSchema: errorSchema[name], idSchema: idSchema[name], formData: formData[name], onChange: _this.onPropertyChange(name), onBlur: onBlur, onFocus: onFocus, registry: _this.props.registry, disabled: disabled, readonly: readonly }, name));
            }
            else {
                var UIComponent = render || (function () { return null; });
                return (_jsx(UIComponent, { name: name, formData: formData, errorSchema: errorSchema, uiSchema: uiSchema, schema: schema, registry: _this.props.registry }, name));
            }
        };
        return _this;
    }
    GridField.prototype.render = function () {
        var uiSchema = this.props.uiSchema;
        var gridSchema = this.props.gridSchema;
        if (!gridSchema)
            gridSchema = uiSchema["grid"];
        if (!gridSchema) {
            logger.logError({
                error: new Error('You must define "ui:grid" in your ui schema when using GridField.'),
            });
            return null;
        }
        if (gridSchema["row"]) {
            return this.renderRow(gridSchema);
        }
        else if (gridSchema["col"]) {
            return this.renderCol(gridSchema);
        }
        else {
            return this.renderField(gridSchema);
        }
    };
    return GridField;
}(ObjectField));
export { GridField };
