var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Spacer, { ESpacerWeight, } from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import { CONFIG } from "@rentiohq/shared-frontend/dist/config/app.config";
import { useQueryParams } from "@rentiohq/shared-frontend/dist/hooks/useQueryParams";
import { getFieldInfo, getFieldTitle, } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import * as documentsApi from "@rentiohq/shared-frontend/dist/reduxV2/documents/document.api";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import uniqBy from "lodash/uniqBy";
import React from "react";
import { StringParam } from "serialize-query-params";
import { Icon, Labelled, TextStyle } from "../../../../components";
import { AutocompleteField } from "../../../../components/AutocompleteField";
import { StyledHelpText } from "../../../../components/Form/Form.styled";
import { getTypeData } from "../../../../utils/document";
import { ResourceList, ResourceListItem } from "../../../ResourceList";
import { UploadField } from "../../../UploadField";
import { InternalDocumentListItem } from "./InternalDocumentListItem";
export var FileField = function (_a) {
    var name = _a.name, uiSchema = _a.uiSchema, idSchema = _a.idSchema, _b = _a.formData, formData = _b === void 0 ? [] : _b, formContext = _a.formContext, _c = _a.rawErrors, rawErrors = _c === void 0 ? [] : _c, required = _a.required, onChange = _a.onChange, _title = _a.title;
    var _d = useQueryParams({
        documentId: StringParam,
    }), setQueryParamValue = _d[1];
    // Properties from schema
    // @ts-ignore TODO: Typescript upgrade
    var title = _title !== null && _title !== void 0 ? _title : getFieldTitle(uiSchema, formContext);
    // @ts-ignore TODO: Typescript upgrade
    var info = getFieldInfo(uiSchema, formContext);
    var error = rawErrors[0];
    var _e = uiSchema.asDropzone, asDropzone = _e === void 0 ? true : _e, _f = uiSchema.multiple, multiple = _f === void 0 ? false : _f, maxFileSize = uiSchema.maxFileSize, _g = uiSchema.accept, accept = _g === void 0 ? "*" : _g, mimeTypes = uiSchema.mimeTypes, fileName = uiSchema.fileName, getExistingDocumentsWhereFilter = uiSchema.getExistingDocumentsWhereFilter, isDisabled = uiSchema.isDisabled, _h = uiSchema.optional, optional = _h === void 0 ? false : _h, _j = uiSchema.useExistingDocuments, useExistingDocuments = _j === void 0 ? false : _j, _k = uiSchema.useNewDocuments, useNewDocuments = _k === void 0 ? true : _k, _l = uiSchema.showOptionsOnFirstFocus, showOptionsOnFirstFocus = _l === void 0 ? false : _l;
    var disabled = isDisabled
        ? isDisabled(formContext.getAllFormData())
        : undefined;
    var existingDocumentsWhereFilter = (getExistingDocumentsWhereFilter === null || getExistingDocumentsWhereFilter === void 0 ? void 0 : getExistingDocumentsWhereFilter(formContext.getAllFormData())) || {};
    // State
    var _m = React.useState([]), files = _m[0], setFiles = _m[1];
    var _o = React.useState(), renderedOptions = _o[0], setRenderedOptions = _o[1];
    // Lifecycle
    React.useEffect(function () {
        if (typeof formData === "undefined") {
            setFiles([]);
            return;
        }
        Array.isArray(formData) ? setFiles(formData) : setFiles([formData]);
    }, []);
    React.useEffect(function () {
        var loadFiles = function () { return __awaiter(void 0, void 0, void 0, function () {
            var renderedOptions;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetchAndCreateOptions()];
                    case 1:
                        renderedOptions = _a.sent();
                        setRenderedOptions(renderedOptions);
                        return [2 /*return*/];
                }
            });
        }); };
        if (showOptionsOnFirstFocus)
            loadFiles();
    }, []);
    // Event handlers
    var handleChange = function (newValue) {
        if (newValue === void 0) { newValue = []; }
        setFiles(newValue);
        var formattedValue = newValue.length > 0 ? newValue : undefined;
        onChange(formattedValue);
        if (formContext.onFieldChange) {
            setTimeout(function () {
                formContext.onFieldChange(name, formattedValue);
            }, 0);
        }
    };
    var handlePressExtraInfo = function () {
        if (formContext.onPressExtraInfo) {
            formContext.onPressExtraInfo(uiSchema.extraInfoUrl);
        }
    };
    // Fetch data
    var fetchAndCreateOptions = function (query) { return __awaiter(void 0, void 0, void 0, function () {
        var search, extraFilterData, dataItems, fetchedDocuments, renderedOptions;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    search = query && query.length > 0 ? query : undefined;
                    extraFilterData = __assign({}, existingDocumentsWhereFilter);
                    if (mimeTypes) {
                        extraFilterData.mimeType = {
                            in: mimeTypes,
                        };
                    }
                    return [4 /*yield*/, documentsApi.getAllWhere({
                            query: {
                                filter: extraFilterData,
                                search: search,
                                page: 1,
                                limit: CONFIG.DEFAULT_FETCH_LIMIT,
                            },
                        })];
                case 1:
                    dataItems = (_a.sent()).data.data;
                    fetchedDocuments = dataItems.items;
                    renderedOptions = fetchedDocuments.map(function (document) { return ({
                        data: document.id,
                        paddingOverride: 0,
                        content: (_jsx(InternalDocumentListItem, { item: document.id, files: files, onChangeHandler: handleChange, setQueryParamValue: setQueryParamValue, showActions: false })),
                    }); });
                    return [2 /*return*/, renderedOptions];
            }
        });
    }); };
    // Render
    var renderDocument = function (item) {
        var _a;
        // Local file
        if (typeof item === "object") {
            return (_jsx(ResourceListItem, { item: item, media: _jsx(Icon, { source: getTypeData(item.type || item.mimeType || "").icon, size: "mediumLarge" }), mediaSize: "mediumLarge", boxProps: { p: 2 }, 
                // variation={validateContact ? "warning" : undefined}
                actions: [
                    {
                        media: _jsx(Icon, { source: "bin", color: "red" }),
                        content: (_jsx(TextStyle, { variation: "negative", children: getLocalizedText("system.remove") })),
                        onClick: function () {
                            handleChange(__spreadArray([], files, true).filter(function (x) { return x.name !== item.name; }));
                        },
                    },
                ], children: _jsx("div", { children: (_a = item.name) !== null && _a !== void 0 ? _a : item.filename }) }));
        }
        // Document id
        if (typeof item === "string") {
            return (_jsx(InternalDocumentListItem, { item: item, files: files, onChangeHandler: handleChange, setQueryParamValue: setQueryParamValue }));
        }
        return null;
    };
    var renderContent = function () {
        return (_jsxs(_Fragment, { children: [files.length > 0 && (_jsxs(_Fragment, { children: [_jsx(ResourceList, { items: files, renderItem: renderDocument, hasLastSeparator: false }), _jsx(Spacer, { weight: ESpacerWeight.W08 })] })), useExistingDocuments && (_jsxs(_Fragment, { children: [_jsx(AutocompleteField, { initialOptions: showOptionsOnFirstFocus ? renderedOptions : undefined, clearAfterChange: true, 
                            // label={label}
                            placeholder: getLocalizedText("file.documents.search.placeholder"), name: "document-search", loadOptions: function (query) { return __awaiter(void 0, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    if (query)
                                        return [2 /*return*/, fetchAndCreateOptions(query)];
                                    return [2 /*return*/, files];
                                });
                            }); }, onChange: function (documentId) {
                                if (multiple) {
                                    handleChange(uniqBy(__spreadArray(__spreadArray([], files, true), [documentId], false), function (x) {
                                        if (typeof x === "object") {
                                            return x.name;
                                        }
                                        return x;
                                    }));
                                    return;
                                }
                                handleChange([documentId]);
                            }, error: error, errorMessageHidden: true, optional: optional, autoFocus: false, renderId: 0, createOptions: [] }), _jsx(Spacer, { weight: ESpacerWeight.W08 })] })), useNewDocuments && (_jsx(UploadField, { id: name, name: name, asDropzone: asDropzone, multiple: multiple, maxFileSize: maxFileSize, accept: accept, fileName: fileName, onChange: function (newFiles) {
                        if (multiple) {
                            handleChange(uniqBy(__spreadArray(__spreadArray([], files, true), newFiles, true), function (x) {
                                if (typeof x === "object") {
                                    return x.name;
                                }
                                return x;
                            }));
                            return;
                        }
                        handleChange(newFiles);
                    }, value: files, required: required, disabled: disabled, error: error }))] }));
    };
    return (_jsx(_Fragment, { children: title ? (_jsxs(Labelled, { label: title, error: error, optional: optional, onPressExtraInfo: uiSchema.extraInfoUrl && handlePressExtraInfo, children: [renderContent(), info && _jsx(StyledHelpText, { children: info })] })) : (_jsxs(_Fragment, { children: [renderContent(), info && _jsx(StyledHelpText, { children: info })] })) }));
};
