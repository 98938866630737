import { ACTIVITY_TYPES } from "@rentiohq/shared-frontend/dist/types/activity.types";
import { addDays, startOfDay, subMonths, } from "@rentiohq/shared-frontend/dist/utils/date-fns.utils";
import { endOfMonth, startOfMonth, } from "@rentiohq/shared-frontend/dist/utils/date-fns.utils";
import { EField } from "./schema.exportActivities.types";
var TOMORROW = addDays(new Date(), 1);
var START_OF_DAY = startOfDay(new Date());
export var getInitialValues = function (extraData) {
    var _a;
    var selectedTypes = extraData.selectedTypes, selectedDateRange = extraData.selectedDateRange, lastLoadedActivity = extraData.lastLoadedActivity;
    var types = (selectedTypes === null || selectedTypes === void 0 ? void 0 : selectedTypes.map(function (type) { return ACTIVITY_TYPES[type]; })) || [];
    if (types.length === 0) {
        types = Object.values(ACTIVITY_TYPES);
    }
    var from = subMonths(START_OF_DAY, 1);
    if (selectedDateRange === null || selectedDateRange === void 0 ? void 0 : selectedDateRange.start) {
        from = startOfMonth(selectedDateRange.start);
    }
    else if (lastLoadedActivity) {
        from = lastLoadedActivity.datetime;
    }
    var to = TOMORROW;
    if (selectedDateRange === null || selectedDateRange === void 0 ? void 0 : selectedDateRange.end) {
        to = endOfMonth(selectedDateRange.end);
    }
    return _a = {},
        _a[EField.Types] = types,
        _a[EField.From] = from,
        _a[EField.To] = to,
        _a;
};
