import Spacer, {
  ESpacerWeight,
} from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import { CONFIG } from "@rentiohq/shared-frontend/dist/config/app.config";
import { useDebounce } from "@rentiohq/shared-frontend/dist/hooks/useDebounce";
import * as leadActions from "@rentiohq/shared-frontend/dist/reduxInsurance/lead/lead.actions";
import * as leadSelectors from "@rentiohq/shared-frontend/dist/reduxInsurance/lead/lead.selectors";
import {
  ELeadStatus,
  ILead,
} from "@rentiohq/shared-frontend/dist/types/insurance.lead.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { stringToSnakeCase } from "@rentiohq/shared-frontend/dist/utils/string.utils";
import {
  Card,
  Drawer,
  Filters,
  Loading,
  OptionListShared,
  Page,
  Pagination,
  ResourceList,
} from "@rentiohq/web-shared/dist/components";
import * as systemActions from "@rentiohq/web-shared/dist/redux/system/system.actions";
import * as systemSelectors from "@rentiohq/web-shared/dist/redux/system/system.selectors";
import { EPreferencePersistScope } from "@rentiohq/web-shared/dist/redux/system/system.types";
import LeadDetail from "modules/general/components/LeadDetail";
import LeadListItemHeader, {
  ESortField,
  ESortMethod,
} from "modules/general/components/LeadListItem/LeadListItem.header";
import hash from "object-hash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { IRootState } from "redux/reducers";
import LeadListItem from "../../components/LeadListItem";

const PREFERENCE_KEY_LEADS_PAGE = "LEADS_PAGE";
const PREFERENCE_KEY_LEADS_STATUSES = "LEADS_STATUSES";
const PREFERENCE_KEY_LEADS_QUERY = "LEADS_QUERY";
const PREFERENCE_KEY_LEADS_SORT_FIELD = "LEADS_SORT_FIELD";
const PREFERENCE_KEY_LEADS_SORT_METHOD = "LEADS_SORT_METHOD";

interface IProps {}

const Leads: React.FC<IProps> = () => {
  const { leadId } = useParams<{ leadId: string }>();
  const navigate = useNavigate();

  // Redux
  const dispatch = useDispatch();

  const page = useSelector(
    (state: IRootState) =>
      systemSelectors.getPreference<number>(state, PREFERENCE_KEY_LEADS_PAGE) ||
      1,
  );
  const statuses = useSelector((state: IRootState) =>
    systemSelectors.getPreference<ELeadStatus[]>(
      state,
      PREFERENCE_KEY_LEADS_STATUSES,
    ),
  );
  const _query = useSelector(
    (state: IRootState) =>
      systemSelectors.getPreference<string>(
        state,
        PREFERENCE_KEY_LEADS_QUERY,
      ) || "",
  );
  const [queryDebounced] = useDebounce(_query);

  const sortField = useSelector((state: IRootState) =>
    systemSelectors.getPreference<ESortField>(
      state,
      PREFERENCE_KEY_LEADS_SORT_FIELD,
    ),
  );

  const sortMethod = useSelector((state: IRootState) =>
    systemSelectors.getPreference<ESortMethod>(
      state,
      PREFERENCE_KEY_LEADS_SORT_METHOD,
    ),
  );

  const getIdentifier = React.useCallback(() => {
    let result = "all";

    if (statuses && statuses.length > 0) {
      result = `${result}-${statuses.join(",")}`;
    }

    if (sortField) {
      result = `${result}-${sortField}`;
    }

    if (sortMethod) {
      result = `${result}-${sortMethod}`;
    }

    if (queryDebounced.length > 0) {
      result = `search-${hash(queryDebounced)}`;
    }

    return result.toLowerCase();
  }, [queryDebounced, sortField, sortMethod, statuses]);

  const getFilter = React.useCallback(() => {
    const filter: { [key: string]: string } = {};

    if (queryDebounced.length > 0) {
      filter.search = queryDebounced;
    }

    if (statuses && statuses.length > 0) {
      filter.statuses = statuses.join(",");
    }

    if (sortField) {
      filter.sort = sortField;
      filter.sortMethod = sortMethod || ESortMethod.Descending;
    }

    return filter;
  }, [queryDebounced, sortField, sortMethod, statuses]);

  const leads = useSelector((state: IRootState) =>
    leadSelectors.getLeadsForPage(state, getIdentifier(), page),
  );
  const isFetching = useSelector((state: IRootState) =>
    leadSelectors.isFetchingLeadsForPage(state, getIdentifier(), page),
  );
  const fetchError = useSelector((state: IRootState) =>
    leadSelectors.leadsForPageFetchError(state, getIdentifier(), page),
  );
  const count = useSelector((state: IRootState) =>
    leadSelectors.leadsCount(state, getIdentifier()),
  );

  // Helpers
  const setPage = (newPage: number) => {
    dispatch(
      systemActions.setPreferences({
        persistScope: EPreferencePersistScope.LocalStorage,
        preferences: {
          [PREFERENCE_KEY_LEADS_PAGE]: newPage,
        },
      }),
    );
  };

  const setStatuses = (newStatuses?: ELeadStatus[]) => {
    dispatch(
      systemActions.setPreferences({
        persistScope: EPreferencePersistScope.LocalStorage,
        preferences: {
          [PREFERENCE_KEY_LEADS_STATUSES]: newStatuses,
        },
      }),
    );
  };

  const setQuery = (newQuery: string) => {
    dispatch(
      systemActions.setPreferences({
        persistScope: EPreferencePersistScope.LocalStorage,
        preferences: {
          [PREFERENCE_KEY_LEADS_QUERY]: newQuery,
        },
      }),
    );
  };

  // Lifecycle
  React.useEffect(() => {
    dispatch(
      leadActions.getLeadsPaged.actions.start({
        identifier: getIdentifier(),
        page,
        filterData: getFilter(),
      }),
    );
  }, [getFilter, getIdentifier, page]);

  // Event handlers
  const handleChangeStatuses = (newStatuses: string | string[]) => {
    if (typeof newStatuses === "string") {
      return;
    }

    setPage(1);
    setStatuses(newStatuses as ELeadStatus[]);
  };

  const handleQueryChange = (newQuery: string) => {
    setPage(1);
    setQuery(newQuery);
  };

  const handleQueryClear = () => {
    setQuery("");
  };

  const handleChangeSort = (
    sortField?: ESortField,
    sortMethod?: ESortMethod,
  ) => {
    dispatch(
      systemActions.setPreferences({
        persistScope: EPreferencePersistScope.LocalStorage,
        preferences: {
          [PREFERENCE_KEY_LEADS_SORT_FIELD]: sortField,
          [PREFERENCE_KEY_LEADS_SORT_METHOD]: sortMethod,
        },
      }),
    );
  };

  const handlePageClick = ({ selected }: { selected: number }) => {
    setPage(selected + 1);
  };

  const handleCloseDetail = () => {
    navigate("/leads");
  };

  // Render
  const renderLeadListItem = (lead: ILead) => <LeadListItem lead={lead} />;

  const renderContent = () => {
    if (leads) {
      return (
        <>
          <LeadListItemHeader
            onChangeSort={handleChangeSort}
            sortField={sortField}
            sortMethod={sortMethod}
          />
          <ResourceList
            items={leads}
            renderItem={renderLeadListItem}
            noResultsHeading={getLocalizedText(
              "insurance.backoffice.leads.empty",
            )}
          />
        </>
      );
    }

    if (isFetching) {
      return (
        <>
          <LeadListItemHeader
            onChangeSort={handleChangeSort}
            sortField={sortField}
            sortMethod={sortMethod}
          />
          <Loading />
        </>
      );
    }

    if (fetchError) {
      return (
        <Card.Section hasBorder={false}>
          <p>{getLocalizedText("fetch.error")}</p>
        </Card.Section>
      );
    }
  };

  const renderPaging = () => {
    if (!count || count === 0) {
      return;
    }

    const numberOfPages = Math.ceil(count / CONFIG.DEFAULT_FETCH_LIMIT);
    if (numberOfPages <= 1) {
      return;
    }

    return (
      <Pagination
        initialPage={page - 1}
        pageCount={numberOfPages}
        onPageChange={handlePageClick}
      />
    );
  };

  return (
    <Page
      title={getLocalizedText("insurance.backoffice.leads.title")}
      fullWidth
    >
      <Card>
        <Filters
          onQueryChange={handleQueryChange}
          onQueryClear={handleQueryClear}
          queryValue={_query}
          queryPlaceholder={getLocalizedText(
            "insurance.backoffice.leads.search",
          )}
          filters={[
            {
              key: "status",
              label: getLocalizedText("insurance.lead.status"),
              content: (
                <OptionListShared
                  id="stage"
                  value={statuses}
                  variant="simple"
                  multiple={true}
                  options={Object.keys(ELeadStatus).map((status: string) => ({
                    label: getLocalizedText(
                      `insurance.lead.status.${stringToSnakeCase(
                        status,
                      )}`.toLowerCase(),
                    ),
                    value: status,
                    id: status,
                  }))}
                  onChange={handleChangeStatuses}
                />
              ),
            },
          ]}
        />
        <Spacer weight={ESpacerWeight.W24} />
        {renderContent()}
        {renderPaging()}
      </Card>
      <Drawer
        isOpen={!!leadId}
        onClose={handleCloseDetail}
        width="half"
        position="right"
        isFullWidth={true}
      >
        {leadId && <LeadDetail leadId={leadId} />}
      </Drawer>
    </Page>
  );
};

// eslint-disable-next-line import/no-default-export
export default Leads;
