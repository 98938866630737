import activitySaga from "@rentiohq/shared-frontend/dist/redux/activity/activity.saga";
import authSaga from "@rentiohq/shared-frontend/dist/redux/auth/auth.saga";
import countSaga from "@rentiohq/shared-frontend/dist/redux/count/count.saga";
import agreementSaga from "@rentiohq/shared-frontend/dist/reduxInsurance/agreement/agreement.saga";
import leadSaga from "@rentiohq/shared-frontend/dist/reduxInsurance/lead/lead.saga";
import quoteSaga from "@rentiohq/shared-frontend/dist/reduxInsurance/quote/quote.saga";
import statSaga from "@rentiohq/web-shared/dist/redux/stats/stats.saga";
import { all, fork } from "redux-saga/effects";

// eslint-disable-next-line import/no-default-export
export default function* sagas() {
  yield all([
    fork(activitySaga),
    fork(agreementSaga),
    fork(authSaga),
    fork(countSaga),
    fork(leadSaga),
    fork(quoteSaga),
    fork(statSaga),
  ]);
}
