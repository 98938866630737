import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
// cspell:disable
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { formatNumber } from "@rentiohq/shared-frontend/dist/utils/number.utils";
import { round } from "lodash";
import sumBy from "lodash/sumBy";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../../../components/Button";
import { CallOutCard } from "../../../../components/CallOutCard";
import { DisplayText } from "../../../../components/DisplayText";
import { Error } from "../../../../components/Error";
import { ESpacings, Grid } from "../../../../components/Grid";
import { Loading } from "../../../../components/Loading/Loading";
import * as statsActions from "../../../../redux/stats/stats.actions";
import { REDUCER_KEY_STATS, } from "../../../../redux/stats/stats.reducer";
import * as statsSelectors from "../../../../redux/stats/stats.selectors";
import { EColor } from "../../DashboardStats.types";
import { generateIdentifier } from "../../DashboardStats.utils";
import StatChart from "../StatChart";
var StatCard = function (props) {
    var _a;
    var chart = props.chart, from = props.from, to = props.to, statInterval = props.statInterval, onClickDetail = props.onClickDetail;
    // State
    var identifier = generateIdentifier({
        entity: chart.entity,
        from: from,
        to: to,
        interval: statInterval,
        scope: undefined,
    });
    // Redux
    var dispatch = useDispatch();
    var stats = useSelector(function (state) {
        return statsSelectors.getStats(state, identifier);
    });
    var isFetching = useSelector(function (state) {
        return statsSelectors.isFetchingStats(state, identifier);
    });
    var fetchError = useSelector(function (state) {
        return statsSelectors.fetchStatsError(state, identifier);
    });
    // Data
    var fetchData = function () {
        if (!identifier) {
            return;
        }
        dispatch(statsActions.getStats.actions.start({
            identifier: identifier,
            params: {
                entity: chart.entity,
                from: from, // getFrom(statDuration),
                to: to, // getTo(statDuration),
                interval: statInterval,
            },
        }));
    };
    // Lifecycle
    React.useEffect(function () {
        fetchData();
    }, [identifier]);
    // Event handlers
    var handleClickDetail = function () {
        onClickDetail(chart.entity);
    };
    var getAverage = function (chart, chartTotal) {
        if (!chart.average) {
            return null;
        }
        var _a = chart.average, dividendKey = _a.dividend, divisorKey = _a.divisor;
        var dividend = chartTotal[dividendKey] || 0;
        var divisor = chartTotal[divisorKey] || 0;
        if (!divisor) {
            return null;
        }
        var value = round(dividend / divisor, 2);
        return {
            value: value,
            color: chart.average.color || "#BCBDBE",
            label: chart.average.label || "Gemiddelde",
        };
    };
    // Render
    var renderContent = function () {
        if (stats) {
            var data_1 = stats.data;
            // Calculate totals for whole chart
            var chartTotal_1 = {};
            chart.lines.forEach(function (line) {
                chartTotal_1[line.key] = round(sumBy(data_1, "total.".concat(line.key)), 2);
            });
            var average = getAverage(chart, chartTotal_1);
            return (_jsxs(_Fragment, { children: [_jsxs(Grid, { alignItems: "center", mb: ESpacings.loose, children: [chart.lines.map(function (line) {
                                return !line.hideInSummary && (_jsx(DisplayText, { size: "extraSmall", children: _jsxs("span", { style: {
                                            color: line.color ? line.color : EColor.DEFAULT,
                                        }, children: [line.label, ": ", formatNumber(chartTotal_1[line.key], false)] }) }));
                            }), average && (_jsx(DisplayText, { size: "extraSmall", children: _jsxs("span", { style: { color: average.color }, children: [average.label, ": ", formatNumber(average.value, false)] }) }))] }), _jsx(StatChart, { chart: chart, stats: stats, statInterval: statInterval })] }));
        }
        if (isFetching) {
            return _jsx(Loading, {});
        }
        if (fetchError) {
            return _jsx(Error, { errors: [fetchError] });
        }
        return null;
    };
    var scopeListLength = ((_a = stats === null || stats === void 0 ? void 0 : stats.metadata.scopelist) === null || _a === void 0 ? void 0 : _a.length) || 0;
    var hasDetails = scopeListLength > 1;
    return (_jsx(CallOutCard, { heading: _jsxs(Grid, { children: [_jsx(Grid.Item, { flex: 1, children: _jsx(DisplayText, { size: "extraSmall", subdued: true, children: getLocalizedText("system.entity.".concat(chart.entity).replace(/[/-]/g, "_")) }) }), hasDetails && (_jsx(Grid.Item, { children: _jsx(Button, { appearance: "link", onClick: handleClickDetail, justifySelf: "flex-end", children: "Bekijk detail" }) }))] }), children: renderContent() }));
};
// eslint-disable-next-line import/no-default-export
export default StatCard;
