export var join = function (input, separator, lastSeparator) {
    if (separator === void 0) { separator = ", "; }
    if (lastSeparator === void 0) { lastSeparator = " & "; }
    if (input.length > 2) {
        return "".concat(input.slice(0, -1).join(separator)).concat(lastSeparator).concat(input.slice(-1));
    }
    return input.join(lastSeparator);
};
export var capitalizeString = function (input) {
    return input.substr(0, 1).toUpperCase() + input.substr(1);
};
// https://gist.github.com/Jagathishrex/f4b57d77a093b7a5614db0f95c5e5060
export var stringToWords = function (input) {
    var regex = /[A-Z\xC0-\xD6\xD8-\xDE]?[a-z\xDF-\xF6\xF8-\xFF]+|[A-Z\xC0-\xD6\xD8-\xDE]+(?![a-z\xDF-\xF6\xF8-\xFF])|\d+/g;
    return input.match(regex);
};
// https://gist.github.com/Jagathishrex/f4b57d77a093b7a5614db0f95c5e5060
export var stringToCamelCase = function (input) {
    var inputArray = stringToWords(input);
    var result = "";
    for (var i = 0, len = inputArray.length; i < len; i++) {
        var currentStr = inputArray[i];
        var tempString = currentStr.toLowerCase();
        if (i !== 0) {
            tempString = capitalizeString(tempString);
        }
        result += tempString;
    }
    return result;
};
export var stringToSnakeCase = function (input) {
    return stringToWords(input).join("_").toLowerCase();
};
export var stringToPascalCaseWithSpaces = function (input) {
    return stringToWords(input).map(capitalizeString).join(" ").toLowerCase();
};
export var mapStringToEnum = function (value, enumValues) {
    return enumValues.find(function (x) { return x === value; });
};
export var removeExtraSpaces = function (input) {
    if (input === void 0) { input = ""; }
    return input.replace(/\s+/g, " ").trim();
};
export var startsWithOneOf = function (input, prefixes) {
    return prefixes.some(function (prefix) { return (input === null || input === void 0 ? void 0 : input.startsWith(prefix)) || false; });
};
